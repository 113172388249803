import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions, questionsActions } from "../../../_actions";
import moment from "moment";
import { CONST } from "../../../_config";
import * as XLSX from "xlsx";
import ReportSummary from "./Components/ReportSummary";
import Footer from "../Home/Component/Footer";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import { LuDownload } from "react-icons/lu";
import NewTeamModal from "./Modal/NewTeamModal";
import { IoPeopleOutline } from "react-icons/io5";
import ReactPaginate from 'react-paginate';

class MyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      backSummary: false,
      contactExpertSection: false,
      topFiveTalent: true,
      showMsg: false,
      showMsg2: false,
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 4,
      drowdown: true,
      viewCode: true,
      modaleOpen: false,
      formNameData: {
        id: "",
        name: "",
      },
      crrPackId: "",
      showReportModal: false,
      showSummaryModal: false,
      showMainSummaryModal: false,
      showSummaryReport: false,
      showSchoolReportModal: false,
      showManagerReportModal: false,
      showHiringReportModal: false,

      menuTab: "assessment",

      showMainMenu: "Summary",
      showDashboard: "Personality",

      surveyLanguageCode: "",
      themeType: 2,
      crrEmpDetails: {
        emp_id: "",
        name: "",
      },
      selectedValue: "1",
      formUserData: {
        name: "",
        email: "",
        country: "",
        postcode: "",
        mobile: "",
        organisation: "",
      },
      pageNo: 1,
      size: 10,
      offset: 0,
      showHiringReport: false,
      crrCodeCopied: "",
      crrLicensee: "",
      crrcode: "",
      crrDetail: "",
      crrEmployeeId: "",
      crrCodeUserDetails: {
        "employeeId": "",
        "name": "",
        "email": "",
        "mobile": "",
        "message": ""
      },
      newEmpFormData: {
        "name": ""
      },
      newEmpFormDataError: {
        "name": ""
      },
      crrProductCode: ""
    };
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.ViewCode = this.ViewCode.bind(this);
    this.handleShowMainSummaryModal = this.handleShowMainSummaryModal.bind(this);
    this.handleShowMainSummaryModalBack = this.handleShowMainSummaryModalBack.bind(this);
    this.handleExpertContactModalBack = this.handleExpertContactModalBack.bind(this);
    this.handleExpertContactModal = this.handleExpertContactModal.bind(this);
    this.drowDown = this.drowDown.bind(this);
    this.ModalOpen = this.ModalOpen.bind(this);
  }


  componentDidMount() {

    this.props.dispatch(assessmentActions.saveSuccessOrderBook());

    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    this.props.dispatch(assessmentActions.getAssessmentCartCount());
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());

    this.props.dispatch(assessmentActions.getOrderBookList({
      "keyWord": '',
      "pageNo": 1,
      "size": this.state.size
    }));
    window.scrollTo(0, 0);

  }



  handleClick() {
    this.setState({ showMsg: true });
    setTimeout(() => {
      this.setState({ showMsg: false });
    }, 3000);
  }

  handleShowMainSummaryModalBack() {
    this.setState({ showMainSummaryModal: true, backSummary: false });
  }

  handleShowMainSummaryModal(ele) {
    this.setState({ showMainSummaryModal: false, backSummary: true, crrDetail: ele });
  }

  handleExpertContactModal(ele) {
    this.setState({
      contactExpertSection: ele, topFiveTalent: false
    });
  }

  handleExpertContactModalBack() {
    this.setState({
      contactExpertSection: false, topFiveTalent: true
    });
  }

  handleClick2(value) {
    this.setState({ showMsg2: true, crrCodeCopied: value });
    setTimeout(() => {
      this.setState({ showMsg2: false, crrCodeCopied: "" });
    }, 3000);
  }

  CopyClick() {
    window.alert("DJKEOPIUJH : Assessment Code Copied!!");
  }


  ModalOpen = () => {
    this.setState({ modaleOpen: !this.state.modaleOpen });
  };
  ViewCode = () => {
    this.setState({ viewCode: !this.state.viewCode });
  };

  drowDown = () => {
    this.setState({ drowdown: !this.state.drowdown });
  };

  handleTabClick = (tabIndex, temp) => {
    this.setState({ activeTab: tabIndex, crrPackId: "", crrLicensee: "" }, () => {
      this.setState({ keyWord: '', offset: 0 });
      if (tabIndex === 5) {
        let data = {
          "id": temp.id,
          "keyWord": '',
          "pageNo": 1,
          "size": this.state.size
        };
        console.log("temp-1------------------>", temp);
        console.log("data-1------------------>", data);
        let productCode = temp && temp.productId && temp.productId.code && temp.productId.code ? temp.productId.code : "";
        this.setState({ crrPackId: temp.id, crrLicensee: temp.licensee, crrProductCode: productCode });
        if (productCode !== "TeenCareerGuidance") {
          let dataX = {
            "id": temp.id
          };
          this.props.dispatch(
            assessmentActions.userAssessmentResponseAnalytics(dataX)
          );
        }
        this.props.dispatch(assessmentActions.userAssessmentOrders(data));


      } else if (tabIndex === 2) {
        let { assessment } = this.props;
        let { userAssessmentUserProfileData } = assessment;
        let data = {
          name:
            userAssessmentUserProfileData && userAssessmentUserProfileData.name
              ? userAssessmentUserProfileData.name
              : "",
          email:
            userAssessmentUserProfileData && userAssessmentUserProfileData.email
              ? userAssessmentUserProfileData.email
              : "",
          country:
            userAssessmentUserProfileData &&
              userAssessmentUserProfileData.country
              ? userAssessmentUserProfileData.country
              : "",
          postcode:
            userAssessmentUserProfileData &&
              userAssessmentUserProfileData.postcode
              ? userAssessmentUserProfileData.postcode
              : "",
          mobile:
            userAssessmentUserProfileData &&
              userAssessmentUserProfileData.mobile
              ? userAssessmentUserProfileData.mobile
              : "",
          organisation:
            userAssessmentUserProfileData &&
              userAssessmentUserProfileData.organisation
              ? userAssessmentUserProfileData.organisation
              : "",
        };
        this.setState({ formUserData: data });
      } else {
        this.setState({ crrPackId: "" });
      }

      if (tabIndex === 2) {
        this.props.history.push(`/assessment/` + "profile-summary");
      } else if (tabIndex === 2) {
        this.props.history.push(`/assessment/` + "profile-summary");
      } else if (tabIndex === 3) {
        this.props.history.push(`/assessment/` + "my-orders");
      } else if (tabIndex === 4) {
        this.props.history.push(`/assessment/` + "assessment-code");
      } else if (tabIndex === 6) {
        this.props.history.push(`/assessment/` + "user");
      } else if (tabIndex === 7) {
        this.props.history.push(`/assessment/` + "myteam");
      }
    });
  };

  handleRedirect = () => {
    this.props.history.push('/assessment/contact-us');
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.user.userAssessmentMyCartSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoPage = (field) => {
    this.props.history.push(`/assessment/` + field);
  };

  onEditSingleName = (element, check) => {
    let { formNameData } = this.state;
    if (check) {
      formNameData = {
        id: element.id,
        name: element.name,
      };
    } else {
      formNameData = {
        id: "",
        name: "",
      };
    }
    this.setState({ showNameEdit: true, formNameData });
  };

  onSingleNameInput = (value, element) => {
    let { formNameData } = this.state;
    formNameData = {
      id: element.id,
      name: value,
    };
    this.setState({ formNameData });
  };

  onSingleNameSave = () => {
    let { formNameData, crrPackId } = this.state;
    let data = {
      id: formNameData.id,
      name: formNameData.name,
    };
    let temp = {
      id: crrPackId,
    };
    this.props.dispatch(
      assessmentActions.userAssessmentOrderNameUpdate(data, temp)
    );
    formNameData = {
      id: "",
      name: "",
    };
    this.setState({ showNameEdit: false });
  };

  viewReport = (ele, isComplete) => {
    if (ele && ele.productId && ele.productId.code) {
      let code = ele.productId.code;
      console.log("code ------------>", code);

      if (isComplete) {

        console.log("ele 1X-->", ele);

        if (code === "Top5TalentplusStrengths") {
          this.handleSummaryReport(ele);
          // this.setState({
          //   showReportModal: false,
          //   showSummaryReport: true,
          //   showManagerReportModal: false,
          //   showHiringReportModal: false,
          //   showSchoolReportModal: false
          // });
        } else if (code === "TalentPlusStrengths21") {
          this.handleReport(ele);
          // this.setState({
          //   showReportModal: true,
          //   showSummaryReport: false,
          //   showManagerReportModal: false,
          //   showHiringReportModal: false,
          //   showSchoolReportModal: false
          // });
        } else if (code === "TalentplusManagerStrengths") {
          this.handleManagerReport(ele);
          // this.setState({
          //   showReportModal: false,
          //   showSummaryReport: false,
          //   showManagerReportModal: true,
          //   showHiringReportModal: false,
          //   showSchoolReportModal: false
          // });
        } else if (code === "TalentPlusHiringStrengths") {
          this.handleHiringReport(ele);
          // this.setState({
          //   showReportModal: false,
          //   showSummaryReport: false,
          //   showManagerReportModal: false,
          //   showHiringReportModal: true,
          //   showSchoolReportModal: false
          // });
        } else if (code === "Top5TeenTalentplusStrengths") {
          this.handleSchoolReport(ele);
          // this.setState({
          //   showReportModal: false,
          //   showSummaryReport: false,
          //   showManagerReportModal: false,
          //   showHiringReportModal: false,
          //   showSchoolReportModal: true
          // });
        } else if (code === "TeenCareerGuidance") {
          this.handleSchoolCareer(ele);
          // this.setState({
          //   showReportModal: false,
          //   showSummaryReport: false,
          //   showManagerReportModal: false,
          //   showHiringReportModal: false,
          //   showSchoolReportModal: true
          // });
        }

        this.setState({ showSummaryModal: true, showMainSummaryModal: true, crrcode: code, showMainMenu: "Summary", crrEmployeeId: ele.employeeId });


      }
    }

    let temp = {
      emp_id: ele.emp_id,
      name: ele.name,
    };
    this.setState({ crrEmpDetails: temp });

    let temp2 = {
      employeeId: ele.employeeId,
      name: ele.name,
      email: ele.email,
      mobile: ele.mobile,
    };
    this.setState({ crrCodeUserDetails: temp2 });



  };


  handleReport = (ele) => {

    console.log("ele---->", ele)

    let data = {
      employeeId: ele.employeeId
    };
    this.props.dispatch(questionsActions.getAssessmentReportAdmin(data));
    this.props.dispatch(questionsActions.getSurveyTakenDateAdmin(data));
  };

  handleSummaryReport = (ele) => {
    let data = {
      employeeId: ele.employeeId
    };
    this.props.dispatch(questionsActions.getAssessmentReportAdmin(data));
    this.props.dispatch(questionsActions.getSurveyTakenDateAdmin(data));
  };

  handleManagerReport = (ele) => {
    let data = {
      employeeId: ele.employeeId
    };
    this.props.dispatch(questionsActions.getAssessmentReportAdmin(data));
    this.props.dispatch(questionsActions.getSurveyTakenDateAdmin(data));
  };

  handleHiringReport = (ele) => {
    let data = {
      employeeId: ele.employeeId
    };
    this.props.dispatch(questionsActions.getAssessmentReportAdmin(data));
    this.props.dispatch(questionsActions.getSurveyTakenDateAdmin(data));
  };

  handleSchoolReport = (ele) => {
    let data = {
      employeeId: ele.employeeId
    };
    this.props.dispatch(questionsActions.getAssessmentReportAdmin(data));
    this.props.dispatch(questionsActions.getSurveyTakenDateAdmin(data));
  };


  handleSchoolCareer = (ele) => {


    let data4 = {
      employeeId: ele.employeeId,
      surveyId: "666aa3e7e44c722a2beb7ce9"
    }
    this.props.dispatch(assessmentActions.getAptitudeTestScore(data4));


    let data3 = {
      employeeId: ele.employeeId,
      surveyId: "663376deba900057d752056d"
    }
    this.props.dispatch(assessmentActions.getInterestDimensionScore(data3));



    let data1 = {
      employeeId: ele.employeeId,
      surveyId: "66336ca4ba900057d751ff06"
    };
    this.props.dispatch(assessmentActions.getPersonalityIndex(data1));

    let data2 = {
      employeeId: ele.employeeId,
      surveyId: "663a0fae8312d8618444d304"
    }
    this.props.dispatch(assessmentActions.getWorkValueDimensionScore(data2));


    let data5 = {
      employeeId: ele.employeeId
    }
    this.props.dispatch(assessmentActions.getCareerFilamentScore(data5));

    this.props.dispatch(assessmentActions.getTalentPlusEmpAdminStatus(data5));


  };


  closeReportModal = () => {
    this.setState({ showReportModal: false });
  };

  closeSummaryReportModal = () => {
    this.setState({ showSummaryReport: false });
  };

  closeManagerReportModal = () => {
    this.setState({ showManagerReportModal: false });
  };

  closeHiringReportModal = () => {
    this.setState({ showHiringReportModal: false });
  };

  handleLanguage = (item) => {
    console.log("item------------------->", item);
    if (item.shortName === "en") {
      this.setState({
        surveyLanguageCode: item.shortName,
        surveyLanguageName: "English",
        surveyLanguageRTL: false,
      });
    } else {
      this.setState({
        surveyLanguageCode: item.shortName,
        surveyLanguageName: item.name,
        surveyLanguageRTL: item.isRTL,
      });
    }
  };
  handleDropdownChange(event) {
    const activeTab = event.target.value;


    if (Number(activeTab) === 2) {
      this.props.history.push(`/assessment/` + "profile-summary");

    } else if (Number(activeTab) === 2) {
      this.props.history.push(`/assessment/` + "profile-summary");

    } else if (Number(activeTab) === 3) {
      this.props.history.push(`/assessment/` + "my-orders");

    } else if (Number(activeTab) === 4) {
      this.props.history.push(`/assessment/` + "assessment-code");

    } else if (Number(activeTab) === 5) {
      this.props.history.push(`/assessment/` + "user");
    }

    this.setState({
      activeTab: Number(activeTab)
    });

    console.log('activeTabactiveTabactiveTabactiveTabactiveTabactiveTabactiveTab', activeTab);
  }

  handleDownloadSample = () => {
    let binary_univers = [
      {
        name: "john",
      },
    ];

    //console.log('binary_univers: ', binary_univers)

    let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

    // Create a new Workbook
    var wb = XLSX.utils.book_new();

    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");

    // export your excel
    XLSX.writeFile(wb, "namefile.xlsx");
  };


  downloadAccessmentCode = (listV1) => {

    let binary_universValues = listV1 && listV1.length > 0 ? listV1.map((element) => ({
      "Name": element && element.name ? element.name : "",
      "Assessment Code": element && element.otpCode ? element.otpCode : ""
    })) : []


    let binary_univers = binary_universValues;

    //console.log('binary_univers: ', binary_univers)

    let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

    // Create a new Workbook
    var wb = XLSX.utils.book_new();

    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");

    // export your excel
    XLSX.writeFile(wb, "assessmentcodes.xlsx");
  };


  updateUserDetails = () => {
    let { formUserData } = this.state;
    let data = {
      name: formUserData && formUserData.name ? formUserData.name : "",
      email: formUserData && formUserData.email ? formUserData.email : "",
      country: formUserData && formUserData.country ? formUserData.country : "",
      postcode:
        formUserData && formUserData.postcode ? formUserData.postcode : "",
      mobile: formUserData && formUserData.mobile ? formUserData.mobile : "",
      organisation:
        formUserData && formUserData.organisation
          ? formUserData.organisation
          : "",
    };
    this.props.dispatch(assessmentActions.updateAssessmentUserProfile(data));
  };

  handleFormData = (value, name) => {
    let { formUserData } = this.state;
    formUserData[name] = value;
    this.setState({ formUserData });
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };


  handleDownloadStatus = () => {
  };

  handleViewReport = () => {
    let { crrcode } = this.state;
    if (crrcode === "Top5TalentplusStrengths") {
      this.setState({
        showReportModal: false,
        showSummaryReport: true,
        showManagerReportModal: false,
        showHiringReportModal: false,
        showSchoolReportModal: false
      });
    } else if (crrcode === "TalentPlusStrengths21") {
      this.setState({
        showReportModal: true,
        showSummaryReport: false,
        showManagerReportModal: false,
        showHiringReportModal: false,
        showSchoolReportModal: false
      });
    } else if (crrcode === "TalentplusManagerStrengths") {
      this.setState({
        showReportModal: false,
        showSummaryReport: false,
        showManagerReportModal: true,
        showHiringReportModal: false,
        showSchoolReportModal: false
      });
    } else if (crrcode === "TalentPlusHiringStrengths") {
      this.setState({
        showReportModal: false,
        showSummaryReport: false,
        showManagerReportModal: false,
        showHiringReportModal: true,
        showSchoolReportModal: false
      });
    } else if (crrcode === "Top5TeenTalentplusStrengths") {
      this.setState({
        showReportModal: false,
        showSummaryReport: false,
        showManagerReportModal: false,
        showHiringReportModal: false,
        showSchoolReportModal: true
      });
    }
  };

  closeReportummaryModal = () => {
    this.setState({ showSummaryModal: false, crrcode: "" });
  }

  handleInputExpertForm = (value, name) => {
    let { crrCodeUserDetails } = this.state;
    crrCodeUserDetails[name] = value;
    this.setState({ crrCodeUserDetails });
  }

  bookExpert = (userDetails, expertDetails) => {
    let data = {
      "employeeId": userDetails.employeeId,
      "name": userDetails.name,
      "email": userDetails.email,
      "mobile": userDetails.mobile,
      "message": userDetails.message,
      "expertId": expertDetails.id,
    }
    console.log("--------->data<---------123123123131-------", data)

    this.props.dispatch(assessmentActions.userAssessmentExpertOrderPlace(data));

  }

  handleExpertClick = () => {
    const { history } = this.props;
    history.push('/assessment/expert'); // Replace '/expert-page' with the actual route of your expert page
  }


  handleTestPayment = (url) => {
    console.log("url------.", url)
    this.props.history.push(`/assessment/` + "expert-booked/" + url);
  }

  handleMainMenu = (value) => {
    this.setState({ showMainMenu: value }, () => {

      if (value === "Summary") {
        this.setState({ backSummary: false, top5Talent: false })
      } else if (value === "Report") {
        this.setState({ backSummary: false, top5Talent: false })
      }
    });
  }

  handleDashboard = (value) => {
    this.setState({ showDashboard: value }, () => {
      if (value === "Personality") {
        // this.Personality();
      } else if (value === "InterestInsights") {
        // this.InterestInsights();
      } else if (value === "WorkValue") {
        // this.WorkValue();
      }
    });
  }

  addTeam = () => {
    this.setState({ showTeamModal: true });
  }

  handleNewEmpInput = (e) => {
    let { newEmpFormData } = this.state;
    newEmpFormData[e.target.name] = e.target.value;
    this.setState({ newEmpFormData });
  }

  handleUserSubmit = () => {
    let { newEmpFormData } = this.state;
    let data = {
      "name": newEmpFormData["name"]
    }
    this.props.dispatch(assessmentActions.createNewTeam(data));
    this.setState({ showTeamModal: false });
  }

  handleMenuTab = (tab) => {
    this.setState({ menuTab: tab });
  }


  handleTeamPageClick = (data, element) => {
    const offset = data.selected * this.state.size;
    const pageNo = data.selected + 1;
    let { crrPackId } = this.state;
    this.setState({ offset: offset, page: data.selected }, () => {
      const datatemp = {
        id: crrPackId,
        keyWord: this.state.keyWord,
        pageNo: pageNo,
        size: this.state.size,
      };
      this.props.dispatch(assessmentActions.userAssessmentOrders(datatemp));
    });
  }
  handleOrderHistoryPageClick = (data, element) => {
    const offset = data.selected * this.state.size;
    const pageNo = data.selected + 1;
    let { crrPackId } = this.state;
    this.setState({ offset: offset, page: data.selected }, () => {
      const datatemp = {
        id: crrPackId,
        keyWord: this.state.keyWord,
        pageNo: pageNo,
        size: this.state.size,
      };
      this.props.dispatch(assessmentActions.getOrderBookList(datatemp));
    });
  }



  render() {
    let { newEmpFormData, newEmpFormDataError, showTeamModal, menuTab, showMsg, showMsg2, crrCodeCopied, crrEmployeeId, crrProductCode } = this.state;
    let { assessment, questions } = this.props;
    let {
      getAssessmentStatusData,
      getAssessmentReportByEmpIDData,
      getTranslateElementAllData,
      // getAssessmentCartCountData,
      loading,
      getSurveyTakenDateData
    } = questions;

    let {
      showManagerReportModal,
      showHiringReportModal,
      showSchoolReportModal,
      crrEmpDetails,
      showSummaryReport,
      showReportModal,
      surveyLanguageCode,
      showNameEdit,
      formNameData,
      crrLicensee,
      showSummaryModal
    } = this.state;
    let {
      getTalentPlusEmpAdminStatusData,
      userAssessmentUserProfileData,
      getOrderBookListData,
      getOrderBookListDataTotal,
      userAssessmentOrdersData,
      userAssessmentOrdersTotal,
      userAssessmentResponseAnalyticsData,
      getAssessmentPackagesShortListData,
      userAssessmentExpertOrderPlaceData,
      getAssessmentCartCountData,

      getAptitudeTestScoreData,
      getCareerFilamentScoreData,
      getCareerSurveyListData,
      getPersonalityIndexData,
      getWorkValueDimensionScoreData,
      getInterestDimensionScoreData


    } = assessment;

    console.log("userAssessmentOrdersDatauserAssessmentOrdersDatauserAssessmentOrdersData--11111111111>", userAssessmentOrdersData);
    console.log("userAssessmentOrdersTotaluserAssessmentOrdersTotaluserAssessmentOrdersTotal-->", userAssessmentOrdersTotal);
    // getAssessmentStatusData && getAssessmentStatusData.isSchool
    //   ? true
    //   : false;

    let createdAt =
      getTalentPlusEmpAdminStatusData && getTalentPlusEmpAdminStatusData.createdAt
        ? moment(getTalentPlusEmpAdminStatusData.createdAt)
          .utcOffset("+05:30")
          .format("DD MMMM, YYYY")
        : "";

    const { activeTab } = this.state;

    function getDetails(orderBook, field, type, orderPack) {
      if (type === 2) {
        return field &&
          orderPack &&
          orderPack["productId"] &&
          orderPack["productId"][field]
          ? orderPack["productId"][field]
          : "";
      } else if (type === 3) {
        return field && orderPack && orderPack[field] ? orderPack[field] : "";
      } else {
        return field && orderBook && orderBook[field] ? orderBook[field] : "";
      }
    }

    function getValueRes(field) {
      return field &&
        userAssessmentResponseAnalyticsData &&
        userAssessmentResponseAnalyticsData[0] &&
        userAssessmentResponseAnalyticsData[0][field]
        ? userAssessmentResponseAnalyticsData[0][field]
        : 0;
    }

    let ReportDataList = [
      {
        name: "Total",
        value: 0,
        color: "#99cc00",
      },
      {
        name: "Clicked",
        value: 0,
        color: "#bf00ff",
      },
      {
        name: "Completed",
        value: 0,
        color: "#008080",
      },
      {
        name: "Not Completed",
        value: 0,
        color: "#ff9933",
      },
    ];

    if (crrProductCode === "TeenCareerGuidance") {
      if (userAssessmentOrdersData && userAssessmentOrdersData.length > 0) {
        ReportDataList = [
          {
            name: "Total",
            value: userAssessmentOrdersData.length,
            color: "#99cc00",
          },
          {
            name: "Clicked",
            value: userAssessmentOrdersData.filter((ele) => (ele.isClicked === true)).length,
            color: "#bf00ff",
          },
          {
            name: "Completed",
            value: userAssessmentOrdersData.filter((ele) => (ele.isComplete === true)).length,
            color: "#008080",
          },
          {
            name: "Not Completed",
            value: Number(userAssessmentOrdersData.length - userAssessmentOrdersData.filter((ele) => (ele.isComplete === true)).length),
            color: "#ff9933",
          },
        ];
      }
    } else {
      ReportDataList = [
        {
          name: "Total",
          value: getValueRes("total"),
          color: "#99cc00",
        },
        {
          name: "Clicked",
          value: getValueRes("clicked"),
          color: "#bf00ff",
        },
        {
          name: "Completed",
          value: getValueRes("completed"),
          color: "#008080",
        },
        {
          name: "Not Completed",
          value: Number(getValueRes("total") - getValueRes("completed")),
          color: "#ff9933",
        },
      ];
    }


    const GetTextByLanguage = (name, text) => {
      if (surveyLanguageCode && surveyLanguageCode !== "en") {
        let sentence = "";
        if (
          getTranslateElementAllData &&
          getTranslateElementAllData.length > 0
        ) {
          let getIndex = getTranslateElementAllData.findIndex(
            (prev) => prev.name === name
          );
          if (getIndex !== -1) {
            sentence =
              getTranslateElementAllData &&
                getTranslateElementAllData[getIndex] &&
                getTranslateElementAllData[getIndex]["transl"] &&
                getTranslateElementAllData[getIndex]["transl"][surveyLanguageCode]
                ? getTranslateElementAllData[getIndex]["transl"][
                surveyLanguageCode
                ]
                : "";
          }
        }
        console.log("sentence------------>", sentence);
        console.log("surveyLanguageCode------------>", surveyLanguageCode);
        console.log(
          "getTranslateElementAllData------------>",
          getTranslateElementAllData
        );

        return sentence ? sentence : text;
      } else {
        return text;
      }
    };

    const GetDetailsByLanguage = (element, name, text) => {
      if (surveyLanguageCode && surveyLanguageCode !== "en") {
        let sentence =
          element &&
            element["details"] &&
            element["details"]["transl"] &&
            element["details"]["transl"][surveyLanguageCode] &&
            element["details"]["transl"][surveyLanguageCode][name]
            ? element["details"]["transl"][surveyLanguageCode][name]
            : "";
        return sentence ? sentence : text;
      } else {
        return text;
      }
    };

    function EditButtonFn(name, text) {
      return <>{GetTextByLanguage(name, text)}</>;
    }
    function EditDetailsFn(element, name, text) {
      return <>{GetDetailsByLanguage(element, name, text)}</>;
    }




    function getStatus(orderPack, orderBook) {


      console.log("orderBook--->", orderBook)



      let status = 0;
      if (orderBook && orderBook.orderPackResponses && orderBook.orderPackResponses.length > 0) {

        console.log("orderBook.orderPackResponses--->", orderBook.orderPackResponses)



        let getIndex = orderBook.orderPackResponses.findIndex(prev => prev.orderPackId.toString() === orderPack.id.toString());

        console.log("getIndex--->", getIndex)

        if (getIndex !== -1) {
          let detail = orderBook.orderPackResponses[getIndex]["reponses"];
          console.log("detail--->", detail)

          let total = detail && detail["total"] ? detail["total"] : 0;
          let clicked = detail && detail["clicked"] ? detail["clicked"] : 0;
          let completed = detail && detail["completed"] ? detail["completed"] : 0;

          console.log("total--->", total)
          console.log("clicked--->", clicked)
          console.log("completed--->", completed)

          if (total === 0) {
            status = 0;
          } else {
            if (total === completed) {
              status = 2;
            } else if (clicked > 0) {
              status = 1;
            } else if (clicked === 0) {
              status = 0;
            }
          }


        }
      }

      return status
    }


    return (
      <div className="">
        <HeaderTransprent
          handleSignInPage={this.handleSignInPage}
          userDetails={userAssessmentUserProfileData}
          logout={this.logout}
          gotoPage={this.gotoPage}
          props2={this.props}
          cartCount={getAssessmentCartCountData ? getAssessmentCartCountData : 0}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />

        <section className="flex flex-col w-full gap-10 px-4 mx-auto mb-10 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
          <div className="w-full py-5 space-y-4 md:py-10">
            <div className="flex items-center pb-6 space-x-4 border-b">
              <h1 className="md:text-3xl text-2xl font-medium text-[#3D405B]">
                My Account
              </h1>{" "}
            </div>

            <div className="flex flex-col items-start w-full gap-10 py-6 xl:flex-row ">
              <div className="hidden w-full capitalize md:block lg:w-64">

                <div
                  onClick={() => this.handleTabClick(2)}
                  className={
                    this.state.activeTab == 2
                      ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                      : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                  > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <polyline points="5 12 3 12 12 3 21 12 19 12" /> <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /> <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                  </svg>
                  <span>Profile Summary</span>
                </div>

                <div
                  onClick={() => this.handleTabClick(3)}
                  className={
                    this.state.activeTab == 3
                      ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                      : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-shopping-cart" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  {" "}  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>{" "}  <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>{" "}  <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>{" "} <path d="M17 17h-11v-14h-2"></path>{" "} <path d="M6 5l14 1l-1 7h-13"></path></svg>
                  <span>my orders</span>
                </div>

                <div
                  onClick={() => this.handleTabClick(4)}
                  className={
                    this.state.activeTab == 4 || this.state.activeTab == 5
                      ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                      : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clipboard-text" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  {" "}  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>{" "}  <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>{" "}  <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path>{" "}  <path d="M9 12h6"></path> <path d="M9 16h6"></path></svg>
                  <span>Assessment Code</span>
                </div>

                <div
                  onClick={() => this.handleTabClick(7)}
                  className={
                    this.state.activeTab == 7
                      ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                      : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                  }
                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clipboard-text" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  {" "}  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>{" "}  <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>{" "}  <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path>{" "}  <path d="M9 12h6"></path> <path d="M9 16h6"></path></svg> */}
                  <IoPeopleOutline className="h-6 w-6" />
                  <span>My Team</span>
                </div>


                {/* <div
                  onClick={() => this.handleTabClick(6)}
                  className={
                    this.state.activeTab == 6
                      ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                      : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                  }
                >
                  <FiUsers className="w-6 h-6" />
                  <span>User</span>
                </div> */}

              </div>

              <div className="block w-full md:hidden">
                <label for="countries" className="block mb-2 text-sm font-medium text-[#3D405B]">Select an option</label>
                <select
                  id="dropdown"
                  onChange={this.handleDropdownChange}
                  value={activeTab}
                  className="bg-white border border-gray-300 text-[#3D405B] text-sm rounded-lg focus:ring-[#2196F3] focus:border-[#2196F3] block w-full p-2.5"
                >
                  <option value="2">Profile Summary</option>
                  {/* <option value="2">Edit Profile</option> */}
                  <option value="3">My Orders</option>
                  <option value="4">Assessment Code</option>
                  <option value="6">user</option>
                </select>
              </div>


              {this.state.activeTab === 4 ? (
                <>
                  {this.state.viewCode ? (
                    <div className="w-full flex-1  text-[#3D405B]">
                      <h1 className="md:text-2xl text-xl  text-[#3D405B] pb-2">
                        Assessment Code
                      </h1>
                      {getOrderBookListData && getOrderBookListData.length > 0
                        ? getOrderBookListData.map((orderBook) => (
                          <div className="w-full divide-y divide-[#CCCCCC]">
                            {getDetails(orderBook, "orderPacks") &&
                              getDetails(orderBook, "orderPacks").length > 0
                              ? getDetails(orderBook, "orderPacks").map(
                                (orderPack) => (
                                  <div className="flex flex-col w-full gap-4 py-6 border-b md:flex-row md:justify-between md:items-center ">
                                    <div className="flex flex-col items-start gap-4 cursor-pointer md:flex-row md:items-center">
                                      <div className="flex items-center space-x-4 ">
                                        <img
                                          src="/img/assessment/Rectangle.png"
                                          className="flex-shrink-0 w-20 h-20 md:w-20 md:h-20 overflow-cover"
                                        />
                                        <div className="flex-shrink-0">
                                          <h1 className="font-medium lg:w-64 md:w-56 w-fit">
                                            {getDetails({}, "name", 2, orderPack)}
                                          </h1>
                                          <p className="text-[#3D405B]/70 text-sm font-medium capitalize">{getDetails({}, "licensee", 3, orderPack)} Plan</p>
                                        </div>
                                      </div>
                                      <div className="text-[#3D405B] md:whitespace-nowrap flex-1 space-y-2 ">
                                        <div>
                                          <div className="md:hidden flex space-x-4 text-[#3D405B]">
                                            <p className="">Qty :</p>
                                            <h1 className="font-medium ">{getDetails({}, "quantity", 3, orderPack)}</h1>
                                          </div>
                                          <div className="md:hidden flex space-x-2 text-[#3D405B] ">
                                            <p className="">
                                              Order Date :
                                            </p>
                                            <h1 className="font-medium ">{moment(new Date(parseInt(getDetails(orderBook, "createdAt")))).utcOffset("+05:30").format("DD MMM YYYY, hh:mm A")}</h1>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="flex-row items-center hidden md:flex 2xl:gap-40 xl:gap-20 md:gap-10 md:justify-start">
                                      <div className="text-[#3D405B] md:w-auto w-full">
                                        <p className="text-[#3D405B]/70 text-sm">
                                          Qty
                                        </p>
                                        <h1 className="text-base text-center ">
                                          {getDetails(
                                            {},
                                            "quantity",
                                            3,
                                            orderPack
                                          )}
                                        </h1>

                                      </div>

                                      <div className="text-[#3D405B]  md:w-auto w-full  ">

                                        <p className="text-[#3D405B]/70 text-sm">
                                          Ordered Date
                                        </p>
                                        <h1 className="text-base ">
                                          {moment(
                                            new Date(
                                              parseInt(
                                                getDetails(orderBook, "createdAt")
                                              )
                                            )
                                          )
                                            .utcOffset("+05:30")
                                            .format("DD MMM YYYY, hh:mm A")}

                                        </h1>
                                      </div>
                                    </div>


                                    {getStatus(orderPack, orderBook) === 0 ?
                                      <button
                                        onClick={() =>
                                          this.handleTabClick(5, orderPack)
                                        }
                                        className="bg-[#2196F3] text-white md:w-36 w-full space-x-2 items-center p-4 py-2.5 uppercase cursor-pointer rounded text-sm font-medium"
                                      >
                                        <span>START</span>
                                      </button>
                                      : null}

                                    {getStatus(orderPack, orderBook) === 1 ?
                                      <button
                                        onClick={() =>
                                          this.handleTabClick(5, orderPack)
                                        }
                                        className="bg-[#FFC107] text-white md:w-36 w-full space-x-2 items-center p-4 py-2.5 uppercase cursor-pointer rounded text-sm font-medium"
                                      >
                                        <span>IN PROGRESS</span>
                                      </button>
                                      : null}

                                    {getStatus(orderPack, orderBook) === 2 ?
                                      <button
                                        onClick={() =>
                                          this.handleTabClick(5, orderPack)
                                        }
                                        className="bg-[#4CAF50] text-white md:w-36 w-full space-x-2 items-center p-4 py-2.5 uppercase cursor-pointer rounded text-sm font-medium"
                                      >
                                        <span>COMPLETED</span>
                                      </button>
                                      : null}
                                  </div>

                                )
                              )
                              : null}

                          </div>
                        ))
                        :

                        <section className="flex items-center justify-center w-full col-span-2 gap-10 px-4 mx-auto overflow-hidden">
                          <div className="flex flex-col items-center justify-center py-8 space-y-4 text-center md:py-10">
                            <img src="/img/newicons/empty_cart2.svg" alt="emptycart.svg" className="w-3/6 2xl:w-2/4" />
                            <h2 className="capitalize md:text-3xl text-2xl font-semibold text-[#3D405B] pt-4">No Products Yet!</h2>
                            <p className="text-lg text-gray-500">Looks like you haven’t made your choice yet.</p>
                            <div className="mt-0 md:mt-8">
                              <button onClick={() => this.props.history.push(`/assessment/` + "products")} className="bg-[#2196F3] border border-[#2196F3] text-white py-2 px-10 rounded uppercase mt-10"> shop Now </button>
                            </div>
                          </div>
                        </section>
                      }

                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          getOrderBookListData && getOrderBookListDataTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={Math.ceil(getOrderBookListDataTotal / this.state.size)}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handleOrderHistoryPageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={this.state.page} // Synchronize selected page
                            />
                            : null}
                      </nav>
                    </div>
                  ) : null}
                </>
              ) : null}


              {/* model back page code here */}
              {this.state.activeTab === 5 ? (
                <>
                  <div className="flex-1 w-full space-y-6 ">
                    {showMsg && (
                      <div className="fixed whitespace-nowrap z-20 top-24 right-0 p-6 py-4 rounded-l-lg shadow-md border-l-4 border-green-500 text-[#3D405B] bg-white ">
                        <p> Assessment Survey Link Copied !</p>
                      </div>
                    )}
                    {showMsg2 && (
                      <div className="fixed whitespace-nowrap z-20 top-24 right-0 p-6 py-4 rounded-l-lg shadow-md border-l-4 border-green-500 text-[#3D405B] bg-white ">
                        <p> Assessment Code Copied !</p>
                        <h2 className="text-lg font-medium">{crrCodeCopied}</h2>
                      </div>
                    )}



                    <button onClick={() => this.handleTabClick(4)} className="bg-gray-100 border md:flex hidden space-x-2 items-center p-4 py-2 uppercase cursor-pointer text-[#3D405B] rounded">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="5" y1="12" x2="19" y2="12" /><line x1="5" y1="12" x2="11" y2="18" /><line x1="5" y1="12" x2="11" y2="6" /></svg>
                      <span>Back</span>
                    </button>

                    <div className="w-full p-6 space-y-4 bg-gray-100 border rounded">
                      <h1>Assessment Survey Link:</h1>
                      <div className="border bg-white w-full flex justify-between rounded text-[#3D405B] items-center ">
                        <p className="px-4 truncate ">
                          {CONST.SURVEY_ASSESSMENT}
                        </p>
                        <div className="flex items-center">
                          <button onClick={() => {
                            this.handleClick();
                            navigator.clipboard.writeText(CONST.SURVEY_ASSESSMENT)
                          }} className="p-2 px-2 border-l
                        hover:text-[#4CAF50] cursor-pointer hover:bg-[#4CAF50]/10 transition-all duration-100
                        ">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">  {" "}  <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}  <rect x="8" y="8" width="12" height="12" rx="2" />{" "}  <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" /></svg>
                          </button>
                          <a href={CONST.SURVEY_ASSESSMENT} target="blank" className="p-2 px-2 border-l hover:text-[#4CAF50] cursor-pointer hover:bg-[#4CAF50]/10 transition-all duration-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                              <path d="M10 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V14M12 12L20 4M20 4V9M20 4H15" stroke="#2c3e50" strokeOpacity="1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-4 ">
                      {ReportDataList && ReportDataList.length > 0
                        ? ReportDataList.map((item) => (
                          <div className="p-6 py-2 space-y-2 border border-gray-300 rounded">
                            <p className="capitalize text-[#3D405B]/70  text-sm">
                              {item.name}
                            </p>
                            <h2 className="text-xl text-[#3D405B]">
                              {item.value}
                            </h2>
                          </div>
                        ))
                        : null}
                    </div>

                    {true &&
                      <>
                        <div className="overflow-x-auto whitespace-nowrap ">

                          <div className="flex items-center justify-between mb-2">
                            <div className="flex space-x-2">
                              {/* <div>
                                <button onClick={() => this.handleMenuTab("assessment")}
                                  className="bg-blue-500 text-white p-2 px-4 rounded-md border border-gray-200 text-[#3D405B] group  hover: transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                                  <span>ASSESSMENT</span></button>
                              </div>
                              <div>
                                <button onClick={() => this.handleMenuTab("team")}
                                  className="bg-gray-500 text-white p-2 px-4 rounded-md border border-gray-200 text-[#3D405B] group  hover:transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                                  <span>TEAM</span></button>
                              </div> */}
                            </div>
                            <div>
                              <button onClick={() => this.downloadAccessmentCode(userAssessmentOrdersData)} className=" bg-white p-2 px-4 rounded-md border border-gray-200 text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                                <LuDownload className="text-[#3D405B] w-5 h-5 group-hover:text-[#2196f3] transition-all duration-150" />
                                <span>ASSESSMENT CODE</span></button>
                            </div>
                          </div>

                          <table className="w-full border border-gray-300 rounded-md ">
                            <tr className="text-[#3D405B] bg-gray-100 w-[40%]">
                              <th className="p-2 px-4 font-medium text-left">
                                Name
                              </th>
                              <th className="p-2 px-4 font-medium text-left w-[40%]">
                                Assessment Code
                              </th>
                              <th className="p-2 px-4 font-medium text-left w-[10%]">
                                Status
                              </th>
                              <th className="p-2 px-4 font-medium text-center w-[10%]">
                                Reports
                              </th>
                            </tr>
                            <tbody className="divide-y ">
                              {userAssessmentOrdersData &&
                                userAssessmentOrdersData.length > 0
                                ? userAssessmentOrdersData.map((element) => (
                                  <>
                                    <tr>
                                      <td className="p-2 px-4 text-left">
                                        <div className="flex items-center justify-between space-x-4">




                                          {showNameEdit && formNameData["id"] === element.id ? (
                                            <>
                                              <input
                                                value={formNameData["name"]}
                                                onChange={(e) =>
                                                  this.onSingleNameInput(
                                                    e.target.value,
                                                    element
                                                  )
                                                }
                                                className="border px-4 py-2 text-[#3D405B]/70 rounded-md focus:outline-none w-full "
                                                placeholder="Enter Name"
                                              ></input>
                                            </>
                                          ) : (

                                            <>

                                              {element && element.name ? (
                                                <span>{element.name}</span>
                                              ) : <input
                                                className="border px-4 py-2 text-[#3D405B]/70 rounded-md focus:outline-none w-full "
                                                disabled={true}
                                                placeholder="Name"
                                              ></input>}


                                            </>

                                          )}

                                          {crrLicensee === "team" ?
                                            <>
                                              {showNameEdit &&
                                                formNameData["id"] === element.id ? (
                                                <span
                                                  onClick={() =>
                                                    this.onSingleNameSave(element, false)
                                                  }
                                                  className="border border-gray-300 text-[#4CAF50] hover:text-[#4CAF50] p-2 rounded cursor-pointer hover:bg-[#4CAF50]/10 transition-all duration-100 "
                                                >
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy " width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none" />  <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />  <circle cx="12" cy="14" r="2" />  <polyline points="14 4 14 8 8 8 8 4" /></svg>
                                                </span>
                                              ) : (
                                                <span
                                                  onClick={() =>
                                                    this.onEditSingleName(element, true)
                                                  }
                                                  className="border  text-[#3D405B]/70 hover:text-[#4CAF50] p-2 rounded cursor-pointer hover:bg-[#4CAF50]/10 transition-all duration-100 "
                                                >
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy " width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>  <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>  <path d="M13.5 6.5l4 4"></path></svg>
                                                </span>
                                              )}
                                            </> : null}
                                        </div>
                                      </td>
                                      <td className="px-4 py-2">
                                        <div className="flex items-center justify-between space-x-4">
                                          <span className="border-[1.8px] w-full text-center rounded border-spacing-4 border-separate border-dashed p-2 px-6 bg-[#4CAF50]/10 text-[#4CAF50]  border-[#4CAF50]">{element.otpCode}</span>
                                          <span onClick={() => { this.handleClick2(element.otpCode); navigator.clipboard.writeText(element.otpCode) }} className="border  text-[#3D405B]/70 hover:text-[#4CAF50] p-2 rounded cursor-pointer hover:bg-[#4CAF50]/10 transition-all duration-100 ">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy " width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path stroke="none" d="M0 0h24v24H0z" fill="none" /><rect x="8" y="8" width="12" height="12" rx="2" /><path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" /></svg>
                                          </span>
                                        </div>
                                      </td>
                                      <td className="px-4 py-2">
                                        {element && element.isComplete ?
                                          <span className="px-6 py-2 bg-[#4CAF50] text-white text-sm rounded w-36">Completed</span>
                                          :
                                          <span className="px-6 py-2 bg-[#FFC107] text-white text-sm rounded w-36">Pending</span>
                                        }
                                      </td>

                                      <td className="px-4 py-2 text-center">
                                        {element && element.isReport ?
                                          <button onClick={() => this.viewReport(element, true)} className="border text-[#3D405B]/70 hover:text-[#4CAF50] p-2 rounded cursor-pointer hover:bg-[#4CAF50]/10 transition-all duration-100 ">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy " width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" /><rect x="9" y="3" width="6" height="4" rx="2" /><line x1="9" y1="12" x2="9.01" y2="12" /><line x1="13" y1="12" x2="15" y2="12" /><line x1="9" y1="16" x2="9.01" y2="16" /><line x1="13" y1="16" x2="15" y2="16" /></svg>
                                          </button>
                                          :
                                          <button className="border text-[#3D405B]/30  p-2 rounded cursor-not-allowed">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy " width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" /><rect x="9" y="3" width="6" height="4" rx="2" /><line x1="9" y1="12" x2="9.01" y2="12" /><line x1="13" y1="12" x2="15" y2="12" /><line x1="9" y1="16" x2="9.01" y2="16" /><line x1="13" y1="16" x2="15" y2="16" /></svg>
                                          </button>
                                        }

                                      </td>
                                    </tr>
                                  </>
                                ))
                                : null}
                            </tbody>
                          </table>

                          <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              userAssessmentOrdersData && userAssessmentOrdersTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={Math.ceil(userAssessmentOrdersTotal / this.state.size)}
                                  marginPagesDisplayed={3}
                                  pageRangeDisplayed={3}
                                  onPageChange={this.handleTeamPageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                  forcePage={this.state.page} // Synchronize selected page
                                />
                                : null}
                          </nav>

                        </div>
                      </>
                    }
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </section>

        <section
          style={{
            backgroundImage: `url('/img/Home/book_a_demo_bg.webp')`,
          }}
          className=" bg-[#E3F2FD] xl:bg-contain bg-cover bg-no-repeat bg-center   "
        >
          <div className="px-4 py-20 mx-auto space-y-8 text-center md:w-4/6 md:px-0">
            <div className="space-y-4 ">
              <h1 className="xl:text-4xl text-3xl leading-[3rem] xl:leading-[4rem] font-semibold text-[#212121] text-center capitalize aos-init aos-animate">
                Ready to leap from feedback to action?
              </h1>
              <p className="text-xl font-semibold text-[#3D405B]">
                Invest in your people today. If not now, when?
              </p>
            </div>
            <button
              onClick={this.handleRedirect}
              data-aos="fade-up"
              // onClick={() => this.gotoPage("contact-us")}
              className="bg-[#212121] hover:bg-[#1976D2] hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black transition-all duration-150  text-white py-2.5 px-6 rounded uppercase cursor-pointer flex justify-center items-center w-56 mx-auto"
            >
              Book a Demo
            </button>
          </div>
        </section>

        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />

        {
          this.state.modaleOpen ? (
            <div className="fixed top-0 right-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-40 xl:h-screen">
              <div className="w-11/12 mx-auto bg-white rounded-lg shadow-sm xl:w-6/12 lg:w-9/12 ">
                <div className="flex items-center justify-between p-4 border-b md:px-8 ">
                  <h2 className="text-lg font-semibold md:text-xl test-gray-800 ">
                    Upload Team
                  </h2>
                  <div
                    onClick={() => this.ModalOpen()}
                    className="p-2 rounded-full cursor-pointer bg-slate-100 hover:bg-slate-200 "
                  >
                    <svg
                      className="w-6 h-6 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                    </svg>
                  </div>
                </div>
                <div className="m-4 space-y-8 md:m-8 ">
                  <div className="p-4 space-y-2 text-sm border divide-y rounded-md text-[#3D405B]">
                    <h1 className="text-lg font-semibold text-[#3D405B] ">
                      {" "}
                      Guidelines for uploading the data
                    </h1>
                    <ol className="list-disc pl-6 space-y-1.5 py-4 text-[#3D405B] ">
                      <li className>
                        {" "}
                        Mandatory Column include emp_id and email as first column
                        &amp; second column should be in the file.
                      </li>
                      <li className="capitalize">
                        {" "}
                        Your file columns/headers should not contain any special
                        characters – this includes characters that are not numbers
                        or the English alphabet.
                      </li>
                      <li className="capitalize">
                        {" "}
                        Be careful not to include duplicates of the same row – no
                        two employees should have the same emp_id or email.
                      </li>
                      <li className="capitalize">
                        {" "}
                        Date format must be same as DD-MM-YYYY
                      </li>
                      <li className="capitalize">
                        {" "}
                        See the sample data format for reference and prepare your
                        master data accordingly without any change in mandatory
                        column names
                      </li>
                      <li className="capitalize">
                        {" "}
                        Make sure your Excel sheet is not password protected.
                      </li>
                    </ol>
                  </div>
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-4 xl:grid-cols-4 md:grid-cols-2">
                      <div className="flex items-start p-2 px-4 space-x-4 rounded shadow-sm cursor-pointer 2xl:items-center bg-sky-100 md:col-span-2">
                        <span className="p-2 text-blue-500 bg-white rounded-full shadow-sm material-symbols-outlined">
                          download
                        </span>
                        <div onClick={() => this.handleDownloadSample()}>
                          <h1 className="text-sm font-medium md:text-base text-[#3D405B] ">
                            Download sample date
                          </h1>
                          <p className="text-sm text-gray-500">
                            See the sample data format for reference
                          </p>
                        </div>
                      </div>
                      <input type="file" id="file" accept=".xlsx,.csv" hidden />
                      <label
                        htmlFor="file"
                        className="rounded shadow-sm cursor-pointer bg-sky-100 md:col-span-2"
                      >
                        <div className="flex items-start p-2 px-4 space-x-4 2xl:items-center ">
                          <span className="p-2 text-blue-500 bg-white rounded-full shadow-sm material-symbols-outlined">
                            upload
                          </span>
                          <div>
                            <h1 className="text-sm font-medium md:text-base text-[#3D405B] ">
                              Upload Master Data
                            </h1>
                            <p className="text-sm text-gray-500">
                              Upload master data as per the guidelines
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="hidden">
                      <button className=" border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none">
                        Sample CSV
                      </button>
                      <button className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }


        {
          showSummaryModal ? (
            <ReportSummary
              closeReportModal={this.closeReportModal}
              getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
              getAssessmenrEmployeeDetailsData={crrEmpDetails}
              createdAt={createdAt}
              EditButtonFn={EditButtonFn}
              EditDetailsFn={EditDetailsFn}
              handleDownloadStatus={this.handleDownloadStatus}
              loading={loading}
              backSummary={this.state.backSummary}
              showMainSummaryModal={this.state.showMainSummaryModal}
              contactExpertSection={this.state.contactExpertSection}
              topFiveTalent={this.state.topFiveTalent}
              handleViewReport={this.handleViewReport}
              closeReportummaryModal={this.closeReportummaryModal}

              handleShowMainSummaryModal={this.handleShowMainSummaryModal}
              handleShowMainSummaryModalBack={this.handleShowMainSummaryModalBack}
              handleExpertContactModalBack={this.handleExpertContactModalBack}
              handleExpertContactModal={this.handleExpertContactModal}
              crrDetail={this.state.crrDetail}
              crrCodeUserDetails={this.state.crrCodeUserDetails}

              handleInputExpertForm={this.handleInputExpertForm}
              bookExpert={this.bookExpert}

              userAssessmentExpertOrderPlaceData={userAssessmentExpertOrderPlaceData}
              handleTestPayment={this.handleTestPayment}

              showMainMenu={this.state.showMainMenu}
              handleMainMenu={this.handleMainMenu}
              handleDashboard={this.handleDashboard}
              showDashboard={this.state.showDashboard}
              handleReport={this.handleReport}
              isSchool={this.state.crrcode === "Top5TeenTalentplusStrengths" ? true : false}

              showReportModal={showReportModal}
              showSummaryReport={showSummaryReport}
              showManagerReportModal={showManagerReportModal}
              showHiringReportModal={showHiringReportModal}
              showSchoolReportModal={showSchoolReportModal}
              crrEmpDetails={crrEmpDetails}


              closeSummaryReportModal={this.closeSummaryReportModal}
              closeManagerReportModal={this.closeManagerReportModal}
              closeHiringReportModal={this.closeHiringReportModal}

              code={this.state.crrcode}
              crrEmployeeId={crrEmployeeId}

              getAptitudeTestScoreData={getAptitudeTestScoreData}
              getCareerFilamentScoreData={getCareerFilamentScoreData}
              getCareerSurveyListData={getCareerSurveyListData}
              getPersonalityIndexData={getPersonalityIndexData}
              getWorkValueDimensionScoreData={getWorkValueDimensionScoreData}
              getInterestDimensionScoreData={getInterestDimensionScoreData}

            />
          ) : null
        }

        {
          showTeamModal ? (
            <NewTeamModal
              closeModal={this.closeReportModal}
              newEmpFormData={newEmpFormData}
              newEmpFormDataError={newEmpFormDataError}
              handleUserSubmit={this.handleUserSubmit}
              handleNewEmpInput={this.handleNewEmpInput}
            />
          ) : null
        }



      </div >
    );
  }
}
MyOrders.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(MyOrders);