import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import Footer from "../Home/Component/Footer";
import OptionType from "./Components/OptionType";
import AOS from "aos";
import { GiCheckMark } from "react-icons/gi";
import { Helmet } from "react-helmet";
class Package extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      formData: {
        quantity: 1,
        type: "",
        licensee: "self",
        nameList: [""],
      },
      activeTab: 3,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    this.props.dispatch(assessmentActions.getAssessmentCartCount());
    this.props.dispatch(assessmentActions.getAssessmentPackagesList());
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.userAssessmentSignInSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  changeQuantity = (type) => {
    let { formData } = this.state;
    if (type === "add") {
      formData["quantity"] += 1;
      formData["nameList"].push("");
    } else if (type === "sub" && formData["quantity"] > 1) {
      formData["quantity"] -= 1;
      formData["nameList"].pop();
    }
    this.setState({ formData });
  };

  onPurchase = (product) => {
    let { formData } = this.state;
    formData["productId"] = product.id;
    this.setState({ formData });

    if (product.code === "Top5TalentplusStrengths") {
      console.log("product--->", product);

      this.setState({ showNameModal: true });
    } else {

      this.props.history.push(
        `/assessment/productdetails/team/` + product.id.toString()
      );
    }
  };

  closeNameEntryModal = () => {
    this.setState({ showNameModal: false });
  };

  handleNameInput = (value, index) => {
    let { formData } = this.state;
    formData["nameList"][index] = value;
    this.setState({ formData });
  };

  handleOptionInput = (value) => {
    let { formData } = this.state;
    formData["licensee"] = value;
    this.setState({ formData });
  };

  handleRedirect = () => {
    this.props.history.push('/assessment/contact-us');
  }

  // handleOptionSubmit = () => {
  //   let { formData } = this.state;
  //   let nameList = []
  //   if (formData && formData["nameList"].length > 0) {
  //     formData["nameList"].forEach((ele) => {
  //       if (ele) {
  //         nameList.push(ele)
  //       }
  //     })
  //   }
  //   let data = {
  //     quantity: formData["quantity"],
  //     type: formData["type"],
  //     licensee: formData["licensee"],
  //     nameList: nameList
  //   }
  //   this.props.dispatch(assessmentActions.userAssessmentPackageBuy(data, this.props));
  // }

  handleOptionSubmit = () => {
    let { formData } = this.state;

    if (formData["licensee"] === "self") {
      this.props.history.push(
        `/assessment/productdetails/self/` + formData["productId"].toString()
      );
    } else if (formData["licensee"] === "team") {
      this.props.history.push(
        `/assessment/productdetails/team/` + formData["productId"].toString()
      );
    }
  };

  handleSignInPage = () => {
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (!(userAssessmentUserProfileData && userAssessmentUserProfileData.email)) {
        this.props.history.push(`/assessment/` + 'userlogin');
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };
  handleDownload = (code) => {
    const link = document.createElement('a');
    link.href = '/' + code.toString() + '.pdf';
    link.download = code.toString() + '.pdf';
    link.click();

    console.log(' link.href link.href link.href link.href', link.href);
  }

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem('route')
      localStorage.setItem('route', `/assessment/` + field)
      this.props.history.push(`/assessment/sign-in`);
    }
  }

  setActiveTab = (tabNumber) => {
    this.setState({
      activeTab: tabNumber,
    });
  };

  render() {
    let { assessment } = this.props;
    let {
      userAssessmentUserProfileData,
      getAssessmentPackagesListData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData
    } = assessment;
    let { quantity, formData, showNameModal } = this.state;
    console.log("quantity-->", quantity);


    function getProductNumber(code) {
      let productno = 1;
      if (code) {
        if (code === "Top5TalentplusStrengths") {
          productno = 1;
        } else if (code === "TalentPlusStrengths21") {
          productno = 2;
        } else if (code === "TalentplusManagerStrengths") {
          productno = 3;
        } else if (code === "TalentPlusHiringStrengths") {
          productno = 4;
        } else if (code === "TeenCareerGuidance") {
          productno = 5;
        }
      }
      return productno
    }


    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The TalentPlusStrengths Assessment Find the Report That's Right for You</title>
          <meta name="description" content="Your customized TalentPlusStrengths strengths reports will help you to experience new ways to understand what makes you so unique." />
          <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/products" />
        </Helmet>

        <HeaderTransprent
          handleSignInPage={this.handleSignInPage}
          userDetails={userAssessmentUserProfileData}
          logout={this.logout}
          gotoPage={this.gotoPage}
          cartCount={
            getAssessmentCartCountData ? getAssessmentCartCountData : 0
          }
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}

        />

        <section
          // style={{
          //   backgroundImage: `url('/img/Home/book_a_demo_bg.webp')`,
          // }}
          className="bg-white bg-center bg-no-repeat bg-cover xl:bg-contain"
        >

          <div className="2xl:w-9/12 xl:w-11/12 w-full  mx-auto xl:px-0 px-4  xl:pt-20 pt-10   space-y-4 text-center text-[#212121]">
            {/* <p data-aos="slide-up" className="text-lg text-[#2196F3] font-medium uppercase tracking-widest ">Reports</p> */}
            <h1 data-aos="slide-up" className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[4rem]  capitalize font-bold text-[#212121]">   Choose Your Report </h1>
            <p data-aos="slide-up" className="text-lg text-[#212121] ">   Your customized TalentPlusStrengths reports will help you to
              experience new ways to understand what makes you so unique.</p>
          </div>
        </section>



        <section className="w-full pt-10 mx-auto 2xl:w-9/12 xl:w-11/12 ">
          <div className="flex flex-col justify-start w-full gap-2 px-4 md:flex-row md:justify-center xl:px-0 ">
          
            <div className={`p-6 py-2 rounded-t-xl border cursor-pointer text-center ${this.state.activeTab === 3 ? 'active-tab text-[#2196F3] border-b-4 border-[#2196F3]' : 'text-[#212121] p-6 py-2 bg-white border rounded-t-xl'}`} onClick={() => this.setActiveTab(3)}>
              <p className="text-lg font-medium capitalize">Teen</p>
              <p className="text-sm text-[#212121]/70 font-medium">People 20 years and below</p>
            </div>
            <div className={`p-6 py-2 rounded-t-xl border cursor-pointer text-center ${this.state.activeTab === 1 ? 'active-tab text-[#2196F3] border-b-4 border-[#2196F3]' : 'text-[#212121] p-6 py-2 bg-white border rounded-t-xl'}`} onClick={() => this.setActiveTab(1)}>
              <p className="text-lg font-medium capitalize">individual</p>
              <p className="text-sm text-[#212121]/70 font-medium">People 21 years and above</p>
            </div>

            <div className={`p-6 py-2 rounded-t-xl border cursor-pointer text-center ${this.state.activeTab === 2 ? 'active-tab text-[#2196F3] border-b-4 border-[#2196F3]' : 'text-[#212121] p-6 py-2 bg-white border rounded-t-xl'}`} onClick={() => this.setActiveTab(2)}>
              <p className="text-lg font-medium capitalize">business</p>
              <p className="text-sm text-[#212121]/70 font-medium">For Larger Team</p>
            </div>

          </div>
        </section>


        {this.state.activeTab === 1 && (
          <div>
            <section className="w-full px-4 pt-10 pb-20 mx-auto space-y-16 xl:pt-20 2xl:w-9/12 xl:w-11/12 xl:px-0">
              <div className="w-full gap-10 mx-auto lg:w-5/6">
                <div className="grid grid-cols-1 gap-10 md:grid-cols-2 ">
                  {getAssessmentPackagesListData &&
                    getAssessmentPackagesListData.length > 0
                    ? getAssessmentPackagesListData.map((item) => (
                      !(item && item.isSchool) ?
                        <>
                          <div data-aos="fade-in" className="border hover:bg-[#2196F3]/5 border-[#2196F3]/50 md:p-10 p-6 gap-4 rounded-3xl items-start box-shadow-custom cursor-pointer space-y-4 flex flex-col justify-between h-full">

                            <div>
                              <div className="space-y-6">
                                <div className="space-y-4">
                                  <h1 className="text-lg text-[#212121] font-semibold">
                                    {item.name}
                                  </h1>
                                  {getProductNumber(item.code) !== 3 ?
                                    <h2 className="text-2xl font-semibold text-[#2196F3]">
                                      <span className=" font-[roboto]">₹</span>{item.price}
                                    </h2>
                                    : null}
                                  <p className="pb-6 border-b border-[#2196F3]/50 text-[#212121]/80">{item.desc}</p>
                                </div>
                                <div className="space-y-2 ">
                                  <p className="text-lg font-semibold">
                                    Feature included:
                                  </p>
                                  <ul className="space-y-2 text-[#212121]/80  ">
                                    {item &&
                                      item.features &&
                                      item.features.length > 0
                                      ? item.features.map((element) => (
                                        <li className="flex flex-row items-start gap-1.5">
                                          <span className="pt-0.5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                              <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                                            </svg>
                                          </span>{element}</li>
                                      ))
                                      : null}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="w-full pt-6">
                              <div className="">

                                {(getProductNumber(item.code) !== 3) && (getProductNumber(item.code) !== 4) ?
                                  <>
                                    {(getProductNumber(item.code) === 5) ?
                                      <div className="flex  items-center  flex-row md:justify-start md:items-center">
                                        <button onClick={() => this.onPurchase(item)} data-aos="fade-right" className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center aos-init aos-animate">Buy Now</button>
                                        <button onClick={() => this.gotoPage("teen-career-guidance")} data-aos="fade-right" className="flex space-x-2 items-center underline border-black hover:text-[#2196F3] transition-all duration-150 text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center aos-init aos-animate font-medium"><span> learn more</span>  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-[1.5rem]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 6l6 6l-6 6"></path></svg></button></div>

                                      :
                                      <div className="flex  items-center  flex-row md:justify-start md:items-center">
                                        <button onClick={() => this.onPurchase(item)} data-aos="fade-right" className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center aos-init aos-animate">Buy Now</button>
                                        <button onClick={() => this.gotoPage("the-4-domains-and-22-themes")} data-aos="fade-right" className="flex space-x-2 items-center underline border-black hover:text-[#2196F3] transition-all duration-150 text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center aos-init aos-animate font-medium"><span> learn more</span>  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-[1.5rem]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 6l6 6l-6 6"></path></svg></button></div>
                                    }
                                  </>
                                  :
                                  <>

                                    <div className="flex  items-center  flex-row md:justify-start md:items-center">
                                      <button onClick={() => this.gotoPage("contact-us")} data-aos="fade-right" className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center aos-init aos-animate">  Connect Us</button>
                                      <button onClick={() => this.gotoPage("the-4-domains-and-22-themes")} data-aos="fade-right" className="flex space-x-2 items-center underline border-black hover:text-[#2196F3] transition-all duration-150 text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center aos-init aos-animate font-medium"><span>  learn more</span>  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-[1.5rem]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 6l6 6l-6 6"></path></svg></button></div>


                                  </>
                                }


                              </div>

                              <div className="flex items-center justify-center w-full mt-12 border-t ">
                                <button onClick={() => this.handleDownload(item.code)} className="  hover:text-[#2196F3] cursor-pointer transition-all duration-150 text-[#212121] py-2 px-8 rounded uppercase space-x-2 flex items-center font-medium pt-6 w-full   justify-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-clipboard-text"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
                                    <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path>
                                    <path d="M9 12h6"></path>
                                    <path d="M9 16h6"></path>
                                  </svg>
                                  <span>view sample report</span>
                                </button>
                              </div>
                            </div>

                          </div>
                        </>
                        : null
                    ))
                    : null}
                </div>
              </div>
            </section>

            <div className=" bg-[#F9FAFB]">
              {/* 3 */}
              <section className="flex flex-col items-center w-full gap-10 px-4 py-20 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
                <div data-aos="fade-right" className="w-full space-y-6 text-[#212121]">
                  <h1 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]   ">
                    Experience Top 5 TalentPlusStrengths
                  </h1>
                  <p className="md:text-lg text-base text-[#212121]">
                    With your purchase of product Top 5 TalentPlusStrengths , you join
                    TalentPlusStrengths  Access platform that helps you be your best transformation
                    tool and develop your team.
                  </p>
                  <p className="text-2xl font-semibold  text-[#212121] pt-2">
                    Top 5 TalentPlusStrengths allows you to:
                  </p>
                  <ul className="pl-6 space-y-2 ">
                    <li className="text-[#212121] flex space-x-2">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                      </span>
                      Unlock your basic TalentPlusStrengths reports
                    </li>
                    <li className="text-[#212121] flex space-x-2">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                      </span>
                      Get a partial view of your full TalentPlusStrengths profile
                    </li>
                    <li className="text-[#212121] flex space-x-2">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                      </span>
                      Start learning about some of your strongest TalentPlusStrengths
                    </li>
                  </ul>
                </div>
                <div className="w-full">
                  <img data-aos="fade-left" className="object-cover object-center h-2/6 " src="/img/Home/top5_1.webp" alt="top5_1.webp" />
                </div>
              </section>
            </div>

            <section className="flex flex-col-reverse items-center w-full gap-10 px-4 py-20 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row">
              <div data-aos="fade-right" className="w-full space-y-6 text-[#212121]">
                <h1 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]">
                  Experience TalentPlusStrengths 28
                </h1>
                <p className="md:text-lg text-base text-[#212121]">
                  With your purchase of product TalentPlusStrengths 28 , you join
                  TalentPlusStrengths  Access platform that helps you be your best
                  transformation tool and develop your team
                </p>
                <p className="text-2xl font-semibold text-[#212121] pt-2">
                  TalentPlusStrengths 28 allows you to
                </p>
                <ul className="pl-6 space-y-2 ">
                  <li className="text-[#212121] flex space-x-2">
                    <span className="pr-2">
                      <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                    </span>
                    Share your TalentPlusStrengths results with others and download results that are shared with you.

                  </li>

                  <li className="text-[#212121] flex space-x-2">
                    <span className="pr-2">
                      <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                    </span>Learn from Talent's library of articles, videos and other TalentPlusStrengths content.
                  </li>
                  <li className="text-[#212121] flex space-x-2">
                    <span className="pr-2">
                      <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                    </span>Manage everything via your personalized dashboard.
                  </li>
                </ul>
              </div>
              {/* img */}
              <div className="w-full">
                <img data-aos="fade-left" className="object-cover " src="/img/assessment/talentplus_strengths_21_3_4.webp" alt="talentplus_strengths21.webp" />
              </div>
            </section>



            <div className=" bg-[#F9FAFB]">
              {/* 2 */}
              <section className="flex flex-col items-center w-full gap-10 px-4 py-20 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
                <div className="w-full">
                  <img data-aos="fade-right" src="/img/Home/understand-strongest_talentplus-strength.webp" className="shadow-xl" alt="understand-strongest_talentplus-strength.webp" />
                </div>
                <div data-aos="fade-left" className="w-full space-y-6 text-[#212121]">
                  <h1 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]">
                    Identify All Your TalentPlusStrengths
                  </h1>
                  <p className="md:text-lg text-base text-[#212121]">
                    TalentPlusStrengths 28 helps you make the most of your top talents
                  </p>
                  <p className="pt-2 text-2xl font-semibold">
                    TalentPlusStrengths  allows you to
                  </p>
                  <ul className="pl-6 space-y-2 ">
                    <li className="text-[#212121] flex space-x-2">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                      </span>
                      The TalentPlusStrengths Introduction interactive module gives you an overview of your strongest TalentPlusStrengths and an action plan for how to aim them to achieve your goals.
                    </li>
                    <li className="text-[#212121] flex space-x-2">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                      </span>The TalentPlusStrengths 28 Report provides personalized insights for your TalentPlusStrengths 1-5.
                      Plus, you get detailed information about your TalentPlusStrengths 6-22, including instructions and ideas for how to use each of them to succeed.
                    </li>
                  </ul>
                </div>
              </section>
            </div>




          </div>


        )}

        {this.state.activeTab === 2 && (
          <section className="w-full px-4 pt-10 pb-20 mx-auto space-y-16 xl:pt-20 2xl:w-9/12 xl:w-11/12 xl:px-0">
            <div className="w-full gap-10 mx-auto lg:w-5/6">
              <div className="grid grid-cols-1 gap-10 md:grid-cols-2 ">

                <div data-aos="fade-in" className="border hover:bg-[#2196F3]/5 border-[#2196F3]/50 md:p-10 p-6 gap-4 rounded-3xl items-center box-shadow-custom cursor-pointer space-y-4 flex flex-col justify-between h-full">
                  <img src="img/assessment/organization.svg" alt="organization" />
                  <div>
                    <div className="space-y-6 text-center">
                      <div className="space-y-4">
                        <h1 className="xl:text-3xl text-4xl leading-[3rem] xl:leading-[2.5rem] font-semibold text-[#212121]">Interested in Buying for a Organization? </h1>
                        <h1 className="text-lg text-[#212121] font-semibold pt-4">Connect With Us to Find the Right Solution</h1>
                        <p className="pb-6 text-[#212121]/80">Contact us and TalentPlus expert will help you identify the best way to bring a strengths-based approach to your larger group.</p>
                      </div>
                    </div>
                  </div>

                  <div className="w-full">
                    <div className="">
                      <div className="flex items-center flex-row md:justify-start md:items-center">
                        <button onClick={() => this.gotoPage("contact-us")} data-aos="fade-right" className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-full justify-center aos-init aos-animate">Contact Us</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div data-aos="fade-in" className="border hover:bg-[#2196F3]/5 border-[#2196F3]/50 md:p-10 p-6 gap-4 rounded-3xl items-center box-shadow-custom cursor-pointer space-y-4 flex flex-col justify-between h-full">
                  <img src="img/assessment/school or institution.svg" alt="school or institution" />
                  <div>
                    <div className="space-y-6 text-center">
                      <div className="space-y-4">
                        <h1 className="xl:text-3xl text-4xl leading-[3rem] xl:leading-[2.5rem] font-semibold text-[#212121]">Interested in Buying for a School / Institution?</h1>
                        <h1 className="text-lg text-[#212121] font-semibold pt-4">Connect With Us to Find the Right Solution</h1>
                        <p className="pb-6 text-[#212121]/80">Contact us and TalentPlus expert will help you identify the best way to bring a strengths-based approach to your larger group.</p>
                      </div>
                    </div>
                  </div>

                  <div className="w-full">
                    <div className="">
                      <div className="flex items-center flex-row md:justify-start md:items-center">
                        <button onClick={() => this.gotoPage("contact-us")} data-aos="fade-right" className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-full justify-center aos-init aos-animate">Contact Us</button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        )}

        {this.state.activeTab === 3 && (
          <>
            <section className="w-full px-4 pt-10 pb-20 mx-auto space-y-16 xl:pt-20 2xl:w-9/12 xl:w-11/12 xl:px-0">
              <div className="w-full gap-10 mx-auto lg:w-5/6">
                <div className="grid grid-cols-1 gap-10 md:grid-cols-2 ">
                  {getAssessmentPackagesListData &&
                    getAssessmentPackagesListData.length > 0
                    ? getAssessmentPackagesListData.map((item) => (
                      (item && item.isSchool) ?

                        <div data-aos="fade-in" className="border hover:bg-[#2196F3]/5 border-[#2196F3]/50 md:p-10 p-6 gap-4 rounded-3xl items-start box-shadow-custom cursor-pointer space-y-4 flex flex-col justify-between h-full">

                          <div>
                            <div className="space-y-6">
                              <div className="space-y-4">
                                <h1 className="text-lg text-[#212121] font-semibold">
                                  {item.name}
                                </h1>
                                {getProductNumber(item.code) !== 3 ?
                                  <h2 className="text-2xl font-semibold text-[#2196F3]">
                                    <span className=" font-[roboto]">₹</span>{item.price}
                                  </h2>
                                  : null}
                                <p className="pb-6 border-b border-[#2196F3]/50 text-[#212121]/80">{item.desc}</p>
                              </div>
                              <div className="space-y-2 ">
                                <p className="text-lg font-semibold">
                                  Feature included:
                                </p>
                                <ul className="space-y-2 text-[#212121]/80  ">
                                  {item &&
                                    item.features &&
                                    item.features.length > 0
                                    ? item.features.map((element) => (
                                      <li className="flex flex-row items-start gap-1.5">
                                        <span className="pt-0.5">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                            <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                                          </svg>
                                        </span>{element}</li>
                                    ))
                                    : null}
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="w-full pt-6">
                            <div className="">

                              {(getProductNumber(item.code) !== 3) && (getProductNumber(item.code) !== 4) ?
                                <>
                                  {(getProductNumber(item.code) === 5) ?

                                    <div className="flex  items-center  flex-row md:justify-start md:items-center">
                                      <button onClick={() => this.onPurchase(item)} data-aos="fade-right" className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center aos-init aos-animate">Buy Now</button>

                                      <button onClick={() => this.gotoPage("teen-career-guidance")} data-aos="fade-right" className="flex space-x-2 items-center underline border-black hover:text-[#2196F3] transition-all duration-150 text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center aos-init aos-animate font-medium"><span> learn more</span>  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-[1.5rem]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 6l6 6l-6 6"></path></svg></button></div>

                                    :
                                    <div className="flex  items-center  flex-row md:justify-start md:items-center">
                                      <button onClick={() => this.onPurchase(item)} data-aos="fade-right" className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center aos-init aos-animate">Buy Now</button>

                                      <button onClick={() => this.gotoPage("teen-talent")} data-aos="fade-right" className="flex space-x-2 items-center underline border-black hover:text-[#2196F3] transition-all duration-150 text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center aos-init aos-animate font-medium"><span> learn more</span>  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-[1.5rem]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 6l6 6l-6 6"></path></svg></button></div>
                                  }
                                </> :
                                <>

                                  <div className="flex  items-center  flex-row md:justify-start md:items-center">
                                    <button onClick={() => this.gotoPage("contact-us")} data-aos="fade-right" className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center aos-init aos-animate">  Connect Us</button>

                                    <button onClick={() => this.gotoPage("teen-talent")} data-aos="fade-right" className="flex space-x-2 items-center underline border-black hover:text-[#2196F3] transition-all duration-150 text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center aos-init aos-animate font-medium"><span>  learn more</span>  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-[1.5rem]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 6l6 6l-6 6"></path></svg></button></div>

                                </>
                              }


                            </div>

                            <div className="flex items-center justify-center w-full mt-12 border-t ">
                              <button onClick={() => this.handleDownload(item.code)} className="  hover:text-[#2196F3] cursor-pointer transition-all duration-150 text-[#212121] py-2 px-8 rounded uppercase space-x-2 flex items-center font-medium pt-6 w-full   justify-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-clipboard-text"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
                                  <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path>
                                  <path d="M9 12h6"></path>
                                  <path d="M9 16h6"></path>
                                </svg>
                                <span>view sample report</span>
                              </button>
                            </div>
                          </div>

                        </div>

                        : null
                    ))
                    : null}
                </div>
              </div>
            </section>

            {/* 3 */}
            <div className=" bg-[#F9FAFB]">
              <section className="flex flex-col items-center w-full gap-10 px-4 py-20 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
                <div data-aos="fade-right" className="w-full space-y-6 text-[#212121]">
                  <h1 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]   ">
                    Experience Top 5 Teen TalentPlusStrengths
                  </h1>
                  <p className="md:text-lg text-base text-[#212121]">
                    With your purchase of product Top 5 Teen TalentPlusStrengths , you join Teen
                    TalentPlusStrengths  Access platform that helps you be your best transformation
                    tool.
                  </p>
                  <p className="text-2xl font-semibold  text-[#212121] pt-2">
                    Top 5 Teen TalentPlusStrengths allows you to:
                  </p>
                  <ul className="pl-6 space-y-2 ">
                    <li className="text-[#212121] flex space-x-2">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                      </span>
                      Unlock your basic Teen TalentPlusStrengths reports
                    </li>
                    <li className="text-[#212121] flex space-x-2">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                      </span>
                      Get a partial view of your full Teen TalentPlusStrengths profile
                    </li>
                    <li className="text-[#212121] flex space-x-2">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                      </span>
                      Start learning about some of your strongest Teen TalentPlusStrengths
                    </li>
                  </ul>
                </div>
                <div className="w-full">
                  <img data-aos="fade-left" className="object-cover object-center h-2/6 " src="/img/Home/teen-top-5.webp" alt="teen-top-5.webp" />
                </div>
              </section>
            </div>


            <section className="flex flex-col-reverse items-center w-full gap-10 px-4 py-20 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row">
              <div data-aos="fade-right" className="w-full space-y-6 text-[#212121]">
                <h1 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]">
                  Experience Teen TalentPlusStrengths 18
                </h1>
                <p className="md:text-lg text-base text-[#212121]">
                  With your purchase of product Teen TalentPlusStrengths 18 , you join Teen TalentPlusStrengths Access platform that helps you be your best transformation tool.
                </p>
                <p className="text-2xl font-semibold text-[#212121] pt-2">
                  Teen TalentPlusStrengths 18 allows you to
                </p>
                <ul className="pl-6 space-y-2 ">
                  <li className="text-[#212121] flex space-x-2">
                    <span className="pr-2">
                      <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                    </span>
                    Share your Teen TalentPlusStrengths results with others and download results that are shared with you.

                  </li>

                  <li className="text-[#212121] flex space-x-2">
                    <span className="pr-2">
                      <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                    </span>Learn from Talent's library of articles, videos and other Teen TalentPlusStrengths content.
                  </li>
                  <li className="text-[#212121] flex space-x-2">
                    <span className="pr-2">
                      <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                    </span>Manage everything via your personalized dashboard.
                  </li>
                </ul>
              </div>
              {/* img */}
              <div className="w-full">
                <img data-aos="fade-left" className="object-cover " src="/img/Home/teen-talent.webp" alt="teen-talent.webp" />
              </div>
            </section>




            {/* 2 */}
            <div className=" bg-[#F9FAFB]">
              <section className="flex flex-col items-center w-full gap-10 px-4 py-20 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
                <div className="w-full">
                  <img data-aos="fade-right" src="/img/Home/Teen-TalentPlusStrengths-Assessment-Summary.webp" className="shadow-xl" alt="Teen-TalentPlusStrengths-Assessment-Summary.webp" />
                </div>
                <div data-aos="fade-left" className="w-full space-y-6 text-[#212121]">
                  <h1 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-bold text-[#212121]">
                    Identify All Your Teen TalentPlusStrengths
                  </h1>
                  <p className="md:text-lg text-base text-[#212121]">
                    Teen TalentPlusStrengths 18 helps you make the most of your top talents
                  </p>
                  <p className="pt-2 text-2xl font-semibold">
                    Teen TalentPlusStrengths  allows you to
                  </p>
                  <ul className="pl-6 space-y-2 ">
                    <li className="text-[#212121] flex space-x-2">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                      </span>
                      The Teen TalentPlusStrengths Introduction interactive module gives you an overview of your strongest Teen TalentPlusStrengths and an action plan for how to aim them to achieve your goals.
                    </li>
                    <li className="text-[#212121] flex space-x-2">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                      </span>The Teen TalentPlusStrengths 18 Report provides personalized insights for your Teen TalentPlusStrengths 1-5.
                      Plus, you get detailed information about your Teen TalentPlusStrengths 6-18, including instructions and ideas for how to use each of them to succeed.
                    </li>
                  </ul>
                </div>
              </section>
            </div>



            {/* <section className="w-full px-4 pt-10 pb-20 mx-auto space-y-16 xl:pt-20 2xl:w-9/12 xl:w-11/12 xl:px-0">
            <div className="w-full gap-10 mx-auto lg:w-5/6">
              <div className="grid grid-cols-1 gap-10 md:grid-cols-2 ">
                <div data-aos="fade-in" className="border hover:bg-[#2196F3]/5 border-[#2196F3]/50 md:p-10 p-6 gap-4 rounded-3xl items-start box-shadow-custom cursor-pointer space-y-4 flex flex-col justify-between h-full">

                  <div>
                    <div className="space-y-6">
                      <div className="space-y-4">
                        <h1 className="text-lg text-[#212121] font-semibold">Top 5 Teen TalentPlusStrengths</h1>
                        <h2 className="text-2xl font-semibold text-[#2196F3]">
                          <span className=" font-[roboto]">₹</span>₹1200
                        </h2>
                        <p className="pb-6 border-b border-[#2196F3]/50 text-[#212121]/80">Discover your top five dominant Teen TalentPlusStrengths themes (1-5) that profoundly shape your behavior. These themes not only enable you to accomplish more but also contribute to developing your personal brand, allowing you to authentically portray yourself and create a genuine impression.</p>
                      </div>
                      <div className="space-y-2 ">
                        <p className="text-lg font-semibold">
                          Feature included:
                        </p>
                        <ul className="space-y-2 text-[#212121]/80  ">
                          <li className="flex flex-row items-start gap-1.5">
                            <span className="pt-0.5">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                              </svg>
                            </span>Teen TalentPlusStrengths assessment</li>
                          <li className="flex flex-row items-start gap-1.5">
                            <span className="pt-0.5">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                              </svg>
                            </span>Custom strengths insights</li>
                          <li className="flex flex-row items-start gap-1.5">
                            <span className="pt-0.5">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                <path d="M0.818848 11.7611C4.16338 15.3798 7.40619 18.6376 10.5234 22.7579C13.9124 16.0173 17.3812 9.25324 23.1046 1.92809L21.5624 1.22168C16.7296 6.34699 12.9749 11.1986 9.71244 16.9642C7.44369 14.9204 3.77713 12.0282 1.53838 10.5423L0.818848 11.7611Z" fill="#2196F3" />
                              </svg>
                            </span>Theme definitions</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="w-full pt-6">
                    <div className="">
                      <div className="flex  items-center  flex-row md:justify-start md:items-center">
                        <button data-aos="fade-right" className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2.5 px-6 rounded uppercase cursor-pointer flex w-fit justify-center aos-init aos-animate">Buy Now</button>
                        <button onClick={() => this.gotoPage("the-4-domains-and-22-themes")} data-aos="fade-right" className="flex space-x-2 items-center underline border-black hover:text-[#2196F3] transition-all duration-150 text-black py-2.5 md:px-6 px-4 rounded uppercase cursor-pointer w-fit justify-center aos-init aos-animate font-medium"><span> learn more</span>  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-[1.5rem]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 6l6 6l-6 6"></path></svg></button></div>
                    </div>

                    <div className="flex items-center justify-center w-full mt-12 border-t ">
                      <button className="  hover:text-[#2196F3] cursor-pointer transition-all duration-150 text-[#212121] py-2 px-8 rounded uppercase space-x-2 flex items-center font-medium pt-6 w-full   justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-clipboard-text"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
                          <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path>
                          <path d="M9 12h6"></path>
                          <path d="M9 16h6"></path>
                        </svg>
                        <span>view sample report</span>
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section> */}

          </>
        )}



        {/* <section className="w-full px-4 pt-10 pb-20 mx-auto space-y-16 2xl:w-9/12 xl:w-11/12 xl:px-0">
          <div className="w-full gap-10 mx-auto lg:w-5/6">
            <div data-aos="fade-up" className="text-[#212121] bg-white border rounded-xl box-shadow-custom hover:bg-[#2196F3]/5 border-[#2196F3]/50">
              <div className="flex flex-col items-center justify-center p-4 mx-auto space-y-8 md:p-10 lg:space-y-0 lg:space-x-12 lg:justify-between lg:flex-row">
                <div className="flex flex-col space-y-4 text-center lg:text-left">
                  <h1 className="xl:text-3xl text-4xl leading-[3rem] xl:leading-[2.5rem] font-semibold text-[#212121]">
                    Interested in Buying for a Larger team or Organization?
                  </h1>
                  <div>
                    <p className="pb-2 text-xl font-semibold">
                      Connect With Us to Find the Right Solution
                    </p>
                    <p className="text-[#212121] text-lg w-full">
                      Contact us and TalentPlusStrengths  expert will help you identify the
                      best way to bring a strengths-based approach to your larger
                      group.
                    </p>
                  </div>
                </div>

                <button onClick={() => this.gotoPage("contact-us")} className="bg-[#2196F3] border border-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2.5 px-6 rounded uppercase md:-mt-10 whitespace-nowrap">
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </section> */}



        {/* ready section */}
        <section
          style={{
            backgroundImage: `url('/img/Home/book_a_demo_bg.webp')`,
          }}
          className=" bg-[#E3F2FD] xl:bg-contain bg-cover bg-no-repeat bg-center   "
        >
          <div className="px-4 py-20 mx-auto space-y-8 text-center md:w-4/6 md:px-0">
            <div className="space-y-4 ">
              <h1 className="xl:text-4xl text-3xl leading-[3rem] xl:leading-[4rem] font-semibold text-[#212121] text-center capitalize aos-init aos-animate">
                Ready to leap from feedback to action?
              </h1>
              <p className="text-xl font-semibold text-[#212121]">
                Invest in your people today. If not now, when?
              </p>
            </div>
            <button
              onClick={this.handleRedirect}
              data-aos="fade-up"
              // onClick={() => this.gotoPage("contact-us")}
              className="bg-[#212121] hover:bg-[#1976D2] hover:shadow-[0px_5px_1px_0px_rgba(0,0,0,10)] shadow-black transition-all duration-150  text-white py-2.5 px-6 rounded uppercase cursor-pointer flex justify-center items-center w-56 mx-auto"
            >
              Book a Demo
            </button>
          </div>
        </section>

        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />

        {/* {
          showNameModal ?
            <NameEntryModal
              closeModal={this.closeNameEntryModal}
              formData={formData}
              handleNameInput={this.handleNameInput}
              changeQuantity={this.changeQuantity}
              handleSubmit={this.submitOrder}
              details={userAssessmentUserProfileData}
              handleOptionInput={this.handleOptionInput}
            />
            : null
        } */}

        {showNameModal ? (
          <OptionType
            closeModal={this.closeNameEntryModal}
            formData={formData}
            handleOptionInput={this.handleOptionInput}
            handleOptionSubmit={this.handleOptionSubmit}
          />
        ) : null}
      </>
    );
  }
}

Package.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(Package);