import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions, questionsActions } from "../../../_actions";
import moment from "moment";
import * as XLSX from 'xlsx';
import AssessmentReport from "../../Surveylink/Component/AssessmentReport/AssessmentReport";
import AssessmentSummary from "../../Surveylink/Component/AssessmentSummary/AssessmentSummary";
import AssessmentManagerReport from "../../Surveylink/Component/AssessmentManagerReport/AssessmentManagerReport";
import AssessmentHiringReport from "../../Surveylink/Component/AssessmentHiringReport/AssessmentHiringReport";
import Ready from "../../../components/Ready";
import Footer from "../Home/Component/Footer";
import HeaderTransprent from "../Home/Component/HeaderTransprent";
import { FiUsers } from "react-icons/fi";
import { IoPeopleOutline } from "react-icons/io5";
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';


class MyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      activeTab: 3,
      drowdown: false,
      viewCode: true,
      modaleOpen: false,
      formNameData: {
        "id": "",
        "name": ""
      },
      crrPackId: "",
      showReportModal: false,
      showSummaryReport: false,
      surveyLanguageCode: '',
      themeType: 2,
      crrEmpDetails: {
        "emp_id": "",
        "name": ""
      },
      selectedValue: '1',
      formUserData: {
        "name": "",
        "email": "",
        "country": "",
        "postcode": "",
        "mobile": "",
        "organisation": ""
      },
      showManagerReportModal: false,
      showHiringReport: false,
      pageNo: 1,
      size: 10,
      offset: 0,

    };
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.ViewCode = this.ViewCode.bind(this);
    this.drowDown = this.drowDown.bind(this);
    this.ModalOpen = this.ModalOpen.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(assessmentActions.userAssessmentUserProfile());
    this.props.dispatch(assessmentActions.getAssessmentCartCount());
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
    this.props.dispatch(assessmentActions.getOrderBookList({
      "keyWord": '',
      "pageNo": 1,
      "size": this.state.size
    }));
    window.scrollTo(0, 0);

  }

  ModalOpen = () => {
    this.setState({ modaleOpen: !this.state.modaleOpen });
  };
  ViewCode = () => {
    this.setState({ viewCode: !this.state.viewCode });
  };

  drowDown = () => {
    this.setState({ drowdown: !this.state.drowdown });
  };

  handleTabClick = (tabIndex, temp) => {
    this.setState({ activeTab: tabIndex, }, () => {
      if (tabIndex === 5) {
        let data = {
          // "id": temp & temp.id ? temp.id : ""
          "id": temp.id
        }
        console.log("temp-1->", temp)
        this.setState({ crrPackId: temp.id })
        this.props.dispatch(assessmentActions.userAssessmentOrders(data));
        this.props.dispatch(assessmentActions.userAssessmentResponseAnalytics(data));
      }
      else if (tabIndex === 2) {
        let { assessment } = this.props;
        let { userAssessmentUserProfileData } = assessment;
        let data = {
          "name": userAssessmentUserProfileData && userAssessmentUserProfileData.name ? userAssessmentUserProfileData.name : "",
          "email": userAssessmentUserProfileData && userAssessmentUserProfileData.email ? userAssessmentUserProfileData.email : "",
          "country": userAssessmentUserProfileData && userAssessmentUserProfileData.country ? userAssessmentUserProfileData.country : "",
          "postcode": userAssessmentUserProfileData && userAssessmentUserProfileData.postcode ? userAssessmentUserProfileData.postcode : "",
          "mobile": userAssessmentUserProfileData && userAssessmentUserProfileData.mobile ? userAssessmentUserProfileData.mobile : "",
          "organisation": userAssessmentUserProfileData && userAssessmentUserProfileData.organisation ? userAssessmentUserProfileData.organisation : ""
        }
        this.setState({ formUserData: data })
      } else {
        this.setState({ crrPackId: "" })
      }

      if (tabIndex === 2) {
        this.props.history.push(`/assessment/` + "profile-summary");
      } else if (tabIndex === 2) {
        this.props.history.push(`/assessment/` + "profile-summary");
      } else if (tabIndex === 3) {
        this.props.history.push(`/assessment/` + "my-orders");
      } else if (tabIndex === 4) {
        this.props.history.push(`/assessment/` + "assessment-code");
      } else if (tabIndex === 6) {
        this.props.history.push(`/assessment/` + "user");
      } else if (tabIndex === 7) {
        this.props.history.push(`/assessment/` + "myteam");
      }


    });
  };

  // TabClick = (tabIndex) => {
  //   this.setState({ activeTab: tabIndex });
  // };


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.userAssessmentMyCartSuccess) {
      return {
        ...nextProps,
        showLogin: false,
      };
    }
    if (nextProps.user.getEmployeeStatusSucess) {
      return {
        ...nextProps,
        notFoundText: "Success!",
      };
    }
    if (nextProps.user.getEmployeeStatusFailed) {
      return {
        ...nextProps,
        notFoundText: "Employee not found!",
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  handleSignInPage = () => {
    localStorage.removeItem('route')
    localStorage.setItem('route', this.props.history.location.pathname)
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoPage = (field) => {
    this.props.history.push(`/assessment/` + field);
  };

  onEditSingleName = (element) => {
    let { formNameData } = this.state;
    formNameData = {
      "id": element.id,
      "name": element.name
    };
    this.setState({ showNameEdit: true, formNameData })
  }

  onSingleNameInput = (value, element) => {
    let { formNameData } = this.state;
    formNameData = {
      "id": element.id,
      "name": value
    };
    this.setState({ formNameData })
  }

  onSingleNameSave = () => {
    let { formNameData, crrPackId } = this.state;
    let data = {
      "id": formNameData.id,
      "name": formNameData.name,
    }
    let temp = {
      "id": crrPackId
    }
    this.props.dispatch(assessmentActions.userAssessmentOrderNameUpdate(data, temp))
    formNameData = {
      "id": "",
      "name": ""
    };
    this.setState({ showNameEdit: false })
  }

  viewReport = (ele) => {
    console.log("ele 1-->", ele);

    if (ele && ele.productId && ele.productId.code) {
      let code = ele.productId.code;

      if (code === "Top5TalentplusStrengths") {
        console.log("Top5TalentplusStrengths 1-->");
        this.handleSummaryReport(ele);
      } else if (code === "TalentPlusStrengths21") {
        console.log("TalentPlusStrengths21 1-->");
        this.handleReport(ele);
      } else if (code === "TalentplusManagerStrengths") {
        console.log("TalentplusManagerStrengths 1-->");
        this.handleManagerReport(ele);
      } else if (code === "TalentPlusHiringStrengths") {
        console.log("TalentPlusHiringStrengths 1-->");
        this.handleHiringReport(ele);
      }
    }


    let temp = {
      "emp_id": ele.emp_id,
      "name": ele.name
    }
    this.setState({ crrEmpDetails: temp })
  }



  handleReport = (ele) => {
    let data = {
      employeeId: ele.employeeId,
      isEmp: true
    }
    this.props.dispatch(questionsActions.getAssessmentReport(data));
    this.setState({ showReportModal: true });
  };

  handleSummaryReport = (ele) => {
    let data = {
      employeeId: ele.employeeId,
      isEmp: true
    }
    this.props.dispatch(questionsActions.getAssessmentReport(data));
    this.setState({ showSummaryReport: true });
  }

  handleManagerReport = (ele) => {
    let data = {
      employeeId: ele.employeeId,
      isEmp: true
    }
    this.props.dispatch(questionsActions.getAssessmentReport(data));
    this.setState({ showManagerReportModal: true });
  }

  handleHiringReport = (ele) => {
    let data = {
      employeeId: ele.employeeId,
      isEmp: true
    }
    this.props.dispatch(questionsActions.getAssessmentReport(data));
    this.setState({ showHiringReportModal: true });
  }

  closeReportModal = () => {
    this.setState({ showReportModal: false });
  };

  closeSummaryReportModal = () => {
    this.setState({ showSummaryReport: false });
  };

  closeManagerReportModal = () => {
    this.setState({ showManagerReportModal: false });
  };

  closeHiringReportModal = () => {
    this.setState({ showHiringReportModal: false });
  };

  handleLanguage = (item, langList) => {
    console.log("item------------------->", item)
    if (item.shortName === 'en') {
      this.setState({ surveyLanguageCode: item.shortName, surveyLanguageName: 'English', surveyLanguageRTL: false });
    }
    else {
      this.setState({ surveyLanguageCode: item.shortName, surveyLanguageName: item.name, surveyLanguageRTL: item.isRTL });
    }
  }

  handleDropdownChange(event) {
    const activeTab = event.target.value;

    if (Number(activeTab) === 2) {
      this.props.history.push(`/assessment/` + "profile-summary");
    } else if (Number(activeTab) === 2) {
      this.props.history.push(`/assessment/` + "profile-summary");
    } else if (Number(activeTab) === 3) {
      this.props.history.push(`/assessment/` + "my-orders");
    } else if (Number(activeTab) === 4) {
      this.props.history.push(`/assessment/` + "assessment-code");
    } else if (Number(activeTab) === 5) {
      this.props.history.push(`/assessment/` + "user");
    }

    this.setState({
      activeTab: Number(activeTab)
    });
  }

  handleDownloadSample = () => {
    let binary_univers = [
      {
        "name": "john"
      }
    ];

    //console.log('binary_univers: ', binary_univers)

    let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

    // Create a new Workbook
    var wb = XLSX.utils.book_new()

    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')

    // export your excel
    XLSX.writeFile(wb, 'namefile.xlsx');
  }


  updateUserDetails = () => {
    let { formUserData } = this.state;
    let data = {
      "name": formUserData && formUserData.name ? formUserData.name : "",
      "email": formUserData && formUserData.email ? formUserData.email : "",
      "country": formUserData && formUserData.country ? formUserData.country : "",
      "postcode": formUserData && formUserData.postcode ? formUserData.postcode : "",
      "mobile": formUserData && formUserData.mobile ? formUserData.mobile : "",
      "organisation": formUserData && formUserData.organisation ? formUserData.organisation : ""
    }
    this.props.dispatch(assessmentActions.updateAssessmentUserProfile(data));
  }


  handleFormData = (value, name) => {
    let { formUserData } = this.state;
    formUserData[name] = value;
    this.setState({ formUserData })
  }

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem('route')
      localStorage.setItem('route', `/assessment/` + field)
      this.props.history.push(`/assessment/sign-in`);
    }
  }


  handleClick = () => {
    const { history } = this.props;
    history.push('/assessment/expert'); // Replace '/expert-page' with the actual route of your expert page
  }

  handleOrderHistoryPageClick = (data, element) => {
    const offset = data.selected * this.state.size;
    const pageNo = data.selected + 1;
    let { crrPackId } = this.state;
    this.setState({ offset: offset, page: data.selected }, () => {
      const datatemp = {
        id: crrPackId,
        keyWord: this.state.keyWord,
        pageNo: pageNo,
        size: this.state.size,
      };
      this.props.dispatch(assessmentActions.getOrderBookList(datatemp));
    });
  }

  render() {
    let { assessment, questions } = this.props;
    let {
      getAssessmentStatusData,
      getAssessmentReportByEmpIDData,
      getAssessmenrEmployeeDetailsData,
      getTranslateElementAllData,
      // getAssessmentCartCountData,


    } = questions;

    let { showManagerReportModal, showHiringReportModal, formUserData, crrEmpDetails, showSummaryReport, showReportModal, surveyLanguageName, surveyLanguageCode, showNameEdit, formNameData } = this.state;
    let { userAssessmentUserProfileData, getOrderBookListData, getOrderBookListDataTotal, userAssessmentOrdersData, userAssessmentResponseAnalyticsData, getAssessmentPackagesShortListData, getAssessmentCartCountData
      , loading

    } = assessment;

    // console.log('getAssessmentPackagesShortListDatagetAssessmentPackagesShortListDatagetAssessmentPackagesShortListData', getAssessmentPackagesShortListData);
    // console.log("formUserData-->", formUserData);

    let check =
      getAssessmentStatusData && getAssessmentStatusData.check
        ? getAssessmentStatusData.check
        : 0;

    let createdAt =
      getAssessmentStatusData && getAssessmentStatusData.createdAt
        ? moment(new Date(parseInt(getAssessmentStatusData.createdAt))).utcOffset("+05:30").format("DD MMMM, YYYY")
        : "";

    const { activeTab } = this.state;

    function getDetails(orderBook, field, type, orderPack) {
      if (type === 2) {
        return field &&
          orderPack &&
          orderPack["productId"] &&
          orderPack["productId"][field]
          ? orderPack["productId"][field]
          : "";
      } else if (type === 3) {
        console.log("orderPack---->", orderPack)
        return field && orderPack && orderPack[field] ? orderPack[field] : "";
      } else {
        return field && orderBook && orderBook[field] ? orderBook[field] : "";
      }
    }

    function getValueRes(field) {
      return field && userAssessmentResponseAnalyticsData && userAssessmentResponseAnalyticsData[0] && userAssessmentResponseAnalyticsData[0][field] ? userAssessmentResponseAnalyticsData[0][field] : 0
    }

    let ReportDataList = [
      {
        "name": "Total",
        "value": getValueRes("total"),
        "color": '#99cc00'
      },
      {
        "name": "Clicked",
        "value": getValueRes("clicked"),
        "color": '#bf00ff'
      },
      {
        "name": "Completed",
        "value": getValueRes("completed"),
        "color": '#008080'
      },
      {
        "name": "Not Completed",
        "value": Number(getValueRes("total") - getValueRes("completed")),
        "color": '#ff9933'
      },


    ]

    const GetTextByLanguage = (name, text) => {
      if (surveyLanguageCode && surveyLanguageCode !== "en") {
        let sentence = "";
        if (getTranslateElementAllData && getTranslateElementAllData.length > 0) {
          let getIndex = getTranslateElementAllData.findIndex(prev => prev.name === name);
          if (getIndex !== -1) {
            sentence = getTranslateElementAllData && getTranslateElementAllData[getIndex] && getTranslateElementAllData[getIndex]["transl"] && getTranslateElementAllData[getIndex]["transl"][surveyLanguageCode] ? getTranslateElementAllData[getIndex]["transl"][surveyLanguageCode] : ""
          }
        }
        // console.log("sentence------------>", sentence);
        // console.log("surveyLanguageCode------------>", surveyLanguageCode);
        // console.log("getTranslateElementAllData------------>", getTranslateElementAllData);

        return sentence ? sentence : text
      } else {
        return text
      }
    }

    const GetDetailsByLanguage = (element, name, text) => {
      if (surveyLanguageCode && surveyLanguageCode !== "en") {
        let sentence = element && element["details"] && element["details"]["transl"] && element["details"]["transl"][surveyLanguageCode] && element["details"]["transl"][surveyLanguageCode][name] ?
          element["details"]["transl"][surveyLanguageCode][name] : ""
        return sentence ? sentence : text
      } else {
        return text
      }
    }

    function EditButtonFn(name, text) {
      return (
        <>
          {GetTextByLanguage(name, text)}
        </>
      )
    }

    function EditDetailsFn(element, name, text) {
      return (
        <>
          {GetDetailsByLanguage(element, name, text)}
        </>
      )
    }

    function hexToRgbA(hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
      throw hex;
    }

    function getUserDetails(field) {
      return (field && userAssessmentUserProfileData && userAssessmentUserProfileData[field] ? userAssessmentUserProfileData[field] : "NA")
    }


    return (

      <LoadingOverlay
        active={loading}
        // active={false}

        spinner
        text='Loading Data...'
        className="loader"
      >

        <div className="">
          <HeaderTransprent
            handleSignInPage={this.handleSignInPage}
            userDetails={userAssessmentUserProfileData}
            logout={this.logout}
            gotoPage={this.gotoPage}
            props2={this.props}
            cartCount={getAssessmentCartCountData ? getAssessmentCartCountData : 0}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />

          <section className="flex flex-col w-full gap-10 px-4 mx-auto mb-10 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:flex-row ">
            <div className="w-full py-5 space-y-4 md:py-10">
              <div className="flex items-center pb-6 space-x-4 border-b">
                <h1 className="md:text-3xl text-2xl font-medium text-[#3D405B]">
                  My Account
                </h1>{" "}
              </div>

              <div className="flex flex-col items-start w-full gap-10 py-6 xl:flex-row ">

                <div className="hidden w-full capitalize md:block lg:w-64">

                  <div
                    onClick={() => this.handleTabClick(2)}
                    className={
                      this.state.activeTab == 2
                        ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                        : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                    }
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                    > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <polyline points="5 12 3 12 12 3 21 12 19 12" /> <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /> <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                    </svg>
                    <span>Profile Summary</span>
                  </div>

                  <div
                    onClick={() => this.handleTabClick(3)}
                    className={
                      this.state.activeTab == 3
                        ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                        : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                    }
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-shopping-cart" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                    > <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path> <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path> <path d="M17 17h-11v-14h-2"></path> <path d="M6 5l14 1l-1 7h-13"></path>
                    </svg>
                    <span>my orders</span>
                  </div>

                  <div
                    onClick={() => this.handleTabClick(4)}
                    className={
                      ((this.state.activeTab == 4) || (this.state.activeTab == 5))
                        ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                        : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                    }
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clipboard-text" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                    > <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path> <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path> <path d="M9 12h6"></path> <path d="M9 16h6"></path>
                    </svg>
                    <span>Assessment Code</span>
                  </div>

                  <div
                    onClick={() => this.handleTabClick(7)}
                    className={
                      this.state.activeTab == 7
                        ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                        : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                    }
                  >
                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clipboard-text" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  {" "}  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>{" "}  <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>{" "}  <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path>{" "}  <path d="M9 12h6"></path> <path d="M9 16h6"></path></svg> */}
                    <IoPeopleOutline className="h-6 w-6" />
                    <span>My Team</span>
                  </div>

                  {/* <div
                  onClick={() => this.handleTabClick(6)}
                  className={
                    this.state.activeTab == 6
                      ? "bg-[#2196F3]/10 text-[#2196F3] flex space-x-4 p-4 rounded cursor-pointer"
                      : "bg-[#fff]/10 text-[#3D405B] flex space-x-4 p-4 rounded cursor-pointer"
                  }
                >
                  <FiUsers className="w-6 h-6" />
                  <span>User</span>
                </div> */}

                </div>

                <div className="block w-full md:hidden">
                  <label for="countries" className="block mb-2 text-sm font-medium text-[#3D405B]">Select an option</label>
                  <select id="dropdown" onChange={this.handleDropdownChange} value={activeTab} className="bg-white border border-gray-300 text-[#3D405B] text-sm rounded-lg focus:ring-[#2196F3] focus:border-[#2196F3] block w-full p-2.5">
                    <option value="2">Profile Summary</option>
                    {/* <option value="2">Edit Profile</option> */}
                    <option value="3">My Orders</option>
                    <option value="4">Assessment Code</option>
                    <option value="6">User</option>
                  </select>
                </div>

                {this.state.activeTab === 3 ? (
                  <div className="w-full flex-1 space-y-6 text-[#3D405B]">
                    <h1 className="md:text-2xl text-xl  text-[#3D405B]">
                      My Orders
                    </h1>
                    <div className="grid grid-cols-2 gap-4 text-[#3D405B] ">
                      {getOrderBookListData && getOrderBookListData.length > 0
                        ? getOrderBookListData.map((orderBook) => (
                          <>
                            <div className="col-span-2 p-6 space-y-2 border rounded-md">
                              <div className="flex md:flex-row flex-col justify-between gap-4 md:items-center -m-6 -mb-0 p-6 py-4 bg-[#F9FAFB] border-b">
                                <div className="space-y-0 text-[#3D405B] ">
                                  <h1 className="text-base font-semibold md:text-lg">
                                    Order No: #
                                    {getDetails(orderBook, "orderNumber")}
                                  </h1>
                                  <p className="text-[#3D405B]/70 md:text-sm text-xs">
                                    Order Placed On: {""}
                                    {moment(
                                      new Date(
                                        parseInt(
                                          getDetails(orderBook, "createdAt")
                                        )
                                      )
                                    )
                                      .utcOffset("+05:30")
                                      .format("ddd, Do MMM YYYY, hh:mm A ")}
                                  </p>
                                </div>
                                <p className="lg:text-lg text-base">
                                  {getDetails(orderBook, "orderPacks") &&
                                    getDetails(orderBook, "orderPacks").length > 0
                                    ? getDetails(orderBook, "orderPacks").length
                                    : 0}{" "}
                                  items
                                </p>
                              </div>

                              <div className="divide-y ">
                                {getDetails(orderBook, "orderPacks") &&
                                  getDetails(orderBook, "orderPacks").length > 0
                                  ? getDetails(orderBook, "orderPacks").map(
                                    (orderPack) => (
                                      <div className="">
                                        <div className="py-4 space-y-4 ">
                                          <div className="flex flex-row  md:gap-6 gap-4 md:items-center items-start   ">
                                            <div className=" cursor-pointer flex items-center md:w-32 w-20 md:h-32 h-20 ">
                                              <img
                                                src="/img/assessment/Rectangle.png"
                                                className="md:w-32 w-20 md:h-32 h-20 overflow-cover"
                                              />{" "}
                                            </div>

                                            <div className="w-full  space-y-2 flex-1 ">
                                              <div className="text-[#3D405B] flex  2xl:flex-row flex-col md:gap-4 gap-2 justify-between w-full ">
                                                <h1 className="  md:text-xl text-lg ">
                                                  {getDetails(
                                                    {},
                                                    "name",
                                                    2,
                                                    orderPack
                                                  )}
                                                </h1>
                                                <h1 className="  md:text-xl text-lg  font-semibold ">
                                                  <span className="font-[roboto]"> ₹</span>{getDetails(
                                                    {},
                                                    "total",
                                                    3,
                                                    orderPack
                                                  )}

                                                </h1>
                                              </div>
                                              <div className="  space-y-2 items-center">
                                                <p className="text-[#3D405B]/70 capitalize">
                                                  {getDetails(
                                                    {},
                                                    "licensee",
                                                    3,
                                                    orderPack
                                                  )} Plan
                                                </p>
                                                <p className="font-medium ">
                                                  Qty:{" "}
                                                  {getDetails(
                                                    {},
                                                    "quantity",
                                                    3,
                                                    orderPack
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          {/* <button onClick={() => this.handleTabClick(5, orderPack)} className="bg-white border md:flex hidden space-x-2 items-center p-4 py-2.5 uppercase cursor-pointer font-medium rounded">
                                          <span> View Assessment Code</span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="rotate-180 icon icon-tabler icon-tabler-chevron-left"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            ></path>
                                            <polyline points="15 6 9 12 15 18"></polyline>
                                          </svg>
                                        </button> */}
                                        </div>
                                      </div>
                                    )
                                  )
                                  : null}
                              </div>

                              <div className="flex items-start justify-between p-6 pb-0 -m-6 border-t">
                                <div className="space-y-2">
                                  <h1 className="lg:text-lg text-base font-semibold">Total Paid</h1>
                                  {/* <button
                                  onClick={() => this.drowDown()}
                                  className="text-[#2196F3] flex items-center space-x-2 font-medium cursor-pointer"
                                >
                                  <span>View Breakup</span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-chevron-down"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M6 9l6 6l6 -6"></path>
                                  </svg>
                                </button> */}
                                </div>
                                <h1 className="md:text-xl text-lg font-semibold ">
                                  <span className="font-[roboto]">  ₹</span>{getDetails(orderBook, "total")}
                                </h1>
                              </div>

                              {this.state.drowdown ? (
                                <div className="py-4 pb-0 space-y-4 text-base border-t md:text-lg">
                                  {/* <div className="flex items-center justify-between capitalize">
                          <p className="">1 x Top 5 TalentPlusStrengths</p>
                          <p className="font-medium ">${getDetails(orderBook,"total")}</p>
                        </div>
                        <div className="flex items-center justify-between capitalize">
                          <p className="">4 x TalentPlusStrengths 28</p>
                          <p className="font-medium ">$59.99</p>
                        </div>
                        <div className="flex items-center justify-between capitalize">
                          <p className="">Esitamted</p>
                          <p className="font-medium ">$59.99</p>
                        </div> */}
                                  {/* <div className="flex items-center justify-between capitalize">
                                  <p className="">Tax</p>
                                  <p className="font-medium ">
                                  <span className="font-[roboto]">  ₹</span>{getDetails(orderBook, "tax")}
                                  </p>
                                </div> */}
                                  <div className="flex items-center justify-between pb-1 capitalize ">
                                    <p className="text-xl font-semibold ">
                                      Total Paid
                                    </p>
                                    <p className="text-lg font-semibold md:text-2xl">
                                      <span className="font-[roboto]"> ₹ </span> {getDetails(orderBook, "total")}
                                    </p>
                                  </div>
                                  <div className="flex items-center p-4 py-2 space-x-3 bg-gray-100">
                                    <img src="/img/assessment/UPI logo.png" />
                                    <span>Paid by UPI</span>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </>
                        ))
                        :
                        <section className="flex items-center justify-center w-full col-span-2 gap-10 px-4 mx-auto overflow-hidden">
                          <div className="flex flex-col items-center space-y-4 justify-center md:py-10 py-8 text-center">
                            <img src="/img/newicons/empty_cart2.svg" alt="emptycart.svg" className="w-3/6 2xl:w-2/4" />
                            <h2 className="capitalize md:text-3xl text-2xl font-semibold text-[#3D405B] pt-4">No Order Yet!</h2>
                            <p className="text-gray-500 text-lg">Looks like you haven’t made your choice yet.</p>
                            <div className="mt-0 md:mt-8">
                              <button onClick={() => this.props.history.push(`/assessment/` + "products")} className="bg-[#2196F3] border border-[#2196F3] text-white py-2 px-10 rounded uppercase mt-10"> shop Now </button>
                            </div>
                          </div>
                        </section>
                      }


                      {/* <div className="flex md:flex-row flex-col gap-6 justify-center items-center  text-[#3D405B] text-sm font-medium md:p-6   col-span-2">
                      <p className="">Pages</p>
                      <div className="flex border divide-x rounded-md ">
                        <button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                          Previous
                        </button>
                        <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3]">
                          1
                        </button>
                        <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                          2
                        </button>
                        <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                          3
                        </button>
                        <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                          4
                        </button>
                        <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                          5
                        </button>
                        <button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                          Next
                        </button>
                      </div>
                      <p>1 of 26</p>
                    </div> */}
                    </div>
                    <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        getOrderBookListData && getOrderBookListDataTotal > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={Math.ceil(getOrderBookListDataTotal / this.state.size)}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={this.handleOrderHistoryPageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                            forcePage={this.state.page} // Synchronize selected page
                          />
                          : null}
                    </nav>

                  </div>
                ) : null}

              </div>
            </div>
          </section>

          <Ready />

          <Footer
            props2={this.props}
            prodList={getAssessmentPackagesShortListData}
            gotoMyAccount={this.gotoMyAccount}
          />

          {
            this.state.modaleOpen ? (
              <div className="fixed top-0 right-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-40 xl:h-screen">
                <div className="w-11/12 mx-auto bg-white rounded-lg shadow-sm xl:w-6/12 lg:w-9/12 ">
                  <div className="flex items-center justify-between p-4 border-b md:px-8 ">
                    <h2 className="text-lg font-semibold md:text-xl test-gray-800 ">
                      Upload Team
                    </h2>
                    <div
                      onClick={() => this.ModalOpen()}
                      className="p-2 rounded-full cursor-pointer bg-slate-100 hover:bg-slate-200 "
                    >
                      <svg
                        className="w-6 h-6 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                      </svg>
                    </div>
                  </div>
                  <div className="m-4 space-y-8 md:m-8 ">
                    <div className="p-4 space-y-2 text-sm border divide-y rounded-md text-[#3D405B]">
                      <h1 className="text-lg font-semibold text-[#3D405B] ">
                        {" "}
                        Guidelines for uploading the data
                      </h1>
                      <ol className="list-disc pl-6 space-y-1.5 py-4 text-[#3D405B] ">
                        <li className>
                          {" "}
                          Mandatory Column include emp_id and email as first column
                          &amp; second column should be in the file.
                        </li>
                        <li className="capitalize">
                          {" "}
                          Your file columns/headers should not contain any special
                          characters – this includes characters that are not numbers
                          or the English alphabet.
                        </li>
                        <li className="capitalize">
                          {" "}
                          Be careful not to include duplicates of the same row – no
                          two employees should have the same emp_id or email.
                        </li>
                        <li className="capitalize">
                          {" "}
                          Date format must be same as DD-MM-YYYY
                        </li>
                        <li className="capitalize">
                          {" "}
                          See the sample data format for reference and prepare your
                          master data accordingly without any change in mandatory
                          column names
                        </li>
                        <li className="capitalize">
                          {" "}
                          Make sure your Excel sheet is not password protected.
                        </li>
                      </ol>
                    </div>
                    <div className="space-y-4">
                      <div className="grid grid-cols-1 gap-4 xl:grid-cols-4 md:grid-cols-2">
                        <div className="flex items-start p-2 px-4 space-x-4 rounded shadow-sm cursor-pointer 2xl:items-center bg-sky-100 md:col-span-2">
                          <span className="p-2 text-blue-500 bg-white rounded-full shadow-sm material-symbols-outlined">
                            download
                          </span>
                          <div onClick={() => this.handleDownloadSample()}>
                            <h1 className="text-sm font-medium md:text-base text-[#3D405B] ">
                              Download sample date
                            </h1>
                            <p className="text-sm text-gray-500">
                              See the sample data format for reference
                            </p>
                          </div>
                        </div>
                        <input type="file" id="file" accept=".xlsx,.csv" hidden />
                        <label
                          htmlFor="file"
                          className="rounded shadow-sm cursor-pointer bg-sky-100 md:col-span-2"
                        >
                          <div className="flex items-start p-2 px-4 space-x-4 2xl:items-center ">
                            <span className="p-2 text-blue-500 bg-white rounded-full shadow-sm material-symbols-outlined">
                              upload
                            </span>
                            <div>
                              <h1 className="text-sm font-medium md:text-base text-[#3D405B] ">
                                Upload Master Data
                              </h1>
                              <p className="text-sm text-gray-500">
                                Upload master data as per the guidelines
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="hidden">
                        <button className=" border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none">
                          Sample CSV
                        </button>
                        <button className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}


          {showReportModal ? (
            <AssessmentReport
              closeReportModal={this.closeReportModal}
              getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
              getAssessmenrEmployeeDetailsData={crrEmpDetails}
              createdAt={createdAt}

              EditButtonFn={EditButtonFn}
              EditDetailsFn={EditDetailsFn}
              loading={loading}
            />
          ) : null}


          {showSummaryReport ? (
            <AssessmentSummary
              closeReportModal={this.closeSummaryReportModal}
              getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
              getAssessmenrEmployeeDetailsData={crrEmpDetails}
              createdAt={createdAt}

              EditButtonFn={EditButtonFn}
              EditDetailsFn={EditDetailsFn}
              loading={loading}
            />
          ) : null}



          {showManagerReportModal ? (
            <AssessmentManagerReport
              closeReportModal={this.closeManagerReportModal}
              getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
              getAssessmenrEmployeeDetailsData={crrEmpDetails}
              createdAt={createdAt}

              EditButtonFn={EditButtonFn}
              EditDetailsFn={EditDetailsFn}
              loading={loading}
            />
          ) : null}


          {showHiringReportModal ? (
            <AssessmentHiringReport
              closeReportModal={this.closeHiringReportModal}
              getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
              getAssessmenrEmployeeDetailsData={crrEmpDetails}
              createdAt={createdAt}

              EditButtonFn={EditButtonFn}
              EditDetailsFn={EditDetailsFn}
              loading={loading}
            />
          ) : null}





        </div>


      </LoadingOverlay>
    );
  }
}
MyOrders.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(MyOrders);
