import React from "react";
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';

export default function TeamTop5Themes(props) {
  let {
    getAssessmentDimensionsData,
    getAllAnsweredAssessmentEmpDataList,

    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    EditDetailsFn,
    EditButtonFn,
    hindiPDFstatic,
    teamName,
    createdAt,

    type

  } = props;


  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }



  let AssessmentList = []
  if (getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0) {
    getAssessmentDimensionsData.forEach((element) => {
      AssessmentList.push({
        ...element,
        finalSum: (Number(element.count) * Number(element.rankSum))
      })
    })
    AssessmentList.sort((b, a) => (a.finalSum - b.finalSum))
  }

  console.log("AssessmentList--1==============->", AssessmentList)

  function checkForGeneralship(bucket, dimension, isSchool) {
    let text = "";
    if (bucket && dimension) {
      if (isSchool) {
        text = "school_";
      } else if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
        text = "old";
      }
    }
    return text
  }


  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
        console.log("range1------------->", range1)
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }


  let dataList = [];

  let x = getAllAnsweredAssessmentEmpDataList;



  function getNameScore(para, listX, typeX) {
    console.log("list-ssss-->", listX)
    console.log("ele--ssss->", para)

    if (listX && listX.length > 0) {
      listX.sort((a, b) => a.rank - b.rank);
    }

    let dimension = para && para.dimension ? para.dimension : "";
    let finalCount = 0;

    if (listX && listX.length > 0) {
      listX.forEach((ele, index1) => {


        let listY = [];
        if (Object.keys(ele) && Object.keys(ele).length > 0) {
          //console.log("el2e-------->", Object.keys(ele))
          let arrayEle = Object.keys(ele);
          console.log("arrayEle-------->", arrayEle)

          const index = arrayEle.indexOf("emp_id");
          if (index > -1) {
            arrayEle.splice(index, 1);
          }

          const index2 = arrayEle.indexOf("name");
          if (index2 > -1) {
            arrayEle.splice(index2, 1);
          }

          for (var i = 0; i < arrayEle.length; i++) {
            let rankNumber = parseInt(arrayEle[i].replace('Rank', ''));
            let dimension = ele["Rank" + rankNumber.toString()]

            if (rankNumber) {
              listY.push({
                dimension: dimension,
                rank: rankNumber
              });
            }
          }


        }

        if (listY && listY.length > 0) {
          listY.sort((a, b) => a.rank - b.rank);
        }

        console.log("listY------------------------------------------------------------->", listY)


        if (listY && listY.length > 0) {
          listY.forEach((innerPara, indexX) => {
            if (typeX === "Top") {
              if (indexX + 1 <= getTopBottomIndex(listY, "top").range1) {
                if (innerPara.dimension.toString() === dimension.toString()) {
                  finalCount++;
                }
              }
            }
            if (typeX === "Bottom") {
              if (getTopBottomIndex(listY, "bottom").range1 <= indexX + 1) {
                if (innerPara.dimension.toString() === dimension.toString()) {
                  finalCount++;
                }
              }
            }
          })}
  


      })
    }

    return finalCount
  }


  if (type === "Top") {
    if (AssessmentList && AssessmentList.length > 0) {
      for (var i = 0; i < AssessmentList.length; i++) {
        let element = AssessmentList[i];
        if ((i + 1 <= getTopBottomIndex(AssessmentList, "top").range1)) {
          dataList.push({
            value: (i + 1) * 20,
            // name: (i + 1).toString(),
            name: getNameScore(element, x, type),
            itemStyle: { color: element && element.colorCode ? element.colorCode : "#757575" }
          })
        }
      }
    }
  }

  if (type === "Bottom") {
    if (AssessmentList && AssessmentList.length > 0) {
      for (var i = 0; i < AssessmentList.length; i++) {
        let element = AssessmentList[i];
        if (getTopBottomIndex(AssessmentList, "bottom").range1 <= i + 1) {
          dataList.push({
            value: ((i - getTopBottomIndex(AssessmentList, "bottom").range1) + 2) * 20,
            // name: ((i - getTopBottomIndex(AssessmentList, "bottom").range1) + 2).toString(),
            name: getNameScore(element, x, type),
            itemStyle: { color: element && element.colorCode ? element.colorCode : "#757575" }
          })
        }
      }
    }
  }



  return (
    <>

      <div className='flex flex-row '>
        <div className='w-full'>
          <div >
            <ReactEcharts
              style={{ height: '450px', width: '500px' }}
              option={{
                series: [
                  {
                    name: 'Funnel',
                    type: 'funnel',
                    left: '10%',
                    top: 10,
                    bottom: 0,
                    width: '80%',
                    min: 0,
                    max: 100,
                    minSize: '0%',
                    maxSize: '100%',
                    sort: 'ascending',
                    gap: 2,
                    label: {
                      show: true,
                      fontSize: 20,
                      position: 'inside',
                      color: '#fff',
                    },
                    labelLine: {
                      length: 10,
                      lineStyle: {
                        width: 1,
                        type: 'solid'
                      }
                    },
                    itemStyle: {
                      borderColor: '#fff',
                      borderWidth: 1,

                    },
                    emphasis: {
                      label: {
                        show: false,
                        fontSize: 20,
                        color: '#fff'
                      }
                    },
                    data: dataList
                  }
                ]
              }}
            />
          </div>
          <p className="pl-36 pt-4 text-[#212121]/70 font-bold">*Number represent frequency of theme</p>
        </div>

        {type === "Top" ?
          <div className='w-full space-y-4 py-3'>
            {AssessmentList && AssessmentList.length > 0 ?
              AssessmentList.map((element, index) =>
                (index + 1 <= getTopBottomIndex(AssessmentList, "top").range1) ?
                  <div className='space-y-1 border-l-4 pl-4' style={{ borderColor: element && element.colorCode ? element.colorCode : "#757575" }}>
                    <b className='text-lg'>{(index + 1).toString()}. {EditDetailsFn(element, "dimension", element.dimension)}</b>
                    <p>{EditDetailsFn(element, "desc", element.details.desc)}</p>
                  </div>
                  : null) : null}
          </div>
          : null}

        {type === "Bottom" ?
          <div className='w-full space-y-4 py-3'>
            {AssessmentList && AssessmentList.length > 0 ?
              AssessmentList.map((element, index) =>
                (getTopBottomIndex(AssessmentList, "bottom").range1 <= index + 1) ?
                  <div className='space-y-1 border-l-4 pl-4' style={{ borderColor: element && element.colorCode ? element.colorCode : "#757575" }}>
                    <b className='text-lg'>{((index - getTopBottomIndex(AssessmentList, "bottom").range1) + 2).toString()}. {EditDetailsFn(element, "dimension", element.dimension)}</b>
                    <p>{EditDetailsFn(element, "desc", element.details.desc)}</p>
                  </div>
                  : null) : null}
          </div>
          : null}


      </div>


    </>
  );
}
