import * as React from 'react';

const PageTemplate = props => {

  let Number1 = props && props.pageNum ? props.pageNum : 0;
  let SurveyLogo = props && props.surveyLogo ? props.surveyLogo : "/img/logo/happypluslogo.png";

  let pageNo = 0
  if (Number1 > 2) {
    pageNo = Number1 - 2
  }
  return (<>
    <div className='absolute flex items-center justify-between bottom-10 left-4' style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className='pt-4 mr-36'>
        <img src={"/img/logo/TalentPlusStrengths_logo.svg"} className="object-cover w-32 mx-4" alt="default_survey_logo" />
      </div>

      <div className='pt-2'>
        {
          pageNo > 0 ?
            <p className='text-center text-[#212121]/85 font-bold pb-1'>{pageNo}</p> : null
        }
        <p className='text-sm text-[#212121]/70'>Copyright 2025, HappyPlus, Inc. All rights reserved</p>
      </div>

      <div className='ml-36'>
        <img src={"/img/logo/happyplus-logo-with-powered-by.svg"} className="object-cover w-32 mx-4 " alt="default_survey_logo" />
      </div>
    </div>


  </>);
};

export default PageTemplate;