import React from 'react'
import moment from "moment";

export default function AssessmentTop5(props) {
  let { crrSelectedEmp, EditButtonFn, hindiPDFstatic, teamName,createdAt } = props;


  return (
    <>
      <main ref={hindiPDFstatic[5]} className="p-10 px-6 space-y-6 bg-white page-break">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-semibold">
              {EditButtonFn(
                "TalentAssessmentReport",
                "Team TalentPlusStrengths Assessment Report"
              )}
            </h1>
            <p className="text-lg font-semibold">
            {crrSelectedEmp && crrSelectedEmp.name
                  ? (crrSelectedEmp.name === "Team Overall") ? teamName : crrSelectedEmp.name
                  : teamName}{" "}
                {createdAt && crrSelectedEmp.name !== "Team Overall"? " | " + createdAt : ""}
            </p>
          </div>
          {/* <div className="py-4 space-y-2">
            <h1 className="text-3xl font-medium text-[#3D405B]">{EditButtonFn("Part2", "Part 2")}</h1>
          </div> */}
          <div className="w-full space-y-1">
            <div className="w-full pb-4">
              <h1 className="text-3xl font-light text-[#212121]">
                <b>{EditButtonFn("DeepDive", "Deep Dive into your TalentPlusStrengths Themes:")}</b>
              </h1>
            </div>


            <div className="flex p-4 bg-[#F5F5F5]">
              <p>1.</p>
              <p className="mx-2">{EditButtonFn("top5list1", "In this section each theme will be explained in detail. Typical behaviour observed in successful people with this theme has been mentioned. ")}</p>
            </div>

            <div className="flex p-4 bg-[#E2E2E2]">
              <p>2.</p>
              <p className="mx-2">{EditButtonFn("top5list2", "In the top heading you will see the talent with chronological number and specific colour. This colour signifies whether the theme is from Leading, Acting, Relating or Thinking bucket.")}</p>
            </div>

            <div className="flex p-4 bg-[#F5F5F5]">
              <p>3.</p>
              <p className="mx-2">{EditButtonFn("top5list3", "Explanation of themes will give you a clear picture of the talent and how it manifests itself. You may want to add more to it, if you think something is missing. Broadly it will cover many dimensions but still you may want to explore further basis your own observations.")}</p>
            </div>
            <div className="flex p-4 bg-[#E2E2E2]">
              <p>4.</p>
              <p className="mx-2">{EditButtonFn("top5list4", "Knowing your talent is one thing, but putting it into use is different. In this section, we have given you ideas and situations about whether the talents can be utilised. These ideas are a good starting point for you to think of other ways in which you can put your talents to use.")}</p>
            </div>
            <div className="flex p-4 bg-[#F5F5F5]">
              <p>5.</p>
              <p className="mx-2">{EditButtonFn("top5list5", "Every talent can have a blind spot. While you enjoy putting your talent to work, sometimes overuse of this can be construed as a weakness from others perspective. It will be important for you to understand it and be aware of the same. ")}</p>
            </div>
            <div className="flex p-4 bg-[#E2E2E2]">
              <p>6.</p>
              <p className="mx-2">{EditButtonFn("top5list6", "The next section of the report gives you an understanding of how to deal with someone who has a particular theme. It will be important for us to understand the person and ensure we help them bring their best out by helping them leverage their talent plus theme. ")}</p>
            </div>
            <div className="flex p-4 bg-[#F5F5F5]">
              <p>7.</p>
              <p className="mx-2">{EditButtonFn("top5list7", "Remember, you are unique and your uniqueness is manifested by your talents. It is important for us to reflect on our work, our relationships, and our successes but even more important is to reflect on what has made us successful. Connecting the events back to your talent is a good starting point. This is called Reflective learning. You may start from here. ")}</p>
            </div>
            <div className="flex p-4 bg-[#E2E2E2]">
              <p>8.</p>
              <p className="mx-2"> {EditButtonFn("top5list8", "Once you are confident of your reflective learning, you can move on to your proactive learning, where you define your goals and decide which talent theme to use to achieve those goals. It will also give you an idea on how to leverage others who bring a talent which you may not have in sufficient quantity. That will create huge synergy. ")}</p>
            </div>
            <div className="flex p-4 bg-[#F5F5F5]">
              <p>9.</p>
              <p className="mx-2">{EditButtonFn("top5list9", "When you know your talents and your team members know theirs, time has come to reflect on the team talents. Understanding team talents will allow you to lead your team with confidence and help engage them tremendously.")}</p>
            </div>
            <div className="flex p-4 bg-[#E2E2E2]">
              <p>10.</p>
              <p className="mx-2">{EditButtonFn("top5list10", "Remember, living your life with talent is a journey and all journeys take time. Be patient and keep working on the natural gifts of your life. You will experience a thriving life for sure. ")}</p>
            </div>

          </div>

          {/* <p>Copyright © 2024, HappyPlus, Inc. All rights reserved</p> */}
        </div>
      </main>

    </>
  )

}
