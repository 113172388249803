import React from 'react';

const CustomHorizontalBar = (props) => {
  let { ele, EditDetailsFn, getAssessmentDimensionsData, isTop, type, AssessmentList } = props;


  function getColor(dimension) {
    let colorCode = "#757575";
    if (getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0) {
      let getIndex = getAssessmentDimensionsData.findIndex(prev => prev.dimension === dimension);
      if (getIndex !== -1) {
        colorCode = getAssessmentDimensionsData[getIndex]["colorCode"];
      }
    }
    return colorCode;
  }
  // console.log("ele-------->", ele)


  let list = [];
  if (Object.keys(ele) && Object.keys(ele).length > 0) {
    //console.log("el2e-------->", Object.keys(ele))
    let arrayEle = Object.keys(ele);
    console.log("arrayEle-------->", arrayEle)

    const index = arrayEle.indexOf("emp_id");
    if (index > -1) {
      arrayEle.splice(index, 1);
    }

    const index2 = arrayEle.indexOf("name");
    if (index2 > -1) {
      arrayEle.splice(index2, 1);
    }

    for (var i = 0; i < arrayEle.length; i++) {
      let rankNumber = parseInt(arrayEle[i].replace('Rank', ''));
      let dimension = ele["Rank" + rankNumber.toString()]

      if (rankNumber) {
        list.push({
          dimension: dimension,
          rank: rankNumber,
          colorCode: getColor(dimension)
        });
      }
    }


  }
  // console.log("list-------->", list)

  if (list && list.length > 0) {
    list.sort((a, b) => a.rank - b.rank);
  }


  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }


  return (<>

    {isTop ?
      <>
        <div className='flex  w-full divide-x divide-white'>
          {list && list.length > 0 ?
            list.map((element, index) =>
              (index < 5) ?
                <div
                  style={{
                    background:
                      element && element.colorCode
                        ? element.colorCode
                        : "#DDDDDD",
                  }}
                  className={
                    "w-full px-6 py-2 text-base font-medium flex text-white text-center justify-center  "
                  }>{(index + 1).toString()}. {EditDetailsFn(element, "dimension", element.dimension)}</div>
                : null) : null}
        </div>
      </>
      :
      <div className='flex  w-full divide-x divide-white'>
        {list && list.length > 0 ?
          list.map((element, index) =>
            (getTopBottomIndex(list, "bottom").range1 <= index + 1) ?
              <div
                style={{
                  background:
                    element && element.colorCode
                      ? element.colorCode
                      : "#DDDDDD",
                }}
                className={
                  "w-full px-6 py-2 text-base font-medium flex text-white text-center justify-center  "
                }>{((index - getTopBottomIndex(list, "bottom").range1) + 2).toString()}. {EditDetailsFn(element, "dimension", element.dimension)}</div>
              : null) : null}
      </div>}


  </>);
};

export default CustomHorizontalBar;
