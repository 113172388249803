import React, { Component } from 'react';
import { connect } from 'react-redux';

// import ResponseHeader from '../../CommonComponents/ResponseHeader';

import Summary from './Overview/Summary/Summary';
import RankTop5Talents from './Overview/RankTop5Talents/RankTop5Talents';
import RankTalents from './Overview/RankTalents/RankTalents';

import { CSVLink } from "react-csv";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { RiArrowDropDownLine } from "react-icons/ri";

import AssessmentReport from '../../../Surveylink/Component/AssessmentReport/AssessmentReport';
import AssessmentTop5Summary from '../../../Surveylink/Component/AssessmentTop5Summary/AssessmentTop5Summary';
import TeamAssessmentReport from '../../../Surveylink/Component/TeamAssessmentReport/TeamAssessmentReport';


class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerDetailsIndexType: 0,
      showManagerList: true,
      CurrentManagerName: 'Select Manager',
      optionType: 1,

      showOverview: true

    }
  }

  componentDidMount() {

  }


  openSurveyResponse = () => {
    this.setState({ showOverview: false });

  }

  closeResponseAnalytics = () => {
    this.setState({ showOverview: true })
  }

  onOptionType = (value) => {
    let { getAssessmentDimensionsTop5 } = this.props;
    if (value === 1) {
      getAssessmentDimensionsTop5(false)
    } else if (value === 2) {
      getAssessmentDimensionsTop5(false)
    } else {
      getAssessmentDimensionsTop5(true)
    }
    this.setState({ optionType: value })
  }

  handleGoBack = () => {
    this.setState({ showOverview: true })
  }


  render() {
    let { optionType, showOverview } = this.state;
    let {
      crrReportType,
      getAllAnsweredAssessmentEmpDataList,
      getAssessmentDashboardData,
      handlAssessmentDashboard,
      getEMPFilterLabel,
      getIndexByFilterDMGData,
      handleThemeStrenght,
      loading,
      CurrentSurveyId,

      EmpFilterData,
      getAssessmentByThemeData,
      cronBachAlpha,
      getTeamMembersData,
      getAssessmentReportTeamData,
      closeModal,
      onReportModal,
      getAssessmentDimensionsTeamData

    } = this.props;

    let getAssessmentDimensionsData = getAssessmentReportTeamData;

    let surveyId = CurrentSurveyId;


    let OptionList = [
      {
        "name": "Overall Dominant Themes",
        "template": 1
      },
      {
        "name": "Dominant Theme Frequency",
        "template": 3
      }
    ]



    let { showReportModal, closeReportModal, crrEmpDetails, createdAt,
      EditButtonFn, EditDetailsFn, isUser,
      getTeamMembersList,
      onAssessmentByEmpID,
      getAnsweredEmployeesData,
      getAllAnsweredAssessmentTeamData,
      getTeamDetails

    } = this.props;

    // console.log('getTeamMembersListgetTeamMembersListgetTeamMembersList111111', getTeamMembersList);

    let teamName = getTeamDetails && getTeamDetails.name ? getTeamDetails.name : "";

    return (
      <>
        {/* sticky top-0 z-40 flex items-center justify-between px-4 bg-white lg:space-y-0 md:px-10 */}

        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
          <div className="max-w-full shadow mx-auto rounded-lg relative left-0 bg-white right-0 overflow-hidden my-4 overflow-y-auto 2xl:w-5/6 xl:w-5/6 lg:w-5/6 customscroll5 h-[calc(100vh-2rem)]">

            <div className='sticky top-0 z-40 bg-white'>
              <div className="flex justify-between items-center w-full border-b p-4 px-6">
                <h2 className="font-medium text-xl   text-gray-800 ">{teamName ? teamName : "Assessment Team Dashboard"}</h2>
                <div onClick={() => closeModal(false)} className=" rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                  <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="">
              {!showReportModal &&

                <main className="flex-1 w-full p-4  md:p-6">

                  {showOverview ?
                    <div className="space-y-4">


                      <div className='flex items-center justify-between' >
                        <h1 className=' xl:text-2xl text-xl font-medium text-[#3D405B] '>Overview</h1>
                        <div className='flex items-center justify-between space-x-2'>
                          {getAllAnsweredAssessmentEmpDataList && getAllAnsweredAssessmentEmpDataList.length > 0 ? (
                            <CSVLink data={getAllAnsweredAssessmentEmpDataList} filename={"AssessmentEmployees.csv"}>
                              <button className=" bg-white flex items-center py-2 px-4 space-x-2 text-sm font-medium text-[#3D405B] border rounded-md cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <path d="M10 12l4 4m0 -4l-4 4" /></svg>
                                <span>Download All Data</span>
                              </button>
                            </CSVLink>
                          ) :
                            <>
                            </>
                          }
                        </div>
                        <div>
                          <Menu as="div" className="relative inline-block text-left">
                            <div>
                              <Menu.Button className="inline-flex justify-between items-center w-56 px-4 py-2 text-sm font-medium text-[#3D405B] bg-white border rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                                Individual Report
                                <RiArrowDropDownLine
                                  className="w-7 h-7 ml-2 -mr-1 text-[#3D405B]"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 w-56 mt-1 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black/5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button onClick={() => onReportModal(1)} className={`${active ? 'bg-[#2196F3]/10 text-[#3D405B]' : 'text-[#3D405B]'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>Individual Report</button>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button onClick={() => onReportModal(5)} className={`${active ? 'bg-[#2196F3]/10 text-[#3D405B]' : 'text-[#3D405B]'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>Summary Report</button>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button onClick={() => onReportModal(4)} className={`${active ? 'bg-[#2196F3]/10 text-[#3D405B]' : 'text-[#3D405B]'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>Team Report</button>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>



                      <div onClick={() => this.openSurveyResponse()}>
                        {/* <ResponseHeader responseOption={responseOption} /> */}

                      </div>



                      {/* Tab */}
                      <div className="items-center justify-between border-b md:flex">
                        <div className="flex space-x-2 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 whitespace-nowrap tab-scroll">
                          {OptionList && OptionList.length > 0 ?
                            OptionList.map((item) =>
                              <span onClick={() => this.onOptionType(item.template)}
                                className={optionType === item.template ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}>
                                {item && item.name ? item.name : ""}
                              </span>
                            ) : null}
                        </div>

                      </div>





                      {optionType === 1 ?

                        <>

                          <div>
                            <Summary
                              getAssessmentDashboardData={getAssessmentDashboardData}
                              getAssessmentByThemeData={getAssessmentByThemeData}
                              handleThemeStrenght={handleThemeStrenght}
                              EmpFilterData={EmpFilterData}
                              getIndexByFilterDMGData={getIndexByFilterDMGData}
                              getEMPFilterLabel={getEMPFilterLabel}

                              handlAssessmentDashboard={handlAssessmentDashboard}

                              getAssessmentDimensionsData={getAssessmentDimensionsTeamData}
                              loading={loading}
                              cronBachAlpha={cronBachAlpha}
                            />

                          </div>


                        </>
                        : null}


                      {optionType === 2 ?

                        <>

                          <div>
                            <RankTalents
                              getAssessmentDashboardData={getAssessmentDashboardData}
                              getAssessmentByThemeData={getAssessmentByThemeData}
                              handleThemeStrenght={handleThemeStrenght}
                              EmpFilterData={EmpFilterData}
                              getIndexByFilterDMGData={getIndexByFilterDMGData}
                              getEMPFilterLabel={getEMPFilterLabel}
                              handlAssessmentDashboard={handlAssessmentDashboard}
                              getAssessmentDimensionsData={getAssessmentDimensionsTeamData}
                              loading={loading}
                            />
                          </div>
                        </>
                        : null}

                      {optionType === 3 ?
                        <>
                          <div>
                            <RankTop5Talents
                              getAssessmentDashboardData={getAssessmentDashboardData}
                              getAssessmentByThemeData={getAssessmentByThemeData}
                              handleThemeStrenght={handleThemeStrenght}
                              EmpFilterData={EmpFilterData}
                              getIndexByFilterDMGData={getIndexByFilterDMGData}
                              getEMPFilterLabel={getEMPFilterLabel}
                              handlAssessmentDashboard={handlAssessmentDashboard}
                              getAssessmentDimensionsData={getAssessmentDimensionsTeamData}
                              loading={loading}
                            />
                          </div>
                        </>
                        : null}

                    </div>
                    :

                    <div className="px-2">
                    </div>

                  }

                </main>
              }


              {showReportModal && crrReportType === "FullReport" ?
                <AssessmentReport
                  closeReportModal={closeReportModal}
                  getAssessmentReportByEmpIDData={getAssessmentReportTeamData}
                  getAssessmenrEmployeeDetailsData={crrEmpDetails}
                  getTeamMembersList={getTeamMembersList}
                  createdAt={createdAt}
                  EditButtonFn={EditButtonFn}
                  EditDetailsFn={EditDetailsFn}
                  handleDownloadStatus={() => { }}
                  loading={loading}
                  isUser={isUser}
                  teamName={teamName}

                  onAssessmentByEmpID={onAssessmentByEmpID}
                  getAnsweredEmployeesData={getAnsweredEmployeesData}

                />
                : null}

              {showReportModal && crrReportType === "SummaryReport" ?
                <AssessmentTop5Summary
                  closeReportModal={closeReportModal}
                  getAssessmentReportByEmpIDData={getAssessmentReportTeamData}
                  getAssessmenrEmployeeDetailsData={crrEmpDetails}
                  createdAt={createdAt}
                  getTeamMembersList={getTeamMembersList}
                  EditButtonFn={EditButtonFn}
                  EditDetailsFn={EditDetailsFn}
                  handleDownloadStatus={() => { }}
                  loading={loading}
                  isUser={isUser}
                  teamName={teamName}
                  onAssessmentByEmpID={onAssessmentByEmpID}
                  getAnsweredEmployeesData={getAnsweredEmployeesData}
                />
                : null}


              {showReportModal && crrReportType === "TeamReport" ?
                <TeamAssessmentReport
                  closeReportModal={closeReportModal}
                  getAssessmentDimensionsData={getAssessmentReportTeamData}
                  getAssessmentDimensionsV2Data={getAssessmentReportTeamData}
                  getAllAnsweredAssessmentEmpDataList={getAllAnsweredAssessmentTeamData}

                  onTeamAssessmentByEmpID={{}}
                  onDownloadAssessmentType2ByEmpID={this.onDownloadAssessmentType2ByEmpID}

                  getAssessmentReportByEmpIDData={getAssessmentReportTeamData}
                  getAssessmenrEmployeeDetailsData={crrEmpDetails}
                  getTeamMembersList={getTeamMembersList}
                  createdAt={createdAt}
                  EditButtonFn={EditButtonFn}
                  EditDetailsFn={EditDetailsFn}
                  handleDownloadStatus={() => { }}
                  loading={loading}
                  isUser={isUser}

                  onAssessmentByEmpID={onAssessmentByEmpID}
                  getAnsweredEmployeesData={getAnsweredEmployeesData}
                  teamName={teamName}


                />
                : null}



            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
