import React, { useState, useEffect } from "react";
// import { assessmentActions } from "../../../../_actions";

function HeaderTransprent(props) {
  let {
    handleSignInPage,
    userDetails,
    logout,
    gotoPage,
    cartCount,
    isLogin,
    props2,
    // prodList,
    gotoMyAccount,
  } = props;


  const [drop, setDrop] = useState(true);

  const gotoPage2 = (field) => {
    props2.history.push(`/assessment/` + field);

  };

  // const gotoProd = (field) => {
  //   props2.history.push(`/assessment/productdetails/team/` + field.toString());
  //   window.location.reload();

  // };

  // function matchProdFn(field) {
  //   // console.log("window.location--->", window.location.hash)
  //   let pathname = window.location.hash;
  //   if (pathname === ('#/assessment/productdetails/team/' + field.toString())) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  // const gotoPageV3 = (field) => {
  //   if (userDetails && userDetails.email) {
  //     props2.history.push(`/assessment/` + field);
  //   } else {
  //     props2.history.push(`/assessment/sign-in`);
  //   }
  // };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };


  function matchPathFn(name) {
    // console.log("window.location--->", window.location.hash)
    let pathname = window.location.hash;
    if (pathname === ('#/assessment/' + name)) {
      return true
    } else {
      return false
    }
  }

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [colorMode, setColorMode] = useState(false);
  useEffect(() => {
    document.body.classList.toggle("dark", colorMode);
  }, [colorMode]);



  return (
    <header className={`flex items-center z-20 top-0 w-full h-20 ease-in-out duration-150 ${scrollPosition > 50 ? "bg-white fixed shadow-sm " : "sticky bg-transparent"}`}>
      <nav className="flex items-center justify-between w-full px-4 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0 ">

        <div className="flex flex-row items-center gap-4">
          <div className="lg:hidden">
            <button onClick={toggleMobileMenu} className="lg:hidden block p-2 text-[#3D405B] bg-[#212121]/5 rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" className=" icon icon-tabler icon-tabler-menu-2" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  {" "}  <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}  <line x1="4" y1="6" x2="20" y2="6" />{" "}  <line x1="4" y1="12" x2="20" y2="12" />{" "}  <line x1="4" y1="18" x2="20" y2="18" /></svg>
            </button>
          </div>
          <img onClick={() => gotoPage2("home")} src="/img/TalentPlusStrengths_logo.svg" alt="TalentPlusStrengths_logo" className="bg-cover cursor-pointer md:w-36 w-28" />
        </div>

        <div className={`${isMobileMenuOpen ? "block" : "hidden"} lg:flex lg:items-center gap-8 `}>
          <div className="absolute left-0 z-40 flex flex-col items-start w-full gap-8 py-0 p-6 pb-6   bg-white border-b shadow-md lg:items-center lg:flex-row lg:relative lg:top-auto lg:p-0 top-0 lg:bg-transparent lg:w-auto lg:border-none lg:shadow-none md:h-auto md:overflow-visible h-screen overflow-y-auto ">
            <div className="md:hidden flex justify-between w-full items-center sticky top-0 h-20 bg-white py-2  ">
              <h1 className="text-xl font-medium text-[#212121] ">Menu</h1>
              <button onClick={toggleMobileMenu} className="p-2 rounded-full  bg-slate-100">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
              </button>
            </div>
            {/* desktop view  Solutions*/}
            <div className="lg:flex hidden justify-between items-center space-x-1 text-base cursor-pointer hover:text-[#2196f3] text-[#212121] relative group lg:w-auto w-full h-20 border-b-[4px] border-transparent">
              <span className="font-medium">Solutions</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2.2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none" />  <polyline points="6 9 12 15 18 9" /></svg>

              <div className="absolute z-40 hidden overflow-hidden transition-all duration-150 transform bg-white shadow-lg opacity-0 w-[64rem]   rounded-2xl group-hover:block top-20 -left-1 group-hover:opacity-100">
                <div className="p-6 grid grid-cols-2 gap-4">
                  <a onClick={() => gotoPage2("teen-career-guidance")} className={matchPathFn("teen-career-guidance") ? "flex flex-row items-start gap-2 p-4 bg-slate-100/60 rounded-2xl text-[#2196f3] " : " flex flex-row items-start gap-2 p-4 hover:bg-slate-100/60 rounded-2xl text-[#212121]"}>
                    <div className="w-full space-y-1 flex items-start flex-row gap-4 group">
                      <div className="w-20 h-20 bg-[#2196f3]/10 flex-shrink-0 rounded-xl flex items-center justify-center">
                        <img src="./assessment_images/headersvg/teen-career-guidance-program.svg" className="w-12 " />
                      </div>
                      <div className="space-y-1 ">
                        <p className="text-lg font-medium  ">For  Teen Career Guidance Program</p>
                        <p className="text-[#212121]/70">Discover your ideal career with our assessment tool having Psychometric Assessment, Career Fitment & Personalized Report.
                        </p>
                      </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right  mt-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>
                  <a onClick={() => gotoPage2("teen-talent")} className={matchPathFn("teen-talent") ? "flex flex-row items-start gap-2 p-4 bg-slate-100/60 rounded-2xl text-[#2196f3]" : "flex flex-row items-start gap-2 p-4 hover:bg-slate-100/60 rounded-2xl text-[#212121]"}>
                    <div className="w-full space-y-1 flex items-start flex-row gap-4">
                      <div className="w-20 h-20 bg-[#2196f3]/10 flex-shrink-0 rounded-xl flex items-center justify-center">
                        <img src="./assessment_images/headersvg/for-teen-talentplusstrengths.svg" className="w-12 " />
                      </div>
                      <div className="space-y-1 ">
                        <p className="text-lg font-medium">For Teen TalentPlusStrengths</p>
                        <p className="text-[#212121]/70">Empower teens(People 20 years & below)to unlock their full potential for fostering growth and resilience in all aspects of life.</p>
                      </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right mt-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>
                  <a onClick={() => gotoPage2("individual")} className={matchPathFn("individual") ? "flex flex-row items-start gap-2 p-4 bg-slate-100/60 rounded-2xl text-[#2196f3]" : "flex flex-row items-start gap-2 p-4 hover:bg-slate-100/60 rounded-2xl text-[#212121]"}>
                    <div className="w-full space-y-1 flex items-start flex-row gap-4">
                      <div className="w-20 h-20 bg-[#2196f3]/10 flex-shrink-0 rounded-xl flex items-center justify-center">
                        <img src="./assessment_images/headersvg/individual.svg" className="w-12 " />
                      </div>
                      <div className="space-y-1 ">
                        <p className="text-lg font-medium">For Individual Talent Assessment</p>
                        <p className="text-[#212121]/70">Empower Individual(People 21 years & above) by discovering their best self & achieving success in all aspects of life.</p>
                      </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right mt-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>

                  <a onClick={() => gotoPage2("employer")} className={matchPathFn("employer") ? "flex flex-row items-start gap-2 p-4 bg-slate-100/60 rounded-xl text-[#2196f3]" : "flex flex-row items-start gap-2 p-4 hover:bg-slate-100/60 rounded-xl text-[#212121]"}>
                    <div className="w-full space-y-1 flex items-start flex-row gap-4">
                      <div className="w-20 h-20 bg-[#2196f3]/10 flex-shrink-0 rounded-xl flex items-center justify-center">
                        <img src="./assessment_images/headersvg/for-employer.svg" className="w-12 " />
                      </div>
                      <div className="space-y-1 ">
                        <p className="text-lg font-medium">For Employer</p>
                        <p className="text-[#212121]/70">Platform for discovering your organization unique talent strengths for unleashing productivity and fostering exponential growth.</p>
                      </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right mt-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>

                </div>
              </div>
            </div>


            {/* mobile view  Solutions*/}
            <div className="lg:hidden flex flex-col justify-start items-start space-y-4 text-base cursor-pointer hover:text-[#2196f3] text-[#212121] w-full border-b-[4px] border-transparent ">
              <div onClick={() => setDrop(drop === 1 ? 0 : 1)} className="flex items-center justify-between w-full">
                <span className="font-medium">Solutions</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2.2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none" />  <polyline points="6 9 12 15 18 9" /></svg>
              </div>

              {drop === 1 ? (
                <div className="grid grid-cols-1 gap-4">
                  <a onClick={() => gotoPage2("teen-career-guidance")} className={matchPathFn("teen-career-guidance") ? "flex flex-row items-start gap-2 p-4 bg-slate-100/60 rounded-2xl text-[#2196f3]" : "flex flex-row items-start gap-2 p-4 hover:bg-slate-100/60 rounded-2xl text-[#212121]"}>
                    <div className="w-full space-y-1 flex items-start flex-col gap-4 group">
                      <div className="w-16 h-16 bg-[#2196f3]/10 flex-shrink-0 rounded-xl flex items-center justify-center">
                        <img src="./assessment_images/headersvg/teen-career-guidance-program.svg" className="w-10 " />
                      </div>
                      <div className="space-y-1 ">
                        <p className="lg:text-lg text-base font-medium   ">For  Teen Career Guidance Program</p>
                        <p className="text-[#212121]/70 lg:text-base text-sm">Discover your ideal career with our assessment tool having Psychometric Assessment, Career Fitment & Personalized Report.
                        </p>
                      </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right  mt-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>
                  <a onClick={() => gotoPage2("teen-talent")} className={matchPathFn("teen-talent") ? "flex flex-row items-start gap-2 p-4 bg-slate-100/60 rounded-2xl text-[#2196f3]" : "flex flex-row items-start gap-2 p-4 hover:bg-slate-100/60 rounded-2xl text-[#212121]"}>
                    <div className="w-full space-y-1 flex items-start flex-col gap-4">
                      <div className="w-16 h-16 bg-[#2196f3]/10 flex-shrink-0 rounded-xl flex items-center justify-center">
                        <img src="./assessment_images/headersvg/for-teen-talentplusstrengths.svg" className="w-10 " />
                      </div>
                      <div className="space-y-1 ">
                        <p className="lg:text-lg text-base font-medium">For Teen TalentPlusStrengths</p>
                        <p className="text-[#212121]/70 lg:text-base text-sm">Empower teens(People 20 years & below)to unlock their full potential for fostering growth and resilience in all aspects of life.</p>
                      </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right mt-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>
                  <a onClick={() => gotoPage2("individual")} className={matchPathFn("individual") ? "flex flex-row items-start gap-2 p-4 bg-slate-100/60 rounded-2xl text-[#2196f3]" : "flex flex-row items-start gap-2 p-4 hover:bg-slate-100/60 rounded-2xl text-[#212121]"}>
                    <div className="w-full space-y-1 flex items-start flex-col gap-4">
                      <div className="w-16 h-16 bg-[#2196f3]/10 flex-shrink-0 rounded-xl flex items-center justify-center">
                        <img src="./assessment_images/headersvg/individual.svg" className="w-10 " />
                      </div>
                      <div className="space-y-1 ">
                        <p className="lg:text-lg text-base font-medium">For Individual Talent Assessment</p>
                        <p className="text-[#212121]/70 lg:text-base text-sm">Empower Individual(People 21 years & above) by discovering their best self & achieving success in all aspects of life.</p>
                      </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right mt-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>

                  <a onClick={() => gotoPage2("employer")} className={matchPathFn("employer") ? "flex flex-row items-start gap-2 p-4 bg-slate-100/60 rounded-xl text-[#2196f3]" : "flex flex-row items-start gap-2 p-4 hover:bg-slate-100/60 rounded-xl text-[#212121]"}>
                    <div className="w-full space-y-1 flex items-start flex-col gap-4">
                      <div className="w-16 h-16 bg-[#2196f3]/10 flex-shrink-0 rounded-xl flex items-center justify-center">
                        <img src="./assessment_images/headersvg/for-employer.svg" className="w-10" />
                      </div>
                      <div className="space-y-1 ">
                        <p className="lg:text-lg text-base font-medium">For Employer</p>
                        <p className="text-[#212121]/70 lg:text-base text-sm">Platform for discovering your organization unique talent strengths for unleashing productivity and fostering exponential growth.</p>
                      </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right mt-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>

                </div>
              ) : null}
            </div>

            {/* desktop view the science */}
            <div className="lg:flex hidden justify-between items-center space-x-1 text-base cursor-pointer hover:text-[#2196f3] text-[#212121] relative group lg:w-auto w-full h-20 border-b-[4px] border-transparent">
              <span className="font-medium">The Science</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2.2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none" />  <polyline points="6 9 12 15 18 9" /></svg>

              <div className="absolute z-40 hidden overflow-hidden transition-all duration-150 transform bg-white shadow-lg opacity-0 w-[28rem] rounded-2xl group-hover:block top-20 -left-1 group-hover:opacity-100">
                <div className="p-6 ">

                  <a onClick={() => gotoPage2("how-talentplus-strength-works")} className={matchPathFn("how-talentplus-strength-works") ? "flex flex-row items-start gap-2 p-4 hover:bg-slate-50 rounded-2xl text-[#2196f3]" : "flex flex-row items-start gap-2 p-4 hover:bg-slate-50 rounded-2xl text-[#212121]"}>
                    <div className="w-full space-y-1">
                      <p className="text-lg font-medium">How TalentPlusStrengths Works</p>
                      <p className="text-[#212121]/70 lg:text-base text-sm">Learn the Step by Step process</p>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right text-[#212121]/70" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>

                  <a onClick={() => gotoPage2("the-4-domains-and-22-themes")} className={matchPathFn("the-4-domains-and-22-themes") ? "flex flex-row items-start gap-2 p-4 hover:bg-slate-50 rounded-2xl text-[#2196f3]" : "flex flex-row items-start gap-2 p-4 hover:bg-slate-50 rounded-2xl text-[#212121]"}>
                    <div className="w-full space-y-1">
                      <p className="text-lg font-medium">The 4 Domains and 28 themes</p>
                      <p className="text-[#212121]/70 lg:text-base text-sm">Explore Individual (People 21 years and above) TalentPlusStrengths 4 domains & 28 themes.</p>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right text-[#212121]/70" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>

                  <a onClick={() => gotoPage2("teen-talent")} className={matchPathFn("teen-talent") ? "flex flex-row items-start gap-2 p-4 hover:bg-slate-50 rounded-2xl text-[#2196f3]" : "flex flex-row items-start gap-2 p-4 hover:bg-slate-50 rounded-2xl text-[#212121]"}>
                    <div className="w-full space-y-1">
                      <p className="text-lg font-medium">The 6 Domains and 18 themes</p>
                      <p className="text-[#212121]/70 lg:text-base text-sm">Explore Teen (People 20 years and below) TalentPlusStrengths 6 domains & 18 themes.</p>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right text-[#212121]/70" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>

                </div>
              </div>
            </div>


            {/* mobile view the science*/}
            <div className="lg:hidden flex flex-col justify-start items-start space-y-4 text-base cursor-pointer hover:text-[#2196f3]  text-[#212121]   w-full border-b-[4px] border-transparent">
              <div onClick={() => setDrop(drop === 2 ? 0 : 2)} className="flex items-center justify-between w-full">
                <span className="font-medium">The Science</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2.2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="6 9 12 15 18 9" /></svg>
              </div>

              {drop === 2 ? (
                <div className="text-[#212121] w-full">
                  <a onClick={() => gotoPage2("how-talentplus-strength-works")} className={matchPathFn("how-talentplus-strength-works") ? "flex flex-row items-start gap-2 pl-4 py-2.5 hover:bg-slate-50 rounded-2xl text-[#2196f3]" : "flex flex-row items-start gap-2 pl-4 py-2.5 hover:bg-slate-50 rounded-2xl text-[#212121]"}>
                    <div className="w-full space-y-1">
                      <p className="lg:text-lg text-base font-medium">How TalentPlusStrengths Works</p>
                      <p className="text-[#212121]/70">Learn the Step by Step process</p>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right text-[#212121]/70" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>

                  <a onClick={() => gotoPage2("the-4-domains-and-22-themes")} className={matchPathFn("the-4-domains-and-22-themes") ? "flex flex-row items-start gap-2 pl-4 py-2.5 hover:bg-slate-50 rounded-2xl text-[#2196f3]" : "flex flex-row items-start gap-2 pl-4 py-2.5 hover:bg-slate-50 rounded-2xl text-[#212121]"}>
                    <div className="w-full space-y-1">
                      <p className="lg:text-lg text-base font-medium">The 4 Domains and 28 themes</p>
                      <p className="text-[#212121]/70">Explore Individual (People 21 years and above) TalentPlusStrengths 4 domains & 28 themes.</p>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right text-[#212121]/70" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>

                  <a onClick={() => gotoPage2("teen-talent")} className={matchPathFn("teen-talent") ? "flex flex-row items-start gap-2 pl-4 py-2.5 hover:bg-slate-50 rounded-2xl text-[#2196f3]" : "flex flex-row items-start gap-2 pl-4 py-2.5 hover:bg-slate-50 rounded-2xl text-[#212121]"}>
                    <div className="w-full space-y-1">
                      <p className="lg:text-lg text-base font-medium">Teen 6 Domains and 18 Themes</p>
                      <p className="text-[#212121]/70">Explore Teen (People 20 years and below) TalentPlusStrengths 6 domains & 18 themes.</p>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right text-[#212121]/70" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
                  </a>
                </div>
              ) : null}
            </div>

            <a onClick={() => gotoPage2("products")} className={matchPathFn("products") ? "flex justify-between items-center text-base cursor-pointer text-[#2196f3]  lg:w-auto w-full lg:h-20 font-medium" : "flex justify-between items-center text-base cursor-pointer hover:text-[#2196f3]  text-[#212121]  lg:w-auto w-full lg:h-20   font-medium"}>Pricing </a>


            <a onClick={() => gotoPage2("impact-coach")}
              className={matchPathFn("impact-coach") ? "flex justify-between items-center text-base cursor-pointer text-[#2196f3]  lg:w-auto w-full lg:h-20 font-medium" : "flex justify-between items-center text-base cursor-pointer hover:text-[#2196f3]  text-[#212121]  lg:w-auto w-full lg:h-20 font-medium"}>Impact Coach{" "}</a>

            <a onClick={() => gotoPage2("blog")}
              className={matchPathFn("blog") ? "flex justify-between items-center text-base cursor-pointer text-[#2196f3]  lg:w-auto w-full lg:h-20 font-medium" : "flex justify-between items-center text-base cursor-pointer hover:text-[#2196f3]  text-[#212121]  lg:w-auto w-full lg:h-20 font-medium"}>Blog{" "}</a>

            <a
              activeclassname='text-[#2196f3]'
              onClick={() => gotoPage("contact-us")}
              className="flex justify-center items-center text-base cursor-pointer text-[#fff] bg-[#212121] px-6 py-3 rounded-md md:hidden font-medium   "
            >
              Get in touch{" "}
            </a>
          </div>
        </div>

        <div className="flex items-center gap-4 md:gap-8">

          <div className="flex flex-row items-center gap-6 ">
            <a
              activeclassname='text-[#2196f3]'
              onClick={() => gotoPage("contact-us")}
              className="flex justify-between items-center text-base cursor-pointer text-[#fff] bg-[#212121] px-6 py-3 rounded-md md:block hidden   "
            >
              Get in touch{" "}
            </a>

            {!isLogin ? (
              <div className="flex items-center space-x-1 text-base cursor-pointer hover:text-[#2196f3]  text-[#3D405B] relative  group lg:w-auto  h-20 border-b-[4px] border-transparent hover:border-[#2196f3]">
                <div className="md:text-lg text-base font-semibold text-[#3D405B]  h-12  w-12 bg-[#212121]/5 rounded-full flex items-center justify-center uppercase">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40" fill="none">
                    <path d="M10 34.9999V31.6666C10 29.8985 10.7024 28.2028 11.9526 26.9526C13.2029 25.7023 14.8986 24.9999 16.6667 24.9999H23.3333C25.1014 24.9999 26.7971 25.7023 28.0474 26.9526C29.2976 28.2028 30 29.8985 30 31.6666V34.9999M13.3333 11.6666C13.3333 13.4347 14.0357 15.1304 15.286 16.3807C16.5362 17.6309 18.2319 18.3333 20 18.3333C21.7681 18.3333 23.4638 17.6309 24.714 16.3807C25.9643 15.1304 26.6667 13.4347 26.6667 11.6666C26.6667 9.8985 25.9643 8.2028 24.714 6.95256C23.4638 5.70232 21.7681 4.99994 20 4.99994C18.2319 4.99994 16.5362 5.70232 15.286 6.95256C14.0357 8.2028 13.3333 9.8985 13.3333 11.6666Z" stroke="#212121" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>

                <div className="w-80 text-left  rounded-b-md absolute group-hover:block hidden  transition-all transform top-20 -right-1 opacity-0 group-hover:opacity-100 p-6 bg-white shadow-xl  duration-150 overflow-hidden text-[#3D405B] space-y-4 z-40">
                  {userDetails && userDetails.email ? (
                    <>
                      <div className="flex items-center w-full gap-3">
                        <div>
                          <div className="md:text-lg text-base font-semibold text-[#3D405B]  h-12  w-12 bg-slate-100 rounded-full flex items-center justify-center uppercase">
                            {/* {userDetails && userDetails.name
                              ? userDetails.name.slice(0, 2)
                              : "NA"} */}
                            {userDetails && userDetails.name
                              ? userDetails.name.split(' ').map(name => name[0]).join('')
                              : "NA"}
                          </div>
                        </div>
                        <div className="flex-1 ">
                          <h1 className="text-lg font-semibold truncate ">
                            {userDetails && userDetails.name
                              ? (userDetails.name).slice(0, 20) + (userDetails.name.length > 20 ? "..." : "")
                              : "NA"}
                          </h1>
                          <p className="text-sm truncate w-52">
                            {userDetails && userDetails.email
                              ? (userDetails.email).slice(0, 20) + (userDetails.name.length > 20 ? "..." : "")
                              : ""}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => handleSignInPage()}
                        className="bg-[#2196F3] text-white py-2 px-4 rounded uppercase  w-full"
                      >
                        Sign in
                      </button>
                      <p className="text-base text-[#3D405B]">
                        <a className="text-[#3D405B] md:text-base text-sm">
                          Don't have an account?
                          <span onClick={() => gotoPage("sign-up")} className="text-[#2196F3] pl-2">Sign up</span>
                        </a>
                      </p>
                    </>
                  )}

                  <p className="text-[#3D405B]/70 py-4 border-b">My Account</p>
                  <button
                    onClick={() => gotoMyAccount("profile-summary")}
                    className="flex flex-row items-center   py-1.5    hover:text-[#2196f3]  gap-3 w-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-user"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <polyline points="5 12 3 12 12 3 21 12 19 12" />
                      <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                      <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                    </svg>
                    <span>Profile Summary</span>
                  </button>

                  <button
                    onClick={() => gotoMyAccount("my-orders")}
                    className="flex flex-row items-center py-1.5   hover:text-[#2196f3]  gap-3 w-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-user"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="6" cy="19" r="2" />
                      <circle cx="17" cy="19" r="2" />
                      <path d="M17 17h-11v-14h-2" />
                      <path d="M6 5l14 1l-1 7h-13" />
                    </svg>
                    <span>My Order</span>
                  </button>

                  <button
                    onClick={() => gotoMyAccount("assessment-code")}
                    className="flex flex-row items-center py-1.5    hover:text-[#2196f3]  gap-3 w-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-user"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                      <rect x="9" y="3" width="6" height="4" rx="2" />
                      <line x1="9" y1="12" x2="9.01" y2="12" />
                      <line x1="13" y1="12" x2="15" y2="12" />
                      <line x1="9" y1="16" x2="9.01" y2="16" />
                      <line x1="13" y1="16" x2="15" y2="16" />
                    </svg>
                    <span>Assessment Code</span>
                  </button>
                  {userDetails && userDetails.email ? (
                    <button
                      onClick={() => logout()}
                      className="flex flex-row items-center py-1.5    hover:text-[#2196f3]  gap-3 w-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-user"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                        <path d="M7 12h14l-3 -3m0 6l3 -3" />
                      </svg>
                      <span>Sign Out</span>
                    </button>
                  ) : null}
                </div>
              </div>
            ) : null}

            <button
              onClick={() => gotoPage("my-cart")}
              className=" text-base cursor-pointer  text-[#3D405B] relative"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 48 48" fill="none">
                <path d="M34.3811 23.8192L34.3808 23.8197C33.7439 24.9811 32.5072 25.7499 31.1 25.7499H16.2H16.0522L15.9809 25.8795L13.7809 29.8795L13.5772 30.2499H14H37.75V33.7499H14C11.1523 33.7499 9.34949 30.6955 10.7192 28.1802C10.7193 28.18 10.7194 28.1798 10.7195 28.1795L13.4187 23.301L13.4809 23.1887L13.4259 23.0728L6.22588 7.8928L6.15812 7.74994H6H2.25V4.24994H8.38126L10.1937 8.10628L10.2613 8.24994H10.42H40.0199C40.3232 8.25009 40.6212 8.32944 40.8845 8.48014C41.1477 8.63084 41.367 8.84766 41.5208 9.10915C41.6745 9.37065 41.7573 9.66774 41.7609 9.97105C41.7645 10.2744 41.6889 10.5733 41.5415 10.8385L41.5411 10.8392L34.3811 23.8192ZM12.32 11.7499H11.9248L12.0941 12.107L16.8341 22.107L16.9018 22.2499H17.06H31.1H31.2476L31.3189 22.1208L36.8389 12.1208L37.0436 11.7499H36.62H12.32ZM10.27 39.9999C10.27 37.9364 11.9397 36.2499 14 36.2499C16.0619 36.2499 17.75 37.938 17.75 39.9999C17.75 42.0619 16.0619 43.7499 14 43.7499C11.9397 43.7499 10.27 42.0635 10.27 39.9999ZM30.27 39.9999C30.27 37.9364 31.9397 36.2499 34 36.2499C36.0619 36.2499 37.75 37.938 37.75 39.9999C37.75 42.0619 36.0619 43.7499 34 43.7499C31.9397 43.7499 30.27 42.0635 30.27 39.9999Z" fill="#212121" stroke="white" strokeWidth="0.5" />
              </svg>
              <span className="absolute top-0 right-0 flex items-center justify-center w-5 h-5 text-sm font-light text-white bg-blue-500 rounded-full">
                {cartCount}
              </span>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}
export default HeaderTransprent;
