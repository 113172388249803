import React from "react";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { LuUpload } from "react-icons/lu";


export default function DeleteUserModal(props) {

  let { deleteUser, handleCloseDeleteUserModal } = props;

  return (
    <>
      <div className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full bg-black/60">
        <div className="p-4 max-w-xl mx-auto relative left-0 right-0 overflow-hidden mt-36">
          <div className="shadow w-full rounded-lg bg-white p-8 overflow-hidden block">
            <div className="py-4 p-6 space-y-4 flex items-center justify-center w-full">
              <div className="text-center space-y-4 w-full mx-auto">
                <div className=" rounded-full p-4 bg-[#F44336]/15 text-[#F44336] hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                  <RiDeleteBin6Fill className="h-7 w-7" />
                </div>
                <h2 className="text-lg md:text-xl font-semibold tracking-wide text-[#212121]">Are you sure?</h2>
                <p className="text-base font-medium text-[#212121]/70">Once you delete this user, You won't be able to revert this!</p>
                <div className="flex items-center justify-center pt-4 space-x-4">
                  <button type="button" onClick={deleteUser} className="w-full bg-blue-500 hover:bg-opacity-90 transition duration-150 text-white font-medium py-2 px-6  rounded-md uppercase">Yes, delete it</button>
                  <button onClick={() => handleCloseDeleteUserModal(false)} type="button" className="w-full bg-[#F44336] hover:bg-opacity-90 transition duration-150 text-white font-medium py-2 px-6  rounded-md uppercase">No, cancel</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
