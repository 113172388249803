import React, { useState } from "react";
import moment from "moment";

export default function TeamMembersModal(props) {

  let { handleCloseTeamMemberModal, handleAddMember, formTeamData, getDetails, handleTabRoute, orderPackData, orderBookData, getTeamListData, crrLicensee, closeModal, formNameData, showNameEdit, activeTabInner, handleTabClick, getOrderBookListData, handleSelectAssessmentCodes, getOrderBookListDataTotal, userAssessmentOrdersData, onSingleNameSave, onEditSingleName, handleClick2, onSingleNameInput, size, handleAssessmentCodesCheckBox, handleAddCode, codeIdList } = props;

  // console.log('userAssessmentOrdersDatauserAssessmentOrdersDatauserAssessmentOrdersDatauserAssessmentOrdersDatauserAssessmentOrdersData', userAssessmentOrdersData);


  return (
    <>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="m-4 max-w-3xl mx-auto relative left-0 right-0 overflow-hidden">

          <div className="shadow w-full rounded-lg bg-white overflow-hidden block">

            <div className="flex justify-between items-center w-full border-b p-4 px-6">
              <h2 className="font-medium text-xl text-gray-800">Add Team Members</h2>
              <div onClick={() => closeModal(false)} className=" rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                </svg>
              </div>
            </div>




            {activeTabInner === 5 ? (
              <>
                <div className="py-4 p-6 space-y-3">

                  <div className="w-full flex-1  text-[#212121]">
                    {/* <h1 className="text-lg font-medium  text-[#212121] pb-2">Add Team Members</h1>  */}

                    <div className="w-full overflow-hidden overflow-y-auto h-[36rem] pr-4">
                      <div className="flex flex-col w-full gap-4 p-3 border md:flex-row md:justify-between md:items-center hover:cursor-pointer bg-[#F9FAFB] mb-2">
                        <div className="flex flex-col items-start gap-4 cursor-pointer md:flex-row md:items-center">
                          <div className="flex items-center space-x-4 ">
                            <img src="/img/assessment/Rectangle.png" className="flex-shrink-0 w-20 h-20 md:w-20 md:h-20 overflow-cover" />
                            <div className="flex-shrink-0">
                              <h1 className="font-medium lg:w-48 text-sm md:w-36 w-fit">{orderPackData && orderPackData.productId && orderPackData.productId.name ? orderPackData.productId.name : ''}</h1>
                              <p className="text-[#212121]/70 text-sm font-medium capitalize">{orderPackData && orderPackData.licensee ? orderPackData.licensee : ''} Plan</p>
                            </div>
                          </div>
                          <div className="text-[#212121] md:whitespace-nowrap flex-1 space-y-2 ">
                            <div>
                              <div className="md:hidden flex space-x-4 text-[#212121]">
                                <p className="">Qty :</p>
                                <h1 className="font-medium">{orderPackData && orderPackData.quantity ? orderPackData.quantity : ''}</h1>
                              </div>
                              <div className="md:hidden flex space-x-2 text-[#212121] ">
                                <p className="">
                                  Order Date :
                                </p>
                                <h1 className="font-medium">{moment(new Date(parseInt(getDetails(orderBookData, "createdAt")))).utcOffset("+05:30").format("DD MMM YYYY, hh:mm A")}</h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-row items-center hidden md:flex 2xl:gap-16 md:gap-10 md:justify-start">
                          <div className="text-[#212121] md:w-auto w-full">
                            <p className="text-[#212121]/70 text-sm">
                              Qty
                            </p>
                            <h1 className="text-sm text-center">{orderPackData && orderPackData.quantity ? orderPackData.quantity : ''}</h1>
                          </div>
                          <div className="text-[#212121]  md:w-auto w-full  ">
                            <p className="text-[#212121]/70 text-sm">
                              Ordered Date
                            </p>
                            <h1 className="text-sm">{moment(new Date(parseInt(getDetails(orderBookData, "createdAt")))).utcOffset("+05:30").format("DD MMM YYYY, hh:mm A")}</h1>
                          </div>
                        </div>
                      </div>

                      <table className="w-full border border-gray-300 rounded-md ">
                        <tr className="text-[#3D405B] bg-gray-100 w-[40%]">
                          <th className="p-2 px-4 font-medium text-left border border-gray-300">
                            Name
                          </th>
                          <th className="p-2 px-4 font-medium text-left w-[40%] border border-gray-300">
                            Assessment Code
                          </th>
                        </tr>
                        <tbody className="divide-y ">
                          {userAssessmentOrdersData &&
                            userAssessmentOrdersData.length > 0
                            ? userAssessmentOrdersData.map((element) => (
                              <>
                                <tr className="hover:bg-[#F9FAFB]">
                                  <td className="p-2 px-4 text-left border border-gray-300">
                                    <div className="flex items-center justify-between space-x-4">
                                      {showNameEdit && formNameData["id"] === element.id ? (
                                        <>
                                          <input
                                            value={formNameData["name"]}
                                            onChange={(e) => onSingleNameInput(e.target.value, element)}
                                            className="border px-4 py-2 text-[#3D405B]/70 rounded-md focus:outline-none w-full "
                                            placeholder="Enter Name"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {element && element.name ? (
                                            <div className="flex items-center space-x-4">
                                              <input onClick={() => handleAddMember(element.id)} type="checkbox" checked={formTeamData["purchaserOrderList"].includes(element.id)} className="w-4 h-4" />
                                              <span>{element.name}</span>
                                            </div>
                                          ) :
                                            <input className="border px-4 py-2 text-[#3D405B]/70 rounded-md focus:outline-none w-full "
                                              disabled={true}
                                              placeholder="Name"
                                            ></input>
                                          }
                                        </>
                                      )}


                                      {crrLicensee === "team" ?
                                        <>
                                          {showNameEdit &&
                                            formNameData["id"] === element.id ? (
                                            <span
                                              onClick={() => onSingleNameSave(element, false)}
                                              className="border border-gray-300 text-[#4CAF50] hover:text-[#4CAF50] p-2 rounded cursor-pointer hover:bg-[#4CAF50]/10 transition-all duration-100 "
                                            >
                                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy " width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none" />  <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />  <circle cx="12" cy="14" r="2" />  <polyline points="14 4 14 8 8 8 8 4" /></svg>
                                            </span>
                                          ) : (
                                            <span
                                              onClick={() => onEditSingleName(element, true)}
                                              className="border  text-[#3D405B]/70 hover:text-[#4CAF50] p-2 rounded cursor-pointer hover:bg-[#4CAF50]/10 transition-all duration-100 "
                                            >
                                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy " width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>  <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>  <path d="M13.5 6.5l4 4"></path></svg>
                                            </span>
                                          )}
                                        </> : null}
                                    </div>
                                  </td>

                                  <td className="px-4 py-2">
                                    <div className="flex items-center justify-between space-x-4">
                                      <span className="border-[1.8px] w-full text-center rounded border-spacing-4 border-separate border-dashed p-2 px-6 bg-[#4CAF50]/10 text-[#4CAF50]  border-[#4CAF50]">{element.otpCode}</span>
                                      <span onClick={() => { handleClick2(element.otpCode); navigator.clipboard.writeText(element.otpCode); }} className="border  text-[#3D405B]/70 hover:text-[#4CAF50] p-2 rounded cursor-pointer hover:bg-[#4CAF50]/10 transition-all duration-100 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy " width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path stroke="none" d="M0 0h24v24H0z" fill="none" /><rect x="8" y="8" width="12" height="12" rx="2" /><path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" /></svg>
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))
                            : null}
                        </tbody>
                      </table>


                    </div>

                    <div className="flex justify-end items-center space-x-3 text-right pt-4 border-t">
                      <button onClick={() => handleCloseTeamMemberModal()} className="bg-gray-100 border md:flex hidden space-x-2 items-center p-4 py-2 uppercase cursor-pointer text-[#3D405B] rounded-md">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="5" y1="12" x2="19" y2="12" /><line x1="5" y1="12" x2="11" y2="18" /><line x1="5" y1="12" x2="11" y2="6" /></svg>
                        <span>Back</span>
                      </button>

                      <button onClick={() => handleAddCode()} type="button" className="w-44 bg-blue-500 hover:bg-opacity-90 transition duration-150 text-white font-medium py-2 px-6 rounded-md uppercase">Add Team</button>
                    </div>
                  </div>
                </div>
              </>
            ) :
              <div className="pt-4 pb-8 px-6 space-y-3">
                <div className="w-full flex-1  text-[#212121]">
                  <h1 className="text-lg font-medium  text-[#212121] pb-2">Select Product</h1>

                  <div className="w-full overflow-hidden overflow-y-auto h-[36rem] pr-4">
                    {getOrderBookListData && getOrderBookListData.length > 0
                      ? getOrderBookListData.map((orderBook) => (
                        <>
                          <div className="w-full gap-4 p-3 border  items-center hover:cursor-pointer hover:bg-[#F9FAFB] mb-2">
                            {getDetails(orderBook, "orderPacks") &&
                              getDetails(orderBook, "orderPacks").length > 0
                              ? getDetails(orderBook, "orderPacks").map(
                                (orderPack) => (
                                  <div className="flex" onClick={() => handleTabRoute(5, orderPack, orderBook)}>
                                    <div className="flex items-start gap-4 cursor-pointer md:flex-row md:items-center" >
                                      <div className="flex items-center space-x-4 ">
                                        <img src="/img/assessment/Rectangle.png" className="flex-shrink-0 w-20 h-20 md:w-20 md:h-20 overflow-cover" />
                                        <div className="flex-shrink-0">
                                          <h1 className="font-medium lg:w-48 text-sm md:w-36 w-fit">{getDetails({}, "name", 2, orderPack)}</h1>
                                          <p className="text-[#212121]/70 text-sm font-medium capitalize">{getDetails({}, "licensee", 3, orderPack)} Plan</p>
                                        </div>
                                      </div>
                                      <div className="text-[#212121] md:whitespace-nowrap flex-1 space-y-2 ">
                                        <div>
                                          <div className="md:hidden flex space-x-4 text-[#212121]">
                                            <p className="">Qty :</p>
                                            <h1 className="font-medium">{getDetails({}, "quantity", 3, orderPack)}</h1>
                                          </div>
                                          <div className="md:hidden flex space-x-2 text-[#212121] ">
                                            <p className="">
                                              Order Date :
                                            </p>
                                            <h1 className="font-medium">{moment(new Date(parseInt(getDetails(orderBook, "createdAt")))).utcOffset("+05:30").format("DD MMM YYYY, hh:mm A")}</h1>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex-row items-center hidden md:flex 2xl:gap-16 md:gap-10 md:justify-start">
                                      <div className="text-[#212121] md:w-auto w-full">
                                        <p className="text-[#212121]/70 text-sm">Qty</p>
                                        <h1 className="text-sm text-center">{getDetails({}, "quantity", 3, orderPack)}</h1>
                                      </div>
                                      <div className="text-[#212121]  md:w-auto w-full  ">
                                        <p className="text-[#212121]/70 text-sm">Ordered Date</p>
                                        <h1 className="text-sm">{moment(new Date(parseInt(getDetails(orderBook, "createdAt")))).utcOffset("+05:30").format("DD MMM YYYY, hh:mm A")}</h1>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )
                              : null}
                          </div>

                          {/* <nav className="relative z-0 flex justify-end w-76">
                            {
                              getOrderBookListData && getOrderBookListDataTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={getOrderBookListDataTotal / size}
                                  marginPagesDisplayed={3}
                                  pageRangeDisplayed={3}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav> */}

                        </>
                      ))
                      :
                      <section className="flex items-center justify-center w-full col-span-2 gap-10 px-4 mx-auto overflow-hidden">
                        <div className="flex flex-col items-center justify-center py-8 space-y-4 text-center md:py-10">
                          <img src="/img/newicons/empty_cart2.svg" alt="emptycart.svg" className="w-3/6 2xl:w-2/4" />
                          <h2 className="capitalize md:text-3xl text-2xl font-semibold text-[#3D405B] pt-4">No Products Yet!</h2>
                          <p className="text-lg text-gray-500">Looks like you haven’t made your choice yet.</p>
                          <div className="mt-0 md:mt-8">
                            <button onClick={() => this.props.history.push(`/assessment/` + "products")}
                              className="bg-[#2196F3] border border-[#2196F3] text-white py-2 px-10 rounded uppercase mt-10"> shop Now </button>
                          </div>
                        </div>
                      </section>
                    }
                  </div>

                </div>
              </div>
            }



          </div >
        </div>
      </div >
    </>
  );
}
