import React from 'react'
import moment from "moment";

export default function AssessmentBottom5(props) {
	let { crrSelectedEmp, EditButtonFn, createdAt } = props;




	return (
		<>
			<main className="relative h-full p-10 px-6 space-y-6 bg-white bg-no-repeat page-break">
				<div className="flex items-center justify-between w-full px-12 py-4 border-b text-[#212121]/85 italic">
					<h1 className="text-base font-semibold">
						{EditButtonFn(
							"TalentAssessmentReport",
							"Teen TalentPlusStrengths Assessment"
						)}
					</h1>
					<p className="text-base font-semibold">
						{crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""} | {createdAt}
					</p>
				</div>


				<div className="px-12">
					<div className="w-full space-y-1 page-break">
						<h1 className="text-3xl font-light text-[#212121]">
							<b>
								{EditButtonFn('Bottom5TalentThemes', 'Non-Dominant Teen TalentPlusStrengths Themes')}
							</b>
						</h1>
					</div>


					<div className="w-full space-y-1">
						<div className="w-full pb-3">
							<h1 className="text-2xl font-light text-[#212121]">
								<b>{EditButtonFn('Thesetalentthemesare', "These Teen TalentPlusStrengths themes are your non-dominant talent profile: ")}</b>
							</h1>
						</div>
						<div className="flex p-4 bg-[#F5F5F5]">
							<p>1.</p>
							<p className="mx-2">{EditButtonFn('bottomlist1', "It does not mean it is not there with you at all. It just means you are naturally not wired like the behaviours mentioned.")}</p>
						</div>
						<div className="flex p-4 bg-[#E2E2E2]">
							<p>2.</p>
							<p className="mx-2">{EditButtonFn('bottomlist2', "It does not mean,these are your weakness. It only means that you may not be able to use these naturally.")}</p>
						</div>
						<div className="flex p-4 bg-[#F5F5F5]">
							<p>3.</p>
							<p className="mx-2">{EditButtonFn('bottomlist3', "Non-Dominant is important for us to know and understand so that we know, who are the people with who we can partner (they may have these themes as dominant)")}</p>
						</div>
						<div className="flex p-4 bg-[#E2E2E2]">
							<p>4.</p>
							<p className="mx-2">{EditButtonFn('bottomlist4', "It can also help us understand that sometime,we may use our dominant talents to overcome/achieve the outcomes we want to achieved.")}</p>
						</div>
						<div className="flex p-4 bg-[#F5F5F5]">
							<p>5.</p>
							<p className="mx-2">{EditButtonFn('bottomlist5', "Non-Dominant themes allows us to understand our limitations/make us understand what are some of the things we migh not do naturally.")}</p>
						</div>
						<div className="flex p-4 bg-[#E2E2E2]">
							<p>6.</p>
							<p className="mx-2">{EditButtonFn("top5list6", "The next section of the report gives you an understanding of how to deal with someone who has a particular theme. It will be important for us to understand the person and ensure we help them bring their best out by helping them leverage their Teen TalentPlusStrengths theme. ")}</p>
						</div>
						<div className="flex p-4 bg-[#F5F5F5]">
							<p>7.</p>
							<p className="mx-2">{EditButtonFn('bottomlist7', "Focus should always be on dominant themes. Non-Dominant themes are there to make you understand ways in which you are not wired naturally. ")}</p>
						</div>
						<div className="flex p-4 bg-[#E2E2E2]">
							<p>8.</p>
							<p className="mx-2">{EditButtonFn('bottomlist8', "Once you are confident of your reflective learning, you can move on to your proactive learning, where you define your goals and decide which talent theme to use to achieve those goals. It will also give you an idea on how to leverage others who bring a talent which you may not have in sufficient quantity. That will create huge synergy. ")}</p>
						</div>
						<div className="flex p-4 bg-[#F5F5F5]">
							<p>9.</p>
							<p className="mx-2">{EditButtonFn('bottomlist9', "When you know your talents and your team members know theirs, time has come to reflect on the team talents. Understanding team talents will allow you to lead your team with confidence and help engage them tremendously. ")}</p>
						</div>
						<div className="flex p-4 bg-[#E2E2E2]">
							<p>10.</p>
							<p className="mx-2">{EditButtonFn('bottomlist10', "Remember, living your life with talent is a journey and all journeys takes time. Be patient and keep working on the natural gifts of your life. You will experience thriving life for sure. ")}</p>
						</div>
					</div>

					<div className="w-full mt-2 space-y-4">
						<p className="text-md font-light text-[#3D405B]">
							{EditButtonFn('bottom5footertext1', "As part of the development journey, it is good to know your natural wiring and the way it impacts your thinking,feeling and behaving. We do not need to get stuck with what we may have less of, but should always focus on what we are gifted with.")}</p>
					</div>



				</div>

				{/* <p>Copyright 2025, HappyPlus, Inc. All rights reserved</p> */}
			</main>


		</>
	)

}
