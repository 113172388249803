import React, { Component } from "react";
import { connect } from "react-redux";
import { assessmentActions } from "../../../_actions";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import AOS from "aos";
import Ready from "../../../components/Ready";
import { GiCheckMark } from "react-icons/gi";
import TestHeader from "../../../components/TestHeader";
import { Helmet } from "react-helmet";
import OrgType from "./Component/OrgType";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp_code: "",
      notFoundText: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
      quantity: 1,
      formData: {
        'type': 'Individual'
      },
      showOrgTypeModal: false
    };
  }

  componentDidMount() {
    this.props.dispatch(assessmentActions.userAssessmentUserProfile('getAssessmentCartCount'));
    this.props.dispatch(assessmentActions.getAssessmentPackagesShortList());
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
      delay: 100,
    });
  }

  logout = () => {
    this.props.dispatch(assessmentActions.AssessmentUserLogout(this.props));
  };

  handleSignInPage = () => {
    localStorage.removeItem("route");
    localStorage.setItem("route", this.props.history.location.pathname);
    this.props.history.push(`/assessment/sign-in`);
  };

  gotoPage = (field) => {
    let { assessment } = this.props;
    let { userAssessmentUserProfileData } = assessment;

    if (field === "my-cart") {
      if (
        !(userAssessmentUserProfileData && userAssessmentUserProfileData.email)
      ) {
        this.props.history.push(`/assessment/` + "sign-in");
      } else {
        this.props.history.push(`/assessment/` + field);
      }
    } else {
      this.props.history.push(`/assessment/` + field);
    }
  };

  gotoMyAccount = (field) => {
    let userDetails = this.props.assessment.userAssessmentUserProfileData;
    if (userDetails && userDetails.email) {
      this.props.history.push(`/assessment/` + field);
    } else {
      localStorage.removeItem("route");
      localStorage.setItem("route", `/assessment/` + field);
      this.props.history.push(`/assessment/sign-in`);
    }
  };

  handleDownload = () => {
    let code = "TalentPlusStrengths21";
    const link = document.createElement("a");
    link.href = "/" + code.toString() + ".pdf";
    link.download = code.toString() + ".pdf";
    // console.log('linklinklinklinklinklinklinklinklinklink', link);
    link.click();
  };

  handleRedirect = () => {
    this.props.history.push("/assessment/how-talentplus-strength-works");
    window.scrollTo(0, 0);
  };

  closeOrgTypeModal = () => {
    this.setState({ showOrgTypeModal: false })
  }


  handleOptionInput = (value) => {
    let { formData } = this.state;
    formData["type"] = value;
    this.setState({ formData });
  };

  handleOptionSubmit = () => {
    let { formData } = this.state;
    if (formData["type"] === "Individual") {
      this.props.history.push(`/assessment/` + "surveylogin");
    } else if (formData["type"] === "Organization") {
      window.location.href = 'https://plusinsight.happyplus.in/surveylink/#/assessment/signin';
    }
  };


  handleTakeAssessmentRedirect = () => {
    this.setState({ showOrgTypeModal: false }, () => {
      this.handleOptionSubmit();
    })
  }


  render() {
    let { assessment } = this.props;
    let {
      userAssessmentUserProfileData,
      getAssessmentCartCountData,
      getAssessmentPackagesShortListData,
    } = assessment;
    let { showOrgTypeModal, formData } = this.state;
    // console.log("quantity-->", quantity);


    return (
      <div className="overflow-hidden ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>TalentPlusStrengths: Unleash Your Potential</title>
          <meta name="description" content="TalentPlusStrengths: Empowering self-improvement, growth, strengths, leadership, performance, and goal achievement. Unlock your potential with our comprehensive platform." />
          <link rel="canonical" href="https://www.talentplusstrengths.com/#/assessment/home" />
        </Helmet>

        <Header
          handleSignInPage={this.handleSignInPage}
          userDetails={userAssessmentUserProfileData}
          logout={this.logout}
          gotoPage={this.gotoPage}
          cartCount={getAssessmentCartCountData ? getAssessmentCartCountData : 0}
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />
        <TestHeader
          handleTakeAssessmentRedirect={this.handleTakeAssessmentRedirect}
        />

        <section
          style={{
            backgroundImage: `url('/img/Home/talentplus_strength.webp')`,
          }}
          className="bg-[#d3eafd] w-full lg:h-[40rem] h-auto  bg-cover bg-no-repeat bg-right "
        >
          <div className="w-full h-full">
            <div className="flex flex-col w-full h-full gap-10 px-4 pt-10 mx-auto md:flex-row 2xl:w-9/12 xl:w-11/12 xl:px-0 md:bg-gradient-to-r bg-gradient-to-b lg:from-transparent  lg:to-transparent  from-[#E9F5FE]/90 to-[#E9F5FE]/90 ">
              <div className="flex flex-col justify-center w-full h-full py-10 mx-auto my-auto space-y-8 text-center md:text-left ">
                <h1
                  data-aos="fade-right"
                  className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#3D405B]  capitalize"
                >Re-Discover Yourself Using Your TalentPlusStrengths</h1>
                <div data-aos="fade-right" className="text-base font-light md:text-lg">
                  <p className="text-black">
                    Your Gateway to Success and Fulfillment Across Personal, Professional, and Family Frontiers.
                  </p>
                </div>
                <div className="flex flex-col items-center md:flex-row md:justify-start md:items-center">
                  <button
                    data-aos="fade-right"
                    onClick={() => this.gotoPage("products")}
                    className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150  text-white py-2 px-8 rounded uppercase cursor-pointer flex w-fit justify-center"
                  >
                    BUY TALENTPLUSSTRENGTHS
                  </button>
                  <button
                    data-aos="fade-right"
                    onClick={() => this.gotoPage("how-talentplus-strength-works")}
                    className="ml-2 mt-2 md:mt-0 border border-black hover:bg-[#3D405B] hover:text-white transition-all duration-150  text-black py-2 px-8 rounded uppercase cursor-pointer flex w-fit justify-center"
                  >
                    HOW IT WORKS
                  </button>
                </div>
              </div>

              <div className="flex items-end justify-center w-full lg:justify-end">
                <img
                  data-aos="fade-left"
                  src="/img/Home/img-report.webp"
                  alt="Re-Discover"
                  className="z-0 w-5/6"
                  width={790}
                  height={680}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="w-full px-4 py-20 mx-auto space-y-6 text-center 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
          <h1
            data-aos="slide-up"
            className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#3D405B] capitalize"
          >
            Building Self-Confidence through Strengths: Embrace Your Identity
            and Reach Your Full Potential
          </h1>
          {/* <p className="md:text-lg text-base text-[#3D405B] ">Make greater performance by 'Know Your Strengths'</p> */}
        </section>

        <div className="px-6 space-y-16 lg:px-0">
          {/* 1 */}
          <div
            className="2xl:bg-[length:46rem_auto]  xl:bg-[length:40rem_auto] md:bg-[length:28rem_auto] bg-[length:18rem_auto] h-auto  bg-conatin lg:bg-[115%] bg-no-repeat  bg-right-top "
            style={{
              backgroundImage: `url('/img/Home/shape_left.webp')`,
            }}
          >
            <section className="flex flex-col-reverse items-center w-full gap-10 py-10 xl:gap-10 lg:gap-0 lg:flex-row ">
              {/* text */}
              <div
                data-aos="fade-right"
                className="xl:w-[40%] w-full space-y-4 text-center lg:text-left 2xl:ml-[10%] xl:ml-[8%] lg:ml-[2%]  "
              >
                {/* <h4 className="text-[#2196F3] uppercase">Stpe 1</h4> */}
                <h1 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[4rem]  capitalize font-medium text-[#3D405B]">identify your talent{" "} </h1>
                <h2 className="text-lg font-semibold text-[#3D405B]">If you judge a fish by its ability to climb a tree, it will always believe it had a wasted life.</h2>
                <p className="text-lg text-[#3D405B] ">We are like that fish, which was born to do something else but ends up doing something else. We spend a major part of our life working and if we do not love what we do every day, then we end up living a struggling life.</p>

                <h2 className="text-lg font-semibold text-[#3D405B]">Your talents can be identified by a deep observation, where you see </h2>
                <div className="grid w-full grid-cols-1 gap-8 pt-4 pb-8 text-left xl:grid-cols-1">
                  <ul className="space-y-2 text-[#3D405B] text-lg pl-4">
                    <li className="flex">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                      </span>
                      Rapid learning in a particular area
                    </li>
                    <li className="flex">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                      </span>
                      You want to do something again and again
                    </li>
                    <li className="flex">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                      </span>
                      When you do that thing, you get into the flow (you forget time)
                    </li>
                    <li className="flex">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                      </span>
                      Typically, you are very productive and deliver superior results
                    </li>
                    <li className="flex">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                      </span>
                      Most importantly you feel happy doing it
                    </li>
                  </ul>
                </div>

                <div className="flex justify-center w-full pt-4 lg:justify-start xl:pt-0">
                  <button
                    onClick={() => this.handleDownload()}
                    className="bg-[#2196F3] text-white py-2 px-4 rounded uppercase cursor-pointer hover:bg-[#1976D2] transition-all duration-150 md:w-auto w-full justify-center flex items-center "
                  >
                    view sample report
                  </button>
                </div>
              </div>
              {/* img */}
              <div className="xl:w-[60%] w-full flex justify-end lg:-mr-[5%]">
                <img
                  alt="identify your talent"
                  data-aos="fade-left"
                  className="w-full mx-auto lg:w-6/6 md:w-5/6"
                  src="/img/Home/assessment_survey.webp"
                  width={992}
                  height={582}
                />
              </div>
            </section>
          </div>

          {/* 2 */}
          <div
            className="2xl:bg-[length:40rem_auto]  xl:bg-[length:40rem_auto] md:bg-[length:28rem_auto] bg-[length:18rem_auto] h-auto  bg-conatin lg:bg-[-15%] bg-no-repeat  bg-right-top "
            style={{
              backgroundImage: `url('/img/Home/shape_left.webp')`,
            }}
          >
            <section className="flex flex-col items-center w-full gap-10 py-10 xl:gap-10 lg:gap-0 lg:flex-row ">
              {/* img */}

              <div className="xl:w-[60%] w-full flex justify-end lg:-mr-[5%]">
                <img
                  data-aos="fade-right"
                  alt="TalentPlusStrengths"
                  className="w-full mx-auto lg:w-6/6 md:w-5/6"
                  src="/img/Home/summary_report.webp"
                  width={992}
                  height={583}
                />
              </div>
              {/* text */}
              <div
                data-aos="slide-left"
                className="xl:w-[40%] w-full space-y-4 text-center lg:text-left 2xl:ml-[10%] xl:mr-[8%] lg:mr-[2%]  "
              >
                {/* <h4 className="text-[#2196F3] uppercase">step 2</h4> */}
                <h1 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#3D405B]  capitalize">TalentPlusStrengths Benefits</h1>
                <h2 className="text-lg font-semibold text-[#3D405B]">Discover Natural Talents with HappyPlus's Cutting-Edge Positive Psychology Tool</h2>
                <p className="text-lg text-[#3D405B]">At HappyPlus, we have created our own tool called TalentPlusStrengths, which is embedded in Positive Psychology</p>
                <div className="grid w-full grid-cols-1 gap-8 pt-4 pb-8 text-left xl:grid-cols-1">
                  <ul className="space-y-2 text-[#3D405B] text-lg pl-4">
                    <li className="flex">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                      </span>
                      The focus of this tool is to find what is so special about you.
                    </li>
                    <li className="flex">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                      </span>
                      What is something which makes you be unique?
                    </li>
                    <li className="flex">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                      </span>
                      What is something which comes naturally to you if the right Skills and Knowledge is added then it can become a life-changing strengths for you.
                    </li>
                    <li className="flex">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                      </span>
                      If you are too good in something then how to put that to use on a day-to-day basis?
                    </li>
                    <li className="flex">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                      </span>
                      How to pick up challenges which are aligned with who you are?
                    </li>
                    <li className="flex">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                      </span>
                      How do you create partnerships with people who may/may not be like you?
                    </li>
                    <li className="flex">
                      <span className="pr-2">
                        <GiCheckMark className="w-5 h-5 pt-1.5 text-[#4CAF50]" />
                      </span>
                      How to be aware of the Blind Spots, you might have because of these natural talents?
                    </li>
                  </ul>
                </div>

                <div className="flex justify-center w-full pt-4 lg:justify-start xl:pt-0">
                  <button
                    onClick={() => this.handleDownload()}
                    className="bg-[#2196F3] text-white py-2 px-4 rounded uppercase cursor-pointer hover:bg-[#1976D2] transition-all duration-150 md:w-auto w-full justify-center flex items-center "
                  >
                    view sample report
                  </button>
                </div>
              </div>
            </section>
          </div>

          {/* 3 */}
          {/* <div
            className="2xl:bg-[length:46rem_auto]  xl:bg-[length:40rem_auto] md:bg-[length:28rem_auto] bg-[length:18rem_auto] h-auto  bg-conatin lg:bg-[115%] bg-no-repeat  bg-right-top "
            style={{ backgroundImage: `url('/img/Home/shape_left.webp')`, }} >
            <section className="flex flex-col-reverse items-center w-full gap-10 py-10 xl:gap-10 lg:gap-0 lg:flex-row ">
              <div
                data-aos="slide-right"
                className="xl:w-[40%] w-full space-y-4 text-center lg:text-left 2xl:ml-[10%] xl:ml-[8%] lg:ml-[2%]  "
              >
                <h4 className="text-[#2196F3] uppercase">step 3</h4>
                <h1 className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#3D405B]  capitalize">
                  Leverage Your Unique Talents to Achieve Your Goals
                </h1>
                <p className="text-lg text-[#3D405B]">
                  Leverage Our Resources and Tools to Maximize Your Unique
                  Talents and Achieve Your Goals.
                </p>
                <div className="grid w-full grid-cols-1 gap-8 pt-4 pb-8 text-left md:grid-cols-1">
                  <div className="flex flex-row items-start gap-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-settings"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#2196F3"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        {" "}
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        />{" "}
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11"></path>
                      </svg>
                    </div>
                    <div className="flex-1 space-y-2 ">
                      <p className="text-lg text-[#3D405B] ">
                        Boost your performance by utilizing your dominant talent
                        themes before starting any task.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row items-start gap-4">
                    <div className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-clipboard-list"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#2196F3"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        {" "}
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697"></path>
                        <path d="M18 14v4h4"></path>
                        <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2"></path>
                        <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path>
                        <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                        <path d="M8 11h4"></path>
                        <path d="M8 15h3"></path>
                      </svg>
                    </div>
                    <div className="flex-1 space-y-2">
                      <p className="text-lg text-[#3D405B] ">
                        Sharing a helpful or insightful report with friends or
                        loved ones and seeking their feedback can provide fresh
                        perspectives and insights.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row items-start gap-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-chart-pie-2"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#2196F3"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        {" "}
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        />{" "}
                        <path d="M12 3v9h9" /> <circle cx="12" cy="12" r="9" />
                      </svg>
                    </div>
                    <div className="flex-1 space-y-2 ">
                      <p className="text-lg text-[#3D405B] ">
                        Empower Your Career: Share Your Strengths Report with
                        Your Manage.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row items-start gap-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-tools"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#2196F3"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                        <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                        <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                        <path d="M8.7 10.7l6.6 -3.4"></path>
                        <path d="M8.7 13.3l6.6 3.4"></path>
                      </svg>
                    </div>
                    <div className="flex-1 space-y-2 ">
                      <p className="text-lg text-[#3D405B] ">
                        Collaborative Success: Share Your Talents with
                        Colleagues for Enhanced Team Performance.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center w-full pt-4 pb-8 lg:justify-start xl:pt-0">
                  <button
                    onClick={this.handleRedirect}
                    className="bg-[#2196F3] text-white py-2 px-4 rounded uppercase cursor-pointer hover:bg-[#1976D2] transition-all duration-150 md:w-auto w-full justify-center flex items-center "
                  >
                    How it works
                  </button>
                </div>
              </div>
              <div className="xl:w-[60%] w-full flex justify-end lg:-mr-[5%]">
                <img
                  data-aos="fade-left"
                  className="w-full mx-auto lg:w-6/6 md:w-5/6"
                  src="/img/Home/detail_report.webp"
                />
              </div>
            </section>
          </div> */}
        </div>

        <section className=" bg-[#E3F2FD]">
          <div className="w-full px-4 py-20 mx-auto space-y-20 2xl:w-9/12 xl:w-11/12 xl:px-0 lg:px-0">
            <h1
              data-aos="slide-up"
              className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#3D405B] text-center capitalize"
            >
              Find the TalentPlusStrengths Report That's Right for You
            </h1>

            <div
              data-aos="fade-up"
              className="grid w-full grid-cols-1 gap-6 mx-auto 2xl:gap-10 lg:grid-cols-3 lg:w-full md:w-4/6 "
            >
              <div className="bg-white p-6 py-14 border rounded-2xl text-[rgb(61,64,91)] relative  flex flex-col justify-between space-y-6 h-full   ">
                {/* <div className="bg-gradient-to-b from-transparent via-transparent to-[#E3F2FD] absolute bottom-0 left-0 w-full h-full "></div> */}
                <label className="bg-[#4CAF50]/10 text-[#4CAF50] px-4 py-1.5 rounded-full font-medium uppercase text-sm  absolute top-4 left-6 opacity-0">
                  Most Popular
                </label>
                <div className="space-y-6">
                  <div className="space-y-4">
                    <h1 className="text-xl font-semibold">
                      Top 5 TalentPlusStrengths
                    </h1>
                    <h2 className="text-2xl font-bold">
                      <span className=" font-[roboto]">₹</span>1200
                    </h2>
                    <p className="text-lg">
                      Unlock your Top 5 TalentPlusStrengths   themes (1 - 5) that have
                      a huge influence on your behaviour. It not only allows you
                      to achieve more but also creates your personal brand and
                      thus helps you create an authentic perception of yourself.
                    </p>
                  </div>
                  <div className="space-y-2 ">
                    <p className="text-lg font-semibold">Feature included:</p>
                    <ul className="pl-4 space-y-2 ">
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        TalentPlusStrengths assessment
                      </li>
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        Custom strengths insights
                      </li>
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        Theme definitions
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <button className="bg-[#2196F3] border border-[#2196F3] text-white py-2 px-4 rounded uppercase hover:bg-[#1976D2] transition-all duration-150" onClick={() => this.gotoPage("products")}>
                    Shop now
                  </button>
                  <button className="border border-[#2196F3] text-[#2196F3] py-2 px-4 rounded uppercase hover:bg-[#1976D2]/10 transition-all duration-150" onClick={() => this.gotoPage("the-4-domains-and-22-themes")}>
                    learn more
                  </button>
                </div>
              </div>
              <div className="bg-white p-6 py-14 border rounded-2xl text-[#3D405B] relative lg:scale-110  flex flex-col justify-between space-y-6 h-full  ">
                {/* <div className="bg-gradient-to-b from-transparent via-transparent to-[#E3F2FD] absolute bottom-0 left-0 w-full h-full "></div> */}
                <label className="bg-[#4CAF50]/10 text-[#4CAF50] px-4 py-1.5 rounded-full font-medium uppercase text-sm  absolute top-4 left-6 ">
                  Most Popular
                </label>
                <div className="space-y-6">
                  <div className="space-y-4">
                    <h1 className="text-xl font-semibold">
                      TalentPlusStrengths 28{" "}
                    </h1>
                    <h2 className="text-2xl font-bold">
                      <span className=" font-[roboto]">₹</span>2400
                    </h2>
                    <p className="text-lg">To gain an understanding of all the themes and know your strengths, you should complete the TalentPlusStrengths Assessment first. This assessment will provide you with a rank order of the 28 themes, highlighting your dominant strengths. By understanding your strengths, using them purposefully, being aware of blind spots, and effectively engaging with others' strengths, you can maximize your potential and create a positive impact.</p>
                  </div>
                  <div className="space-y-2 ">
                    <p className="text-lg font-semibold">Feature included:</p>
                    <ul className="pl-4 space-y-2 ">
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        Detailed understanding of your individual talent profile.
                      </li>
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        Develop the last 11 talents in your profile to expand your range of capabilities and skills.
                      </li>
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        Your areas of excellence
                      </li>
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        Navigating blind spots
                      </li>
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        Managing your lesser strengths
                      </li>
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        Unique domain distribution
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <button className="bg-[#2196F3] border border-[#2196F3] text-white py-2 px-4 rounded uppercase hover:bg-[#1976D2] transition-all duration-150" onClick={() => this.gotoPage("products")}>
                    Shop now
                  </button>
                  <button className="border border-[#2196F3] text-[#2196F3] py-2 px-4 rounded uppercase hover:bg-[#1976D2]/10 transition-all duration-150" onClick={() => this.gotoPage("the-4-domains-and-22-themes")}>
                    learn more
                  </button>
                </div>
              </div>

              <div className="bg-white p-6 py-14 border rounded-2xl text-[#3D405B] relative  flex flex-col justify-between space-y-6 h-full ">
                {/* <div className="bg-gradient-to-b from-transparent via-transparent to-[#E3F2FD] absolute bottom-0 left-0 w-full h-full "></div> */}
                <label className="bg-[#4CAF50]/10 text-[#4CAF50] px-4 py-1.5 rounded-full font-medium uppercase text-sm  absolute top-4 left-6 opacity-0">
                  Most Popular
                </label>
                <div className="space-y-6">
                  <div className="space-y-4">
                    <h1 className="text-xl font-semibold">
                      TalentPlusStrengths Manager Strengths{" "}
                    </h1>
                    {/* <h2 className="text-2xl font-bold">
                      <span className=" font-[roboto]">₹</span>800
                    </h2> */}
                    <p className="text-lg">The TalentPlusStrengths for Managers Report is specifically created to assist managers in comprehending their innate talents and leveraging them to enhance their management approach and overall team performance.</p>
                  </div>
                  <div className="space-y-2 ">
                    <p className="text-lg font-semibold">Feature included:</p>
                    <ul className="pl-4 space-y-2 ">
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        Manager-specific theme
                      </li>
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        Theme contributes to your success
                      </li>
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        Action items to help you coach
                      </li>
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        Helps lead and communicate with your team better
                      </li>
                      <li className="flex space-x-2">
                        <span className="pr-2">
                          <GiCheckMark className="w-5 h-5 pt-1 text-[#4CAF50]" />
                        </span>
                        A resource built to help you achieve your greatest goals
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <button className="bg-[#2196F3] border border-[#2196F3] text-white py-2 px-4 rounded uppercase hover:bg-[#1976D2] transition-all duration-150" onClick={() => this.gotoPage("contact-us")}>
                    Connect Us
                  </button>
                  <button className="border border-[#2196F3] text-[#2196F3] py-2 px-4 rounded uppercase hover:bg-[#1976D2]/10 transition-all duration-150" onClick={() => this.gotoPage("the-4-domains-and-22-themes")}>
                    learn more
                  </button>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <button
                data-aos="fade-up"
                onClick={() => this.gotoPage("products")}
                className="bg-[#2196F3] hover:bg-[#1976D2] transition-all duration-150 text-white py-2 px-8 rounded uppercase space-x-2 flex items-center font-medium "
              >
                <span>view packages</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#fff"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <polyline points="9 6 15 12 9 18" />
                </svg>
              </button>
            </div>
          </div>
        </section>

        <section className="w-full px-4 py-20 mx-auto space-y-6 text-center 2xl:w-9/12 xl:w-11/12 xl:px-0 ">
          <h1
            data-aos="slide-up"
            className="xl:text-5xl text-4xl leading-[3rem] xl:leading-[3.5rem] font-medium text-[#3D405B] text-center capitalize"
          >
            I am interested in:
          </h1>
          {/* <p className="md:text-lg text-base text-[#3D405B] ">
            With your purchase of either option, you join Gallup Access -- the
            platform that helps you be your best <br /> self and develop your
            team.
          </p> */}
        </section>

        {/* 1 */}
        <section className="w-full px-4 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0">
          <div
            data-aos="slide-right"
            className="flex flex-col items-stretch mb-16 overflow-hidden border lg:items-center rounded-2xl md:flex-row md:px-0"
          >
            {/* img */}
            <div className="w-full">
              <img
                data-aos="fade-in"
                alt="Individual"
                src="/img/Home/individual.webp"
                className="object-cover h-full "
                width={760}
                height={520}
              />
            </div>
            {/* text */}
            <div className="w-full p-12 px-6 space-y-8 text-center lg:px-16 md:text-left">
              <h1 className=" xl:text-4xl text-3xl leading-[2rem] xl:leading-[4rem] font-semibold text-[#3D405B] ">
                Individual
              </h1>
              <p className="text-lg text-[#3D405B] ">
                Individual talent attributes make us unique, This TalentPlusStrengths
                assessment measures the intensity of individual talents in each
                of the 22 Strengths themes. These 28 themes represent what an
                individual can do best.{" "}
              </p>
            </div>
          </div>
        </section>

        {/* 2 */}
        <section className="w-full px-4 mx-auto 2xl:w-9/12 xl:w-11/12 xl:px-0">
          <div
            data-aos="slide-left"
            className="flex flex-col-reverse items-stretch mb-16 overflow-hidden border lg:items-center rounded-2xl md:flex-row md:px-0"
          >
            {/* text */}
            <div className="w-full p-12 px-6 space-y-8 text-center lg:px-16 md:text-left">
              <h1 className="xl:text-4xl text-3xl leading-[2rem] xl:leading-[4rem] font-semibold text-[#3D405B]">
                Organization
              </h1>
              <p className="text-lg text-[#3D405B] ">
                Build TalentPlusStrengths Assessment strengths based Organization culture
                where people want to work, feel fulfilled and can realize their
                full potential.
              </p>

            </div>

            {/* img */}
            <div className="w-full">
              <img
                alt="Organization"
                data-aos="fade-in"
                src="/img/Home/organization.webp"
                className="object-cover h-full "
                width={760}
                height={520}
              />
            </div>
          </div>
        </section>


        {showOrgTypeModal ? (
          <OrgType
            closeModal={this.closeOrgTypeModal}
            formData={formData}
            handleOptionInput={this.handleOptionInput}
            handleOptionSubmit={this.handleOptionSubmit}
          />
        ) : null}



        <Ready />

        <Footer
          props2={this.props}
          prodList={getAssessmentPackagesShortListData}
          gotoMyAccount={this.gotoMyAccount}
        />
      </div>
    );
  }
}
Home.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, user, assessment } = state;
  return {
    loggingIn,
    questions,
    user,
    assessment,
  };
}

export default connect(mapStateToProps)(Home);
