import * as React from 'react';

const PageTemplate = props => {

  let Number1 = props && props.pageNum ? props.pageNum : 0;
  let SurveyLogo = props && props.surveyLogo ? props.surveyLogo : "/img/logo/happypluslogo.png";

  let pageNo = 0
  if (Number1 > 2) {
    pageNo = Number1 - 2
  }
  return (
    <div style={{
      position: "absolute",
      bottom: "20px",
      width: '100%',
    }}>
      {/* <p className='text-slate-600'>Page {pageNo}</p> */}
      <div className='flex justify-between items-center w-full px-10 pb-4'>
        <img src={"./img/assessment/TalentPlusStrengths_logo.svg"} className="object-contain  w-32" alt="default_survey_logo" />
        <p className='text-center'>Copyright 2025, HappyPlus, Inc. All rights reserved</p>
        <img src={"./img/logo/happyplus-logo-with-powered-by.svg"} className="object-contain  w-24" alt="default_survey_logo" />
      </div>

    </div>

  );
};

export default PageTemplate;