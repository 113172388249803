import React from "react";
import CustomHorizontalBar from "./CustomHorizontalBar";

export default function EmployeeWiseTable(props) {
  let {
    getAssessmentDimensionsData,
    getAllAnsweredAssessmentEmpDataList,
    type,
    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    EditDetailsFn,
    EditButtonFn,
    hindiPDFstatic,
    createdAt,
    teamName,
    isTop
  } = props;

  let AssessmentList = []

  if (getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0) {
    getAssessmentDimensionsData.forEach((element) => {
      AssessmentList.push({
        ...element,
        finalSum: (Number(element.count) * Number(element.rankSum))
      })
    })
    AssessmentList.sort((b, a) => (a.finalSum - b.finalSum))
  }

  console.log("AssessmentList--1==============->", AssessmentList)


  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }

  function chunkArray(array, chunkSize) {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      let chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }

  let size = 8;
  let finalList = getAllAnsweredAssessmentEmpDataList && getAllAnsweredAssessmentEmpDataList.length > 0 ? chunkArray(getAllAnsweredAssessmentEmpDataList, size) : [];

  let title = isTop ? "Team Members Dominant Themes" : "Team Members Non Dominant Themes";


  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
        console.log("range1------------->", range1)
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }

  let dataList = [];
  let x = getAllAnsweredAssessmentEmpDataList;

  function getNameScore(para, listX, typeX) {
    console.log("list-ssss-->", listX)
    console.log("ele--ssss->", para)

    if (listX && listX.length > 0) {
      listX.sort((a, b) => a.rank - b.rank);
    }

    let dimension = para && para.dimension ? para.dimension : "";
    let finalCount = 0;

    if (listX && listX.length > 0) {
      listX.forEach((ele, index1) => {


        let listY = [];
        if (Object.keys(ele) && Object.keys(ele).length > 0) {
          //console.log("el2e-------->", Object.keys(ele))
          let arrayEle = Object.keys(ele);
          console.log("arrayEle-------->", arrayEle)

          const index = arrayEle.indexOf("emp_id");
          if (index > -1) {
            arrayEle.splice(index, 1);
          }

          const index2 = arrayEle.indexOf("name");
          if (index2 > -1) {
            arrayEle.splice(index2, 1);
          }

          for (var i = 0; i < arrayEle.length; i++) {
            let rankNumber = parseInt(arrayEle[i].replace('Rank', ''));
            let dimension = ele["Rank" + rankNumber.toString()]

            if (rankNumber) {
              listY.push({
                dimension: dimension,
                rank: rankNumber
              });
            }
          }


        }

        if (listY && listY.length > 0) {
          listY.sort((a, b) => a.rank - b.rank);
        }

        console.log("listY------------------------------------------------------------->", listY)


        if (listY && listY.length > 0) {
          listY.forEach((innerPara, indexX) => {
            if (typeX === "Top") {
              if (indexX + 1 <= getTopBottomIndex(listY, "top").range1) {
                if (innerPara.dimension.toString() === dimension.toString()) {
                  finalCount++;
                }
              }
            }
            if (typeX === "Bottom") {
              if (getTopBottomIndex(listY, "bottom").range1 <= indexX + 1) {
                if (innerPara.dimension.toString() === dimension.toString()) {
                  finalCount++;
                }
              }
            }
          })
        }



      })
    }

    return finalCount
  }

  if (type === "Top") {
    if (AssessmentList && AssessmentList.length > 0) {
      for (var i = 0; i < AssessmentList.length; i++) {
        let element = AssessmentList[i];
        if ((i + 1 <= getTopBottomIndex(AssessmentList, "top").range1)) {
          dataList.push({
            value: (i + 1) * 20,
            // name: (i + 1).toString(),
            name: getNameScore(element, x, type),
            itemStyle: { color: element && element.colorCode ? element.colorCode : "#757575" }
          })
        }
      }
    }
  }

  if (type === "Bottom") {
    if (AssessmentList && AssessmentList.length > 0) {
      for (var i = 0; i < AssessmentList.length; i++) {
        let element = AssessmentList[i];
        if (getTopBottomIndex(AssessmentList, "bottom").range1 <= i + 1) {
          dataList.push({
            value: ((i - getTopBottomIndex(AssessmentList, "bottom").range1) + 2) * 20,
            // name: ((i - getTopBottomIndex(AssessmentList, "bottom").range1) + 2).toString(),
            name: getNameScore(element, x, type),
            itemStyle: { color: element && element.colorCode ? element.colorCode : "#757575" }
          })
        }
      }
    }
  }



  return (
    <>
      {finalList && finalList.length > 0 &&
        finalList.map((innerList, index) =>
          <main className="p-10 px-6 space-y-6 bg-white page-break">
            <div className='page-break'>
              <div className="flex items-center justify-between w-full py-4 border-b">
                <h1 className="text-lg font-semibold"> {EditButtonFn("TalentAssessmentReport", "Team TalentPlusStrengths Assessment Report")}</h1>
                <p className="text-lg font-semibold">
                {crrSelectedEmp && crrSelectedEmp.name
                  ? (crrSelectedEmp.name === "Team Overall") ? teamName : crrSelectedEmp.name
                  : teamName}{" "}
                {createdAt && crrSelectedEmp.name !== "Team Overall"? " | " + createdAt : ""}                </p>
              </div>

              <div className='py-2 space-y-6'>
                <h1 className='text-3xl text-[#212121] pt-2'>
                  {(index ? title + " (Continue)" : title)}
                </h1>


                <div className='w-full'>
                  <div>

                    {type === "Top" ? (
                      <div className="bg-[#F1F5F9] border-b-2 border-white px-4 py-4">
                        <div className="mb-2 font-medium">Team Overall</div>
                        {AssessmentList && AssessmentList.length > 0 ? (
                          <div className="flex space-x-2 w-full">
                            {AssessmentList.map((element, index) =>
                              index + 1 <= getTopBottomIndex(AssessmentList, "top").range1 ? (
                                <div
                                  key={index}
                                  className="px-4 py-2 text-white text-base font-medium text-center w-full"
                                  style={{
                                    background: element?.colorCode || "#757575",
                                  }}
                                >
                                  {index + 1}. {EditDetailsFn(element, "dimension", element.dimension)}
                                </div>
                              ) : null
                            )}
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    {type === "Bottom" ? (
                      <div className="bg-[#F1F5F9] border-b-2 border-white px-4 py-4">
                        <div className="mb-2 font-medium">Team Overall</div>
                        {AssessmentList && AssessmentList.length > 0 ? (
                          <div className="flex space-x-2 w-full">
                            {AssessmentList.map((element, index) =>
                              (getTopBottomIndex(AssessmentList, "bottom").range1 <= index + 1) ? (
                                <div
                                  key={index}
                                  className="px-4 py-2 text-white text-base font-medium text-center w-full"
                                  style={{
                                    background: element?.colorCode || "#757575",
                                  }}
                                >
                                  {((index - getTopBottomIndex(AssessmentList, "bottom").range1) + 2).toString()}. {EditDetailsFn(element, "dimension", element.dimension)}
                                </div>
                              ) : null
                            )}
                          </div>
                        ) : null}
                      </div>
                    ) : null}


                    {innerList && innerList.length > 0 ?
                      innerList.map((ele) =>
                        <div className='bg-[#F1F5F9] border-b-2 border-white space-y-2 px-4 py-4' >
                          <div className='    '  >
                            {ele && ele.name}
                          </div>
                          <div className='  '  >
                            <CustomHorizontalBar
                              ele={ele}
                              EditDetailsFn={EditDetailsFn}
                              getAssessmentDimensionsData={getAssessmentDimensionsData}
                              AssessmentList={AssessmentList}
                              isTop={isTop}
                              type={type}
                            />
                          </div>
                        </div>
                      ) : null}
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}


    </>
  );
}
