import {
  assessmentConstants
} from '../_constants';

export function assessment(state = {}, action) {
  // console.log('state',state,action)
  switch (action.type) {

    case assessmentConstants.GET_STUDENT_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_STUDENT_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        getStudentReportData: action.assessment.getStudentReportData,
      };
    case assessmentConstants.GET_STUDENT_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.GET_DASHBOARD_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_DASHBOARD_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        getDashboardAnalyticsData: action.assessment.getDashboardAnalyticsData,
      };
    case assessmentConstants.GET_DASHBOARD_ANALYTICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.AUTO_ASSIGN_ASSESSMENT_CODE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.AUTO_ASSIGN_ASSESSMENT_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        autoAssignAssessnentCodeData: action.assessment.autoAssignAssessnentCodeData,
      };
    case assessmentConstants.AUTO_ASSIGN_ASSESSMENT_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case assessmentConstants.GET_ASSESSENT_CODE_BY_TIMESTAMP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSENT_CODE_BY_TIMESTAMP_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentCodeByTimestampData: action.assessment.getAssessmentCodeByTimestampData,
      };
    case assessmentConstants.GET_ASSESSENT_CODE_BY_TIMESTAMP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.ASSIGN_ASSESSMENT_CODE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.ASSIGN_ASSESSMENT_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        assignAssessmentCodeData: action.assessment.assignAssessmentCodeData,

      };
    case assessmentConstants.ASSIGN_ASSESSMENT_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.DELETE_STUDENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteStudentData: action.assessment.deleteStudentData,

      };
    case assessmentConstants.DELETE_STUDENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case assessmentConstants.DELETE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.DELETE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteTeamMemberData: action.assessment.deleteTeamMemberData,

      };
    case assessmentConstants.DELETE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.DELETE_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.DELETE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteTeamData: action.assessment.deleteTeamData,

      };
    case assessmentConstants.DELETE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.DISABLE_STUDENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.DISABLE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        disableStudentData: action.assessment.disableStudentData,

      };
    case assessmentConstants.DISABLE_STUDENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.UPDATE_STUDENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        updateStudentData: action.assessment.updateStudentData,

      };
    case assessmentConstants.UPDATE_STUDENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.ADD_STUDENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.ADD_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        createStudentData: action.assessment.createStudentData,

      };
    case assessmentConstants.ADD_STUDENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case assessmentConstants.GET_STUDENT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getStudentListData: action.assessment.getStudentListData.list,
        getStudentListTotal: action.assessment.getStudentListData.total,

      };
    case assessmentConstants.GET_STUDENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case assessmentConstants.UPLOAD_STUDENT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.UPLOAD_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadStudentListData: action.assessment.uploadStudentListData,

      };
    case assessmentConstants.UPLOAD_STUDENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.GET_SCHOOL_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_SCHOOL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getSchoolListData: action.assessment.getSchoolListData.list,
        getSchoolListTotal: action.assessment.getSchoolListData.total,

      };
    case assessmentConstants.GET_SCHOOL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.UPDATE_SCHOOL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.UPDATE_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSchoolData: action.assessment.updateSchoolData,

      };
    case assessmentConstants.UPDATE_SCHOOL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.CREATE_SCHOOL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.CREATE_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        createSchoolData: action.assessment.createSchoolData,

      };
    case assessmentConstants.CREATE_SCHOOL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.GET_SCHOOL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_SCHOOL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        getSchoolDetailsData: action.assessment.getSchoolDetailsData,

      };
    case assessmentConstants.GET_SCHOOL_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case assessmentConstants.ADD_USERS_ASSESSMENT_CODE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.ADD_USERS_ASSESSMENT_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserAssessmentCodeData: action.assessment.addUserAssessmentCodeData,

      };
    case assessmentConstants.ADD_USERS_ASSESSMENT_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.GET_TEAM_MAMBERS_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TEAM_MAMBERS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        getTeamMembersData: action.assessment.getTeamMembersData,
        getTeamMembersDataTotal: action.assessment.getTeamMembersData.total
      };
    case assessmentConstants.GET_TEAM_MAMBERS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case assessmentConstants.GET_USERS_ASSESSMENT_CODE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_USERS_ASSESSMENT_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        getUserAssessmentCodeData: action.assessment.getUserAssessmentCodeData,

      };
    case assessmentConstants.GET_USERS_ASSESSMENT_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.GET_ROLE_USERS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        createUserSucess: false,
        updateUserSucess: false,
      };
    case assessmentConstants.GET_ROLE_USERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        createUserSucess: false,
        getRoleUsersListData: action.assessment.getRoleUsersListData.list,
        getRoleUsersListTotal: action.assessment.getRoleUsersListData.total,
      };
    case assessmentConstants.GET_ROLE_USERS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case assessmentConstants.DELETE_ROLE_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.DELETE_ROLE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteRoleUsersData: action.assessment.deleteRoleUsersData,

      };
    case assessmentConstants.DELETE_ROLE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.UPDATE_ROLE_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.UPDATE_ROLE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateUserSucess: true,
        updateRoleUsersData: action.assessment.updateRoleUsersData,

      };
    case assessmentConstants.UPDATE_ROLE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.CREATE_ROLE_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.CREATE_ROLE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        createUserSucess: true,
        createRoleUsersData: action.assessment.createRoleUsersData,

      };
    case assessmentConstants.CREATE_ROLE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.USER_ASSESSMENT_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userAssessmentUserProfileData: action.assessment.userAssessmentUserProfileData,

      };
    case assessmentConstants.USER_ASSESSMENT_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case assessmentConstants.SUBMIT_RATING_EXPERT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.SUBMIT_RATING_EXPERT_SUCCESS:
      return {
        ...state,
        loading: false,
        submitExpertRatingData: action.assessment.submitExpertRatingData

      };
    case assessmentConstants.SUBMIT_RATING_EXPERT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case assessmentConstants.GENERATE_CAREER_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        generateCareerReportLoading: true
      };
    case assessmentConstants.GENERATE_CAREER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        generateCareerReportLoading: false,
        generateCareerReportData: action.assessment.generateCareerReportData

      };
    case assessmentConstants.GENERATE_CAREER_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        generateCareerReportLoading: false,
        error: action.error
      };



    case assessmentConstants.GET_TALENT_EMP_SUMMARYU_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TALENT_EMP_SUMMARYU_SUCCESS:
      return {
        ...state,
        loading: false,
        getTalentPlusEmpSummarySuccess: true,
        getCareerFilamentScoreData: action.assessment.getCareerFilamentScoreData

      };
    case assessmentConstants.GET_TALENT_EMP_SUMMARYU_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case assessmentConstants.GET_ASSESSMENT_PACKAGE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentPackagesListData: action.assessment.getAssessmentPackagesListData,

      };
    case assessmentConstants.GET_ASSESSMENT_PACKAGE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.GET_EXPERT_DETAILS_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_EXPERT_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getExpertDetailsListData: action.assessment.getExpertDetailsListData,

      };
    case assessmentConstants.GET_EXPERT_DETAILS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.USER_ASSESSMENT_PACKAGE_BUY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_PACKAGE_BUY_SUCCESS:
      return {
        ...state,
        loading: false,
        userAssessmentPackageBuyData: action.assessment.userAssessmentPackageBuyData,

      };
    case assessmentConstants.USER_ASSESSMENT_PACKAGE_BUY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        userAssessmentOrdersData: action.assessment.userAssessmentOrdersData.list,
        userAssessmentOrdersTotal: action.assessment.userAssessmentOrdersData.total,

      };
    case assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userAssessmentSurveyLoginData: action.assessment.userAssessmentSurveyLoginData,

      };
    case assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        userAssessmentSurveyLoginByOTPData: action.assessment.userAssessmentSurveyLoginByOTPData,

      };
    case assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.GET_ORDER_BOOK_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ORDER_BOOK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getOrderBookListData: action.assessment.getOrderBookListData.list,
        getOrderBookListDataTotal: action.assessment.getOrderBookListData.total,

      };
    case assessmentConstants.GET_ORDER_BOOK_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case assessmentConstants.GET_TEAM_ASSESSMENT_RESPONSE_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TEAM_ASSESSMENT_RESPONSE_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        getTeamResponseAnalyticsData: action.assessment.getTeamResponseAnalyticsData,

      };
    case assessmentConstants.GET_TEAM_ASSESSMENT_RESPONSE_ANALYTICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.USER_ASSESSMENT_RESPONSE_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_RESPONSE_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        userAssessmentResponseAnalyticsData: action.assessment.userAssessmentResponseAnalyticsData,

      };
    case assessmentConstants.USER_ASSESSMENT_RESPONSE_ANALYTICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.USER_ASSESSMENT_RESPONSE_TEAM_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_RESPONSE_TEAM_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        userAssessmentTeamResponseAnalyticsData: action.assessment.userAssessmentTeamResponseAnalyticsData,
      };
    case assessmentConstants.USER_ASSESSMENT_RESPONSE_TEAM_ANALYTICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.USER_ASSESSMENT_ORDER_NAME_UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_ORDER_NAME_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        userAssessmentOrderNameUpdateData: action.assessment.userAssessmentOrderNameUpdateData,

      };
    case assessmentConstants.USER_ASSESSMENT_ORDER_NAME_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.GET_ASSESSMENT_PACKAGE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_PACKAGE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentPackagesByIdData: action.assessment.getAssessmentPackagesByIdData,

      };
    case assessmentConstants.GET_ASSESSMENT_PACKAGE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case assessmentConstants.GET_ASSESSMENT_CART_ITEMS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_CART_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentCartItemsData: action.assessment.getAssessmentCartItemsData,

      };
    case assessmentConstants.GET_ASSESSMENT_CART_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.GET_ASSESSMENT_CART_COUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_CART_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentCartCountData: action.assessment.getAssessmentCartCountData,

      };
    case assessmentConstants.GET_ASSESSMENT_CART_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.GET_ASSESSMENT_CART_PRICE_TOTAL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_CART_PRICE_TOTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentCartPriceTotalData: action.assessment.getAssessmentCartPriceTotalData,

      };
    case assessmentConstants.GET_ASSESSMENT_CART_PRICE_TOTAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case assessmentConstants.SAVE_ASSESSMENT_CONTACT_US_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.SAVE_ASSESSMENT_CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        saveAssessmentContactUsData: action.assessment.saveAssessmentContactUsData,

      };
    case assessmentConstants.SAVE_ASSESSMENT_CONTACT_US_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case assessmentConstants.UPDATE_ASSESSMENT_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.UPDATE_ASSESSMENT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateAssessmentUserProfileData: action.assessment.updateAssessmentUserProfileData,

      };
    case assessmentConstants.UPDATE_ASSESSMENT_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case assessmentConstants.ASSESSMENT_DOWNLOAD_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.ASSESSMENT_DOWNLOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        assessmentDownloadStatusData: action.assessment.assessmentDownloadStatusData,

      };
    case assessmentConstants.ASSESSMENT_DOWNLOAD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case assessmentConstants.ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentPackagesShortListData: action.assessment.getAssessmentPackagesShortListData,

      };
    case assessmentConstants.ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.USER_ASSESSMENT_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        AssessmentUserLogoutData: action.assessment.AssessmentUserLogoutData,

      };
    case assessmentConstants.USER_ASSESSMENT_LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.USER_PLACE_PAYMENT_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_PLACE_PAYMENT_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        userPlacePaymentOrderData: action.assessment.userPlacePaymentOrderData,

      };
    case assessmentConstants.USER_PLACE_PAYMENT_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDER_PLACE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDER_PLACE_SUCCESS:
      return {
        ...state,
        loading: false,
        userAssessmentPackageOrderPlaceData: action.assessment.userAssessmentPackageOrderPlaceData,

      };
    case assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDER_PLACE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.ASSESSMENT_CART_BY_PRE_ORDER_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.ASSESSMENT_CART_BY_PRE_ORDER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentCartByPreOrderIdList: action.assessment.getAssessmentCartByPreOrderIdData.cartList,
        getAssessmentCartByPreOrderNumberData: action.assessment.getAssessmentCartByPreOrderIdData.orderNumber,
        getAssessmentCartByPreOrderCodeData: action.assessment.getAssessmentCartByPreOrderIdData.preOrderCode,
        getAssessmentCartByOrderNumber: action.assessment.getAssessmentCartByPreOrderIdData.orderNumber
      };
    case assessmentConstants.ASSESSMENT_CART_BY_PRE_ORDER_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentCartByPreOrderPriceTotalData: action.assessment.getAssessmentCartByPreOrderPriceTotalData,

      };
    case assessmentConstants.GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.CREATE_SUCCESS_ORDER_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.CREATE_SUCCESS_ORDER_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        createSuccessOrderBookData: action.assessment.createSuccessOrderBookData

      };
    case assessmentConstants.CREATE_SUCCESS_ORDER_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case assessmentConstants.SAVE_SUCCESS_ORDER_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.SAVE_SUCCESS_ORDER_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        saveSuccessOrderBookData: action.assessment.saveSuccessOrderBookData

      };
    case assessmentConstants.SAVE_SUCCESS_ORDER_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case assessmentConstants.USER_EXPERT_ORDER_PLACE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_EXPERT_ORDER_PLACE_SUCCESS:
      return {
        ...state,
        loading: false,
        userAssessmentExpertOrderPlaceData: action.assessment.userAssessmentExpertOrderPlaceData

      };
    case assessmentConstants.USER_EXPERT_ORDER_PLACE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.EXPERT_BOOKING_SUCCESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.EXPERT_BOOKING_SUCCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        expertBookingSuccessData: action.assessment.expertBookingSuccessData

      };
    case assessmentConstants.EXPERT_BOOKING_SUCCESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case assessmentConstants.EXPERT_ORDER_BOOKID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.EXPERT_ORDER_BOOKID_SUCCESS:
      return {
        ...state,
        loading: false,
        getExpertOrderBookIdData: action.assessment.getExpertOrderBookIdData

      };
    case assessmentConstants.EXPERT_ORDER_BOOKID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case assessmentConstants.EXPERT_ORDER_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.EXPERT_ORDER_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        getExpertOrderBookListData: action.assessment.getExpertOrderBookListData

      };
    case assessmentConstants.EXPERT_ORDER_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.GET_TALENT_RESOURCES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TALENT_RESOURCES_SUCCESS:
      return {
        ...state,
        loading: false,
        getTalentResourcesData: action.assessment.getTalentResourcesData

      };
    case assessmentConstants.GET_TALENT_RESOURCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case assessmentConstants.GET_ASSESSMENT_REPORT_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_REPORT_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentReportTeamData: action.assessment.getAssessmentReportTeamData

      };
    case assessmentConstants.GET_ASSESSMENT_REPORT_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case assessmentConstants.GET_TEST_SURVEY_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TEST_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        getTestSurveyQuestionsDataSuccess: true,
        getTestSurveyQuestionsData: action.assessment.getTestSurveyQuestionsData.list,
        getTestSurveyQuestionsDataTotal: action.assessment.getTestSurveyQuestionsData.total

      };
    case assessmentConstants.GET_TEST_SURVEY_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        getTestModuleListFailed: true,
        error: action.error
      };


    case assessmentConstants.GET_INTEREST_DSCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_INTEREST_DSCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        getInterestDimensionScoreSuccess: true,
        getInterestDimensionScoreData: action.assessment.getInterestDimensionScore

      };
    case assessmentConstants.GET_INTEREST_DSCORE_FAILURE:
      return {
        ...state,
        loading: false,
        getWorkValueDimensionScoreFailed: true,
        error: action.error
      };

    case assessmentConstants.GET_WORKER_VALUE_DSCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_WORKER_VALUE_DSCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        getWorkValueDimensionScoreSuccess: true,
        getWorkValueDimensionScoreData: action.assessment.getWorkValueDimensionScore

      };
    case assessmentConstants.GET_WORKER_VALUE_DSCORE_FAILURE:
      return {
        ...state,
        loading: false,
        getWorkValueDimensionScoreFailed: true,
        error: action.error
      };




    case assessmentConstants.GET_FILAMENT_SCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_FILAMENT_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        getCareerFilamentScoreSuccess: true,
        getCareerFilamentScoreData: action.assessment.getCareerFilamentScoreData

      };
    case assessmentConstants.GET_FILAMENT_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        getCareerFilamentScoreFailed: true,
        error: action.error
      };




    case assessmentConstants.GET_TALENT_PLUS_EMP_ADMIN_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TALENT_PLUS_EMP_ADMIN_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        getTalentPlusEmpAdminStatusSuccess: true,
        getTalentPlusEmpAdminStatusData: action.assessment.getTalentPlusEmpAdminStatusData

      };
    case assessmentConstants.GET_TALENT_PLUS_EMP_ADMIN_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        getCareerFilamentScoreFailed: true,
        error: action.error
      };





    case assessmentConstants.GET_TALENT_PLUS_EMP_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TALENT_PLUS_EMP_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        getTalentPlusEmpStatusSuccess: true,
        getTalentPlusEmpStatusData: action.assessment.getTalentPlusEmpStatusData

      };
    case assessmentConstants.GET_TALENT_PLUS_EMP_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        getTalentPlusEmpStatusFailed: true,
        error: action.error
      };




    case assessmentConstants.GET_APTITUDE_TEST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_APTITUDE_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAptitudeTestScoreSuccess: true,
        getAptitudeTestScoreData: action.assessment.getAptitudeTestScoreData

      };
    case assessmentConstants.GET_APTITUDE_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        getAptitudeTestScoreFailed: true,
        error: action.error
      };

    case assessmentConstants.GET_PERSONALITY_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_PERSONALITY_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        getPersonalityIndexSuccess: true,
        getPersonalityIndexData: action.assessment.getPersonalityIndex

      };
    case assessmentConstants.GET_PERSONALITY_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        getPersonalityIndexFailed: true,
        error: action.error
      };

    case assessmentConstants.GET_CAREER_SURVEY_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_CAREER_SURVEY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getCareerSurveyListSuccess: true,
        getCareerSurveyListData: action.assessment.getCareerSurveyList

      };
    case assessmentConstants.GET_CAREER_SURVEY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        getCareerSurveyListFailed: true,
        error: action.error
      };


    case assessmentConstants.GET_TEST_MODULE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TEST_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        getTestModuleSuccess: true,
        getTestModuleData: action.assessment.getTestModuleData

      };
    case assessmentConstants.GET_TEST_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        getTestModuleListFailed: true,
        error: action.error
      };


    case assessmentConstants.GET_TALENT_BLOG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TALENT_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        getTalentBlogListSuccess: true,
        getTalentBlogData: action.assessment.getTalentBlogListData.list,
        getTalentBlogTotal: action.assessment.getTalentBlogListData.total

      };
    case assessmentConstants.GET_TALENT_BLOG_FAILURE:
      return {
        ...state,
        loading: false,
        getTalentBlogListFailed: true,
        error: action.error
      };



    case assessmentConstants.CREATE_NEW_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.CREATE_NEW_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        createNewTeamSuccess: true,
        createNewTeamData: action.assessment.createNewTeamData

      };
    case assessmentConstants.CREATE_NEW_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


      case assessmentConstants.GET_ASSESSMENT_REPORT_MAIL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case assessmentConstants.GET_ASSESSMENT_REPORT_MAIL_SUCCESS:
        return {
          ...state,
          loading: false,
          getAssessmentReportMailSuccess: true,
          getAssessmentReportMailData: action.assessment.getAssessmentReportMailData
        };
      case assessmentConstants.GET_ASSESSMENT_REPORT_MAIL_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };
  




    case assessmentConstants.UPDATE_NEW_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.UPDATE_NEW_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        createNewTeamSuccess: true,
        createNewTeamData: action.assessment.createNewTeamData

      };
    case assessmentConstants.UPDATE_NEW_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.ADD_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.ADD_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        AddTeamMembersSuccess: true,
        AddTeamMembersData: action.assessment.AddTeamMembersData

      };
    case assessmentConstants.ADD_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case assessmentConstants.GET_TEAM_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TEAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getTeamListSuccess: true,
        getTeamListData: action.assessment.getTeamListData.list,
        getTeamListTotal: action.assessment.getTeamListData.total

      };
    case assessmentConstants.GET_TEAM_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case assessmentConstants.GET_ALL_ANSWER_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ALL_ANSWER_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        getTeamListSuccess: true,
        getAllAnsweredAssessmentTeamData: action.assessment.getAllAnsweredAssessmentTeamData,
        getAllAnsweredAssessmentTeamTotal: action.assessment.getAllAnsweredAssessmentTeamData.total

      };
    case assessmentConstants.GET_ALL_ANSWER_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.GET_ASSESSMENT_DIMENSIONS_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_DIMENSIONS_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentDimensionsTeamSuccess: true,
        getAssessmentDimensionsTeamData: action.assessment.getAssessmentDimensionsTeamData

      };
    case assessmentConstants.GET_ASSESSMENT_DIMENSIONS_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case assessmentConstants.GET_ASSESSMENT_TC_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_TC_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentTeamCompletedDateSuccess: true,
        getAssessmentTeamCompletedDateData: action.assessment.getAssessmentTeamCompletedDateData

      };
    case assessmentConstants.GET_ASSESSMENT_TC_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    default:
      return state
  }
}