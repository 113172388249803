import React from "react";
import moment from "moment";

export default function AssessmentDetails(props) {
  let {
    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    teamName,
    EditDetailsFn,
    EditButtonFn,
    createdAt
  } = props;

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }

  let newList = [];
  if (
    getAssessmentReportByEmpIDData &&
    getAssessmentReportByEmpIDData.length > 0
  ) {
    getAssessmentReportByEmpIDData.forEach((element, index) => {
      let getIndex = newList.findIndex(
        (prev) => prev.bucket === element.bucket
      );
      if (getIndex === -1) {
        let temp = {
          element:element,
          bucket: element.bucket,
          colorCode: element.colorCode,
          dimensions: [
            {
              rank: index + 1,
              name: element.dimension,
            },
          ],
        };
        newList.push(temp);
      } else {
        newList[getIndex]["dimensions"].push({
          rank: index + 1,
          name: element.dimension,
        });
      }
    });
  }

  console.log(
    "getAssessmentReportByEmpIDData--->",
    getAssessmentReportByEmpIDData
  );
  console.log("newList--->", newList);

  function getOpacity(rank, item) {
    let total =
      item && item.dimensions && item.dimensions.length > 0
        ? item.dimensions.length
        : 0;
    return (total + 1 - Number(rank)) / (total + 2);
  }

  return (
    <>
      <main className="relative h-full p-10 px-6 space-y-6 bg-white bg-no-repeat page-break">
        <div className="flex items-center justify-between w-full px-12 pt-4 pb-2 border-b text-[#212121]/85 italic">
          <h1 className="text-lg font-semibold">
            {EditButtonFn(
              "TalentAssessmentReport",
              "TalentPlusStrengths Assessment"
            )}
          </h1>
          <p className="text-lg font-semibold">
          {crrSelectedEmp && crrSelectedEmp.name
                  ? (crrSelectedEmp.name === "Team Overall") ? teamName : crrSelectedEmp.name
                  : teamName}{" "}
                {createdAt && crrSelectedEmp.name !== "Team Overall"? " | " + createdAt : ""}
          </p>
        </div>

        <div className="px-12">
          <div className="w-full space-y-1 page-break">
            <h1 className="text-right text-3xl font-light text-[#212121]">
              {/* <b>{getTitle(element, 1)}</b> */}
              <b>
                {EditButtonFn(
                  "YourTalentPlusThemesByDomain",
                  "TalentPlusStrengths domains and themes"
                )}
              </b>
            </h1>
          </div>

          <div className="flex flex-row-reverse grid grid-cols-2 gap-4 mt-6">
            {newList && newList.length > 0
              ? newList.map((item) => (
                <div className="grid grid-cols-2 gap-1 mb-6">
                  <div className={`px-2 py-2 font-medium bg-[#F2F2F2] flex justify-center col-span-2 space-x-2 text-[#3D405B] `} >
                    {/* <p
                      className="w-4 h-4 m-1 text-lg"
                      style={{
                        background:
                          item && item.colorCode ? item.colorCode : "#DDDDDD",
                      }}
                    /> */}
                    <span>{EditDetailsFn(item.element, "bucket", item.bucket, 1)}</span>
                  </div>
                  {item && item.dimensions && item.dimensions.length > 0
                    ? item.dimensions.map((element, index2) => (
                      <div
                        className="flex flex-col items-center justify-center h-20 gap-1 px-2 py-1 text-center "
                        style={{
                          background: hexToRgbA(
                            item && item.colorCode
                              ? item.colorCode
                              : "#DDDDDD",
                            element.rank <= 5 ? "1.0" : "0.24"
                            // getOpacity(index2+1,item)
                          ),
                          color:
                            element.rank <= 5
                              ? item.bucket.trim() === "THINKING TALENTS"
                                ? "#3D405B"
                                : "#FFFFFF"
                              : "#3D405B",
                        }}
                      >
                        <p className="text-2xl font-medium">
                          {element.rank}
                        </p>
                        <h1>{EditDetailsFn(
                          element,
                          "dimension",
                          element.name
                        )}</h1>
                      </div>
                    ))
                    : null}
                </div>
              ))
              : null}
          </div>
        </div>
      </main>
    </>
  );
}
